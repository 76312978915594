<script>
    export let id;
    export let firstName;
    export let lastName;
    export let birthDate;
    export let nationality;
    export let email;
    export let phone;
    export let residenceAddress;
    export let createdAt;
    export let active;
    export let memberships;
    export let isAdmin;
    export let maximumPayment;
    export let minimumPaymentUGS;
    export let minimumPaymentNotUGS;
    export let membershipPrintIds = [];
    let checked = false;
    const maxCharEmail = 20;
    const maxCharName = 12;
    const maxCharResidence = 10;

    function handleSelect(value) {
        if(value) {
            membershipPrintIds = [...new Set([...membershipPrintIds, memberships[0].id])];
        } else {
            membershipPrintIds = membershipPrintIds.filter((id) =>id !== memberships[0].id);
        }
    }

    $: membershipPrintIds, checked = membershipPrintIds.includes(memberships[0].id);
</script>

<tr class="border-b flex flex-row py-3 items-center overflow-ellipsis">
    <td class="w-16 border-t-0  border-l-0 border-r-0 whitespace-nowrap font-450 px-3 text-left flex items-center">
        <input
            class="cursor-pointer"
            type="checkbox"
            checked="{checked}"
            on:input={(v) => {handleSelect(v.target.checked)}}
        >
    </td>
    <td class="w-32 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {firstName.length > maxCharName ? 'scroll-container' : ''}">
        <div class="{firstName.length > maxCharName ? 'scroll-text' : ''}">
            {firstName}
        </div>
    </td>
    <td class="w-32 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {lastName.length > maxCharName ? 'scroll-container' : ''}">
        <div class="{lastName.length > maxCharName ? 'scroll-text' : ''}">
            {lastName}
        </div>
    </td>
    <td class="w-64 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {email.length > maxCharEmail ? 'scroll-container' : ''}">
        <div class="{email.length > maxCharEmail ? 'scroll-text' : ''}">
            {email}
        </div>
    </td>
    <td class="w-52 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {phone.code} {phone.number}
    </td>
    <td class="w-32 h-full tooltipTrigger cursor-pointer font-450 truncate">
        {residenceAddress.city}
        <span class="tooltip w-52">
            <li>
                {residenceAddress.country}
            </li>
            <li>
                {residenceAddress.city}{residenceAddress.province ? `(${residenceAddress.province})` : ''}
            </li>
            <li>
                {residenceAddress.street} {residenceAddress.number}
            </li>
        </span>
    </td>
    <td class="w-44 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 ">
        {(new Date(createdAt)).toLocaleDateString('en-GB')}
    </td>
    <td class="w-44 h-full tooltipTrigger font-450 {memberships[0].prints[0]?.printAt ? 'cursor-pointer' : ''}">
        {memberships[0].prints[0]?.printAt ? (new Date(memberships[0].prints[0]?.printAt)).toLocaleDateString('en-GB') : ""}

        {#if memberships[0].prints[0]?.printAt }
            <span class="tooltip w-52">
                <div class="flex flex-col rounded shadow-md p-3">

                    {#each memberships[0].prints as print}
                        <div class="border-blueGray-100 border-b-2 border-t-0 border-l-0 border-r-0 flex flex-col">
                            <div class="font-normal text-xl text-center">
                                {(new Date(print.printAt)).toLocaleDateString('en-GB')}
                            </div>

                            {#if print?.notes}
                                <div class="font-normal border-gray-200">
                                    {print.notes}
                                </div>
                            {/if}
                        </div>
                    {/each}

                </div>
            </span>
        {/if}
    </td>
    <td class="w-32 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 ">
        {memberships[0]?.paidAt ? (new Date(memberships[0]?.paidAt)).toLocaleDateString('en-GB') : ""}
    </td>
    <td class="w-28 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {memberships[0]?.paidAmount ? `${memberships[0]?.paidAmount} €` : ""}
    </td>
</tr>

import {http} from "./http";
import {sortAnalyzer} from "./sortAnalyzer";

export function getGroups (page, size, sort, name) {
    let url = `v1/groups?page=${page}&size=${size}${name ? `&name=${name}` : ""}${sortAnalyzer(sort)}`
    return http.get(url);
}

export function deleteGroup (id) {
    return http.delete(`v1/groups/${id}`);
}

export function createGroup (group) {
    return http.post(`v1/groups`, {...group});
}

export function updateGroup (group, id) {
    return http.patch(`v1/groups/${id}`, {...group});
}

export function getGroup (id) {
    return http.get(`v1/groups/${id}`);
}

export function getGroupChildrenByName(region) {
    //'http://localhost:5000/v1/groups/Lombardiapp/children' \
    return http.get(`v1/groups/${region}/children`);
}
<script>
    import {handleSortValue} from "../../utils/sortFunction";
    import EmptyHeader from "./EmptyHeader.svelte";
    let nameSortValue = undefined;
    export let sort = [];

    const sortNames = ["name"];


    function searchSort(name) {
        const sortValue = (sort?.find((el) => el.name === name))?.sort;

        switch (name) {
            case "name" :
                nameSortValue = sortValue;
                break;
        }

        return sortValue;
    }

    function handleClick (name) {
        sort = handleSortValue(sort, name, searchSort(name) === undefined ? "rmv" : searchSort("name"));

        if(sort) {
            sort.forEach((el) => searchSort(el.name))
        } else {
            sortNames.forEach((name) => searchSort(name))
        }

    }

</script>

<tr class="w-full bg-white border-b transition duration-300 ease-in-out">
    <th on:click|preventDefault={() => handleClick("name")}  class="cursor-pointer select-none px-6  align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Nome {nameSortValue ? nameSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th class="select-none px-6  align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Tipologia
    </th>
    <EmptyHeader />
</tr>

<script>
    import {DateInput, localeFromDateFnsLocale} from "date-picker-svelte";
    import {it} from "date-fns/locale";
    import sub from "date-fns/sub";
    import {isAfter} from "date-fns";
    import {onMount} from "svelte";
    import {getSettings} from "../../services/settingService";
    import toast from "svelte-french-toast";
    import {navigate} from "svelte-routing";

    export let placeholderValue;
    const locale = localeFromDateFnsLocale(it);
    export let value;
    export let isNullable = true;
    export let date;
    export let max;
    export let isUGSActive = false;
    export let disabled = false;
    let ugsMaxAgeInclusive;

    function handleInputDate(value) {
        const newDate = new Date(value);

        if(newDate.toString() !== "Invalid Date") {
            date = newDate?.toISOString().split('T')[0] ?? "";
        }

        setUGS();
    }

    async function getPaymentLimit() {
        getSettings().then((data) => {
            ugsMaxAgeInclusive = data.data.ugsMaxAgeInclusive;
        }).catch((err) => {
            console.error(err);

            toast("Errore server, riprova più tardi");
            navigate("/", {replace: true});
        })
    }

    function setUGS() {
        const limitDate = sub(new Date(), {
            years: ugsMaxAgeInclusive
        });

        const subBirthdate = new Date(date);
        isUGSActive = isAfter(subBirthdate, limitDate);

        // console.log(isUGSActive);
        // console.log(limitDate + "//" + subBirthdate);
    }

    function handleReset() {
        date = "";
        value= null;
    }

    $: value && value ? handleInputDate(value) : null;


    onMount(async () => {
        await getPaymentLimit();
    })
</script>

<div class="flex items-center w-full">
    <div class="w-full">
        <DateInput
                format="dd/MM/yyyy"
                {locale}
                bind:value={value}
                placeholder={placeholderValue}
                browseWithoutSelecting={true}
                closeOnSelection={true}
                min={new Date(1900, 1, 1)}
                max={max ?? new Date()}
                disabled="{disabled}"
        />
    </div>
    {#if isNullable}
        <div class="w-3 flex items-center h-8 cursor-pointer p-3" on:click|preventDefault={() => handleReset()}>
            <i class="fa fa-minus-square"></i>
        </div>
    {/if}
</div>




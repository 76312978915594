<script>
  import {Router, Route, navigate} from "svelte-routing";
  import {onMount} from "svelte";
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";
  import Settings from "views/admin/Settings.svelte";
  import Tables from "views/admin/Tables.svelte";
  import Users from "../views/admin/Users.svelte";
  import {closeModal, Modals} from "svelte-modals";
  import Profile from "../views/Profile.svelte";
  import Groups from "../views/admin/Groups.svelte";
  import {me} from "../services/authService";
  import Subscribers from "../views/admin/Subscribers.svelte";
  import Prints from "../views/admin/Prints.svelte";

  let isAdmin;
  const isRootManager = localStorage.getItem(process.env.IS_ROOT_MANAGER) === "true";
  export let location;

  onMount(async () => {
    me().then((resp) => {
      if(resp.status === 200 && resp?.data?.email) {
        isAdmin = resp.data.role === "ADMIN";
        localStorage.setItem(process.env.IS_ROOT_MANAGER, resp.data.isRootManager);
        localStorage.setItem(process.env.USER_ROLE, resp.data.role);
        return;
      }
      navigate("/", { replace: true });
    }).catch((err) => {
      if(err?.response?.data?.status === 401){
        navigate("/", { replace: true });
      }
      navigate("/", { replace: true });
    })
  });

</script>

<div>
  <Modals>
    <div
            slot="backdrop"
            class="backdrop"
            on:click={closeModal}
    >
    </div>
  </Modals>
  <div class="h-screen">
    <Sidebar location={location} isAdmin={isAdmin}/>
    <FooterAdmin />
    <div class="relative md:ml-64 bg-blueGray-100">
      <AdminNavbar />
      <HeaderStats />
      <div class="px-4 md:px-10 mx-auto w-full -mt-32">
        <Router url="admin">
          <Route component="{Subscribers}" />
          <Route path="subscribers/*subscribers" component="{Subscribers}" />
          <Route path="settings" component="{Settings}" />
          <Route path="tables" component="{Tables}" />
          <Route path="users/*users" component="{Users}" />
          <Route path="groups/*groups" component="{Groups}" />
          <Route path="prints/*prints" component="{Prints}" />
          <Route path="settings/*settings" component="{Settings}" />
          <Route path="profile" component="{Profile}" />
        </Router>
      </div>
    </div>
  </div>
</div>


<script>
  // core components
  import {onMount} from "svelte";
  import {navigate} from "svelte-routing";
  import {me} from "../services/authService";

  const team2 = "/assets/img/team-2-800x800.jpg";
  let user = {};

  onMount(async () => {
    me().then((resp) => {
      if(resp.status === 200 && resp?.data?.email) {
        user = resp.data;
        return;
      }
      navigate("/", { replace: true });
    }).catch((err) => {
      if(err?.response?.data?.status === 401){
        navigate("/", { replace: true });
      }
      navigate("/", { replace: true });
    })
  });

</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-8 px-4">
    <div class="h-full relative flex flex-col px-8 min-w-0 break-words w-full rounded bg-white mt-6">
      <div class="container mx-auto px-4">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
          <div class="px-6">
            <div class="flex flex-wrap justify-center h-16">
              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div class="relative flex items-center justify-center w-52 h-52 text-blueGray-800">
                  <i class="fa fa-8x fa-user rounded-full h-auto border-none absolute max-w-150-px pb-40"/>
                </div>
              </div>
            </div>
            <div class="text-center mt-12 pb-6">
              <h3
                      class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
              >
                {user.email}
              </h3>
              <div class="mb-2 text-blueGray-600 mt-10">
                <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                {user.role=== "ADMIN" ? "AMMINISTRATORE" : user.role=== "SUBSCRIBER" ? "ISCRITTO" : "AMMINISTRATORE DI GRUPPO"}
              </div>
              <div class="text-blueGray-600">
                <i class="fas fa-people-arrows mr-2 text-lg text-blueGray-400"></i>
                Sinistra Italiana
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
</div>


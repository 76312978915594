<script>
  import {onMount} from "svelte";
  import {navigate} from "svelte-routing";
  import {exportToXlsx, getUsers} from "../../../services/userService";
  import UserSearchBar from "../../SearchBars/UserSearchBar.svelte";
  import TableFooter from "../TableFooter.svelte";
  import UserHeader from "../../Headers/UserHeader.svelte";
  import {resetPage} from "../../../utils/resetPage";
  import StretchLoader from "../../Loaders/StretchLoader.svelte";
  import {showToast} from "../../../services/toastService";
  import EmptyTable from "../EmptyTable.svelte";
  import UserCard from "./UserCard.svelte";
  import Table from "../Table.svelte";
  import toast from "svelte-french-toast";
  import PrintButton from "../../Button/PrintButton.svelte";
  import {format} from "date-fns";
  
  let page = 1;
  let size = 10;
  let sort = [];
  let totalPages = 10;
  let users = [];
  let email, firstname, lastname = undefined;
  let totalCount;
  let isLoading;
  let colspan = 6;
  let isPressed = false;

  async function getUserList () {
    isLoading = true;

    getUsers(page, size, sort, email, firstname, lastname).then((resp) => {
      isLoading = false;

      if(resp.status === 200 && resp?.data) {
        users = resp.data.data;
        totalPages = Math.ceil(resp.data?.totalCount / size);
        totalCount = resp.data.totalCount;
      }

    }).catch((err) => {
      isLoading = false;
      console.log(err);

      if(err?.response?.data?.status === 403){
        showToast("error", "Non hai i permessi per compiere questa azione");
        navigate("/", {replace: true});
      }

      showToast("error", "Errore server");
      navigate("/", {replace: true});
    })
  }

  async function handleChange() {
    await getUserList()
  }

  async function handleExport() {
    isPressed = true;

    exportToXlsx().then((data) => {
      const fileName = `utenti_${format(new Date(), "dd_MM_yyyy")}.xlsx`;
      const a = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(new Blob([data.data]));

      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);

      getUserList();
      isPressed = false;
      toast.success("Esportazione completata");
    }).catch((err) => {
      isPressed = false;
      toast.error("Esportazione fallita");

      console.error(err);
    });
  }

  $: email, firstname, lastname, page = resetPage();

  $: sort, size, page, totalPages, email, firstname, lastname, getUserList();

  onMount(() => {
    getUserList();
  })

</script>

<Table>
  <div class="flex flex-col flex-wrap items-center justify-between w-full md:flex-row" slot="actions">
    <div class="relative w-full max-w-full flex-grow flex-1">
      <h3
              class="font-semibold text-2xl light"
      >
        Utenti
      </h3>
    </div>

    <div class="flex flex-col gap-1 lg:flex-row">
      <PrintButton
        handleClick="{handleExport}"
        bind:isPressed={isPressed}
      />
      <button class="border-0 bg-lightBlue-500 p-2 px-4 font-semibold text-white hover:shadow-lg w-44" on:click|preventDefault={() => navigate("create", {replace: true})}>
        Crea utente
      </button>
    </div>
    
  </div>

  <UserSearchBar
      bind:email
      bind:firstname
      bind:lastname
      slot="searchbar"
  />

  <UserHeader bind:sort slot="tableHeader"/>

  <tbody class="h-full overflow-y-auto pt-6 no-scroll" slot="tableContent">
    {#if isLoading === true}
      <StretchLoader bind:colspan />
    {:else if isLoading === false && users.length === 0}
      <EmptyTable bind:colspan subject="utenti" />
    {:else if isLoading === false}
      {#each users as user}
        <UserCard {...user} on:delete={handleChange}/>
      {/each}
    {/if}
  </tbody>

  <TableFooter bind:colspan bind:page bind:totalPages bind:size subject="Utenti" bind:totalCount slot="footer"/>
</Table>
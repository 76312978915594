<script>
    import {navigate} from "svelte-routing";
    import {showToast} from "../../../services/toastService";
    import {onMount} from "svelte";
    import DeleteButton from "../../Cards/DeleteButton.svelte";
    import {deleteGroup} from "../../../services/groupService";

    export let isEdit;
    export let id;
    async function handleDeleteGroup() {
        deleteGroup(id).then((resp) => {
            if(resp.status === 200 && resp?.data?.id) {
                showToast("success", "Gruppo eliminato", "top-center");
                navigate("/admin/groups/table");
            }
        }).catch((err) => {
            handleError(err);
        })
    }

    function handleError(err) {
        if(err?.response?.status === 404) {
            showToast("error", "Gruppo non trovato", "top-center");
            console.error(err);
            return;
        }

        if(err?.response?.status === 403 || err?.response?.status === 401) {
            showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
            console.error(err);
            return;
        }

        defaultError();
    }

    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }

    onMount(() => {
        console.log(id)
    })
</script>

<div class="rounded-t mb-0 px-8 py-3 border-0">
    <div class="flex flex-wrap items-center justify-between w-full">
        <div class="relative w-full max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-2xl">
                {isEdit ? "Modifica" : "Crea"} gruppo
            </h3>
        </div>
        {#if isEdit}
            <DeleteButton element="il gruppo" isEdit="{true}"  on:delete={() => handleDeleteGroup()}/>
        {/if}
    </div>
</div>
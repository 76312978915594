<script>
    import Accordion from "../../components/Button/Accordion.svelte";
    import {getSettings} from "../../services/settingService";
    import {onMount} from "svelte";
    import toast from "svelte-french-toast";

    let ugsMaxAgeInclusive = "--";
    let minPaymentUGS = "--";
    let minPaymentNotUGS = "--";

    async function getPaymentLimit() {
        getSettings().then((data) => {
            ugsMaxAgeInclusive = data.data.ugsMaxAgeInclusive ?? "--";
            minPaymentUGS =  data.data.minimumPaymentUGS ?? "--"
            minPaymentNotUGS = data.data.minimumPaymentNotUGS ?? "--";
        }).catch((err) => {
            ugsMaxAgeInclusive = "--";
            minPaymentUGS =  "--"
            minPaymentNotUGS =  "--";

            toast("Impossibile caricare dati relativi al pagamento, ci scusiamo per il disagio");
            console.error(err);
        })
    }

    onMount(() => getPaymentLimit())

</script>

<div class="container mx-auto w-full md:bg-none px-3 py-2 lg:px-10">
    <div class="flex flex-col content-center items-center select-none h-full w-full mb-8">
        <div class="title-red font-bold w-full text-left">
            Serve aiuto?
        </div>

        <div class="text-3xl font-450 w-full text-left">
            Qui trovi alcune risposte che potrebbero a aiutarti per completare l’adesione
        </div>
    </div>
    <Accordion title="Cosa mi serve per fare l'adesione?" >
            L'adesione richiede:
            <li class="font-bold">
                Il pagamento di una quota minima di {minPaymentNotUGS} € ({minPaymentUGS} € se hai meno di {ugsMaxAgeInclusive} anni)
            </li>
            <br>
            Nel caso di raccolta di adesioni di altre persone ti servono:
            <li class="font-450">
                dati personali degli aderenti
            </li>
            <li class="font-450">
                Il pagamento di una quota minima di {minPaymentNotUGS} € ({minPaymentUGS} € se hai meno di {ugsMaxAgeInclusive} anni)
            </li>
            <li class="font-450">
                un modulo di iscrizione firmato da ognuno degli aderenti
            </li>
    </Accordion>

    <Accordion title="Ho iniziato l’adesione ma non sono riuscito a concludere il pagamento.">
        Ad iscrizione effettuata abbiamo inviato all'indirizzo mail indicato un link per pagare direttamente dalla nostra piattaforma. Qualora non fosse possibile accedere al suddetto link, procedi con una delle seguenti alternative:
        <br>
        <br>
        Con Paypal:
        <div class="font-450">
            <li>Vai su www.paypal.it</li>
            <li>
                Accedi in paypal
            </li>
            <li>
                Clicca su Paga beni o servizi
            </li>
            <li>
                Inserisci la mail adesioni.si@gmail.com
            </li>
            <li>
                Clicca avanti
            </li>
            <li>
                Inserisci l'importo
            </li>
            <li>
                Inserisci nelle istruzioni per il venditore il tuo codice utente
            </li>
            <li>
                Paga
            </li>
        </div>
            <br>
            Con bonifico bancario:
        <div class="font-450">
            <li>
                Indica nella causale di pagamento nome e cognome seguiti dal tuo codice utente
            </li>
            <li>
                BENEFICIARIO: Sinistra Italiana - BANCO BPM - Fil. di Piazza di Montecitorio, Roma
            </li>
            <li>
                IBAN: IT70F0503403264000000002286
            </li>
            <li>
                SWIFT: BAPPIT21P23
            </li>
        </div>
    </Accordion>

    <Accordion title="Ho inserito la richiesta di iscrizione on-line. Il mio tesseramento è già effettivo?" >
        L'inserimento dei dati per il tesseramento deve essere seguito dal versamento della quota che ne è elemento essenziale. Solo al ricevimento del versamento effettuato la tessera verrà resa valida a tutti gli effetti.
    </Accordion>

    <Accordion title="Ho pagato la quota tessera con un bonifico devo inviare la ricevuta del versamento?" >
        No, non è assolutamente necessario. Al ricevimento delle quote sul c/c di SI, l'amministrazione lo invierà all'ufficio tesseramento che procede con l'attivazione della tessera.
    </Accordion>

    <Accordion title="Ho fatto l'iscrizione per me e per altri, posso fare un unico bonifico?" >
        Sì certo. Il bonifico collettivo deve essere però seguito dall'invio dell'elenco de@ tesserat@ cui si riferisce alla mail adesioni.si@gmail.com in cui si comunicano: nome, cognome quota e codice tesseramento cui il versamento collettivo si riferisce.
    </Accordion>

    <Accordion title="Quando e come mi arriva la tessera?" >
        Le tessere on-line, validate con la registrazione della quota tessera, vengono stampate su cartoncino ogni trimestre (nei giorni immediatamente successivi alla scadenza del trimestre) ed inviate alle Federazioni per la consegna.
    </Accordion>

    <Accordion title="Ho inserito i miei dati nel tesseramento e mi sono accorto tardi che ho fatto degli errori nella compilazione. Come posso correggere?" >
        Sarà sufficiente inviare i dati da correggere alla mail adesioni.si@gmail.com
    </Accordion>

    <Accordion title="Non ho una casella di posta elettronica. Come posso fare?">
        L'indirizzo di posta elettronica è un dato obbligatorio perché SI possa avere un contatto diretto con i propri iscritti. Mail fasulle non potranno essere accettate e il tesseramento verrà sospeso fino all'invio della mail corretta. Per iscritte/i che non hanno indirizzo mail è compito del responsabile del tesseramento della Federazione la creazione di una mail ad hoc da tenere in archivio
    </Accordion>

    <Accordion title="Mi sono iscritto nella Federazione di .............. (provincia di residenza/domicilio in cui voglio essere tesserato/a) come contatto il responsabile del mio territorio?">
        Scrivendo a adesioni.si@gmail.com, chiedendo il contatto della propria Federazione.
    </Accordion>

    <Accordion title="Ho ricevuto la lettera automatica che riepiloga i miei dati di iscrizione compreso il mio Codice univoco. Di cosa si tratta, per cosa dovrò usarlo?">
        Le lettera di riepilogo dei dati dell'iscritt@ è una lettera automatica generata direttamente dal database del tesseramento. Non va <span class="underline font-450">MAI</span> condivisa con nessuno (neppure con noi) perchè contiene l'indicazione del Codice Univoco.

        Il Codice Univoco è un codice del tesserato/a che può essere richiesto per l'accesso a piattaforme di Sinistra Italiana per consultazioni e/o voti on-line su specifici temi che di volta in volta si rendessero necessari. L'accesso a tali piattaforme sarà possibile solo attraverso l'indicazione del codice univoco che quindi è personale e non cedibile ad alcun per garantire la assoluta personalizzazone del voto.

        La lettera automatica ricevta dopo il tesseramento andrà custodita in archivio.
    </Accordion>
</div>
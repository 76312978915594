<script>
    import Select from "svelte-select";
    import {getCities} from "../../services/addressService";
    import {showToast} from "../../services/toastService";

    let timer;

    async function searchCityByName (filterText) {
        await getCities(filterText ? filterText : "Roma").then((data) => {
            cities = data?.data?.map((city) => {
                return {label: city.name, value: city}
            });
        }).catch((data) => {
            showToast("error", "Non è stato possibile caricare la lista delle città");
            console.error(data);
        });
        return cities;
    }
    let cities = [];
    export let value;
    export let handleChange;
    export const required = true;
    export let isSearchBar = false;
    export let cityName;
    export let clearable = false;

    function setCityName(val) {
        cityName = val?.detail?.value?.name;
    }

    let topConfig = {
        placement: 'top-start'
    };
</script>


<Select
        {cities}
        id="city"
        type= "text"
        debounceWait="350"
        loadOptions={searchCityByName}
        floatingConfig={topConfig}
        required={required}
        clearable={clearable}
        bind:value={value}
        on:change={handleChange}
        on:input={setCityName}
        placeholder="Nome della città"
        class={`${isSearchBar ? "" : "hover:shadow-md"} transition-all`}
        containerStyles={`
            border: 1px solid #e0e0e0;
            border-radius: 0px;
            box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);
            ${isSearchBar ? "max-height: 2rem !important; min-height: 2.75rem; border-color: #D8D4D4;" : ""}`
        }
/>
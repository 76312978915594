<script>
    import { link } from "svelte-routing";
    import {onDestroy, onMount} from "svelte";
    import EmailForm from "../../components/Forms/Subscriber/EmailForm.svelte";

    export let isBackgroundVisible;

    onMount(() => {
        isBackgroundVisible = false;
    })

    onDestroy(() => {
        isBackgroundVisible = false;
    } )
</script>

<div class="container-home min-h-600-px flex justify-center">
    <div class="flex flex-col content-color items-center select-none mb-4 h-full w-full md:w-8/12 xl:w-4/12">
        <div class="text-2xl mt-4">
            Se hai già effettuato l'iscrizione uno degli scorsi anni da questo sito recupera i dati inserendo la tua email, oppure
            <a
                    use:link
                    href="/iscriviti"
                    class="red-si"
            >
                procedi con una nuova iscrizione.
            </a>
        </div>

        <EmailForm />

        <div class="my-2 w-full flex items-start">
            <a
                    use:link
                    href="/iscriviti"
            >
                <div class="w-64 bg-lb cursor-pointer py-1 px-8 second-button text-center transform-gpu section-title rounded">
                    Nuova iscrizione
                </div>
            </a>
        </div>
    </div>
</div>
export const countries = [
    {
        value: "Afghanistan",
        label: "Afghanistan"
    },
    {
        value: "Albania",
        label: "Albania"
    },
    {
        value: "Algeria",
        label: "Algeria"
    },
    {
        value: "Andorra",
        label: "Andorra"
    },
    {
        value: "Angola",
        label: "Angola"
    },
    {
        value: "Anguilla",
        label: "Anguilla"
    },
    {
        value: "Antartide",
        label: "Antartide"
    },
    {
        value: "Antigua e Barbuda",
        label: "Antigua e Barbuda"
    },
    {
        value: "Antille Olandesi",
        label: "Antille Olandesi"
    },
    {
        value: "Arabia Saudita",
        label: "Arabia Saudita"
    },
    {
        value: "Argentina",
        label: "Argentina"
    },
    {
        value: "Armenia",
        label: "Armenia"
    },
    {
        value: "Aruba",
        label: "Aruba"
    },
    {
        value: "Australia",
        label: "Australia"
    },
    {
        value: "Austria",
        label: "Austria"
    },
    {
        value: "Azerbaijan",
        label: "Azerbaijan"
    },
    {
        value: "Bahamas",
        label: "Bahamas"
    },
    {
        value: "Bahrain",
        label: "Bahrain"
    },
    {
        value: "Bangladesh",
        label: "Bangladesh"
    },
    {
        value: "Barbados",
        label: "Barbados"
    },
    {
        value: "Belgio",
        label: "Belgio"
    },
    {
        value: "Belize",
        label: "Belize"
    },
    {
        value: "Benin",
        label: "Benin"
    },
    {
        value: "Bermuda",
        label: "Bermuda"
    },
    {
        value: "Bielorussia",
        label: "Bielorussia"
    },
    {
        value: "Bhutan",
        label: "Bhutan"
    },
    {
        value: "Bolivia",
        label: "Bolivia"
    },
    {
        value: "Bosnia Erzegovina",
        label: "Bosnia Erzegovina"
    },
    {
        value: "Botswana",
        label: "Botswana"
    },
    {
        value: "Brasile",
        label: "Brasile"
    },
    {
        value: "Brunei Darussalam",
        label: "Brunei Darussalam"
    },
    {
        value: "Bulgaria",
        label: "Bulgaria"
    },
    {
        value: "Burkina Faso",
        label: "Burkina Faso"
    },
    {
        value: "Burundi",
        label: "Burundi"
    },
    {
        value: "Cambogia",
        label: "Cambogia"
    },
    {
        value: "Camerun",
        label: "Camerun"
    },
    {
        value: "Canada",
        label: "Canada"
    },
    {
        value: "Capo Verde",
        label: "Capo Verde"
    },
    {
        value: "Ciad",
        label: "Ciad"
    },
    {
        value: "Cile",
        label: "Cile"
    },
    {
        value: "Cina",
        label: "Cina"
    },
    {
        value: "Cipro",
        label: "Cipro"
    },
    {
        value: "Citta del Vaticano",
        label: "Citta del Vaticano"
    },
    {
        value: "Colombia",
        label: "Colombia"
    },
    {
        value: "Comore",
        label: "Comore"
    },
    {
        value: "Corea del Nord",
        label: "Corea del Nord"
    },
    {
        value: "Corea del Sud",
        label: "Corea del Sud"
    },
    {
        value: "Costa Rica",
        label: "Costa Rica"
    },
    {
        value: "Costa d'Avorio",
        label: "Costa d'Avorio"
    },
    {
        value: "Croazia",
        label: "Croazia"
    },
    {
        value: "Cuba",
        label: "Cuba"
    },
    {
        value: "Danimarca",
        label: "Danimarca"
    },
    {
        value: "Dominica",
        label: "Dominica"
    },
    {
        value: "Ecuador",
        label: "Ecuador"
    },
    {
        value: "Egitto",
        label: "Egitto"
    },
    {
        value: "Eire",
        label: "Eire"
    },
    {
        value: "El Salvador",
        label: "El Salvador"
    },
    {
        value: "Emirati Arabi Uniti",
        label: "Emirati Arabi Uniti"
    },
    {
        value: "Eritrea",
        label: "Eritrea"
    },
    {
        value: "Estonia",
        label: "Estonia"
    },
    {
        value: "Etiopia",
        label: "Etiopia"
    },
    {
        value: "Federazione Russa",
        label: "Federazione Russa"
    },
    {
        value: "Fiji",
        label: "Fiji"
    },
    {
        value: "Filippine",
        label: "Filippine"
    },
    {
        value: "Finlandia",
        label: "Finlandia"
    },
    {
        value: "Francia",
        label: "Francia"
    },
    {
        value: "Gabon",
        label: "Gabon"
    },
    {
        value: "Gambia",
        label: "Gambia"
    },
    {
        value: "Georgia",
        label: "Georgia"
    },
    {
        value: "Germania",
        label: "Germania"
    },
    {
        value: "Ghana",
        label: "Ghana"
    },
    {
        value: "Giamaica",
        label: "Giamaica"
    },
    {
        value: "Giappone",
        label: "Giappone"
    },
    {
        value: "Gibilterra",
        label: "Gibilterra"
    },
    {
        value: "Gibuti",
        label: "Gibuti"
    },
    {
        value: "Giordania",
        label: "Giordania"
    },
    {
        value: "Grecia",
        label: "Grecia"
    },
    {
        value: "Grenada",
        label: "Grenada"
    },
    {
        value: "Groenlandia",
        label: "Groenlandia"
    },
    {
        value: "Guadalupa",
        label: "Guadalupa"
    },
    {
        value: "Guam",
        label: "Guam"
    },
    {
        value: "Guatemala",
        label: "Guatemala"
    },
    {
        value: "Guinea",
        label: "Guinea"
    },
    {
        value: "Guinea-Bissau",
        label: "Guinea-Bissau"
    },
    {
        value: "Guinea Equatoriale",
        label: "Guinea Equatoriale"
    },
    {
        value: "Guyana",
        label: "Guyana"
    },
    {
        value: "Guyana Francese",
        label: "Guyana Francese"
    },
    {
        value: "Haiti",
        label: "Haiti"
    },
    {
        value: "Honduras",
        label: "Honduras"
    },
    {
        value: "Hong Kong",
        label: "Hong Kong"
    },
    {
        value: "India",
        label: "India"
    },
    {
        value: "Indonesia",
        label: "Indonesia"
    },
    {
        value: "Iran",
        label: "Iran"
    },
    {
        value: "Iraq",
        label: "Iraq"
    },
    {
        value: "Isola di Bouvet",
        label: "Isola di Bouvet"
    },
    {
        value: "Isola di Natale",
        label: "Isola di Natale"
    },
    {
        value: "Isola Heard e Isole McDonald",
        label: "Isola Heard e Isole McDonald"
    },
    {
        value: "Isole Cayman",
        label: "Isole Cayman"
    },
    {
        value: "Isole Cocos",
        label: "Isole Cocos"
    },
    {
        value: "Isole Cook",
        label: "Isole Cook"
    },
    {
        value: "Isole Falkland",
        label: "Isole Falkland"
    },
    {
        value: "Isole Faroe",
        label: "Isole Faroe"
    },
    {
        value: "Isole Marshall",
        label: "Isole Marshall"
    },
    {
        value: "Isole Marianne Settentrionali",
        label: "Isole Marianne Settentrionali"
    },
    {
        value: "Isole Minori degli Stati Uniti d'America",
        label: "Isole Minori degli Stati Uniti d'America"
    },
    {
        value: "Isola Norfolk",
        label: "Isola Norfolk"
    },
    {
        value: "Isole Solomon",
        label: "Isole Solomon"
    },
    {
        value: "Isole Turks e Caicos",
        label: "Isole Turks e Caicos"
    },
    {
        value: "Isole Vergini Americane",
        label: "Isole Vergini Americane"
    },
    {
        value: "Isole Vergini Britanniche",
        label: "Isole Vergini Britanniche"
    },
    {
        value: "Israele",
        label: "Israele"
    },
    {
        value: "Islanda",
        label: "Islanda"
    },
    {
        value: "Italia",
        label: "Italia"
    },
    {
        value: "Kazakhistan",
        label: "Kazakhistan"
    },
    {
        value: "Kenya",
        label: "Kenya"
    },
    {
        value: "Kirghizistan",
        label: "Kirghizistan"
    },
    {
        value: "Kiribati",
        label: "Kiribati"
    },
    {
        value: "Kuwait",
        label: "Kuwait"
    },
    {
        value: "Laos",
        label: "Laos"
    },
    {
        value: "Lettonia",
        label: "Lettonia"
    },
    {
        value: "Lesotho",
        label: "Lesotho"
    },
    {
        value: "Libano",
        label: "Libano"
    },
    {
        value: "Liberia",
        label: "Liberia"
    },
    {
        value: "Libia",
        label: "Libia"
    },
    {
        value: "Liechtenstein",
        label: "Liechtenstein"
    },
    {
        value: "Lituania",
        label: "Lituania"
    },
    {
        value: "Lussemburgo",
        label: "Lussemburgo"
    },
    {
        value: "Macao",
        label: "Macao"
    },
    {
        value: "Macedonia",
        label: "Macedonia"
    },
    {
        value: "Madagascar",
        label: "Madagascar"
    },
    {
        value: "Malawi",
        label: "Malawi"
    },
    {
        value: "Maldive",
        label: "Maldive"
    },
    {
        value: "Malesia",
        label: "Malesia"
    },
    {
        value: "Mali",
        label: "Mali"
    },
    {
        value: "Malta",
        label: "Malta"
    },
    {
        value: "Marocco",
        label: "Marocco"
    },
    {
        value: "Martinica",
        label: "Martinica"
    },
    {
        value: "Mauritania",
        label: "Mauritania"
    },
    {
        value: "Maurizius",
        label: "Maurizius"
    },
    {
        value: "Mayotte",
        label: "Mayotte"
    },
    {
        value: "Messico",
        label: "Messico"
    },
    {
        value: "Moldavia",
        label: "Moldavia"
    },
    {
        value: "Monaco",
        label: "Monaco"
    },
    {
        value: "Mongolia",
        label: "Mongolia"
    },
    {
        value: "Montserrat",
        label: "Montserrat"
    },
    {
        value: "Mozambico",
        label: "Mozambico"
    },
    {
        value: "Myanmar",
        label: "Myanmar"
    },
    {
        value: "Namibia",
        label: "Namibia"
    },
    {
        value: "Nauru",
        label: "Nauru"
    },
    {
        value: "Nepal",
        label: "Nepal"
    },
    {
        value: "Nicaragua",
        label: "Nicaragua"
    },
    {
        value: "Niger",
        label: "Niger"
    },
    {
        value: "Nigeria",
        label: "Nigeria"
    },
    {
        value: "Niue",
        label: "Niue"
    },
    {
        value: "Norvegia",
        label: "Norvegia"
    },
    {
        value: "Nuova Caledonia",
        label: "Nuova Caledonia"
    },
    {
        value: "Nuova Zelanda",
        label: "Nuova Zelanda"
    },
    {
        value: "Oman",
        label: "Oman"
    },
    {
        value: "Paesi Bassi",
        label: "Paesi Bassi"
    },
    {
        value: "Pakistan",
        label: "Pakistan"
    },
    {
        value: "Palau",
        label: "Palau"
    },
    {
        value: "Panama",
        label: "Panama"
    },
    {
        value: "Papua Nuova Guinea",
        label: "Papua Nuova Guinea"
    },
    {
        value: "Paraguay",
        label: "Paraguay"
    },
    {
        value: "Peru",
        label: "Peru"
    },
    {
        value: "Pitcairn",
        label: "Pitcairn"
    },
    {
        value: "Polinesia Francese",
        label: "Polinesia Francese"
    },
    {
        value: "Polonia",
        label: "Polonia"
    },
    {
        value: "Portogallo",
        label: "Portogallo"
    },
    {
        value: "Porto Rico",
        label: "Porto Rico"
    },
    {
        value: "Qatar",
        label: "Qatar"
    },
    {
        value: "Regno Unito",
        label: "Regno Unito"
    },
    {
        value: "Repubblica Ceca",
        label: "Repubblica Ceca"
    },
    {
        value: "Repubblica Centroafricana",
        label: "Repubblica Centroafricana"
    },
    {
        value: "Repubblica del Congo",
        label: "Repubblica del Congo"
    },
    {
        value: "Repubblica Democratica del Congo",
        label: "Repubblica Democratica del Congo"
    },
    {
        value: "Repubblica Dominicana",
        label: "Repubblica Dominicana"
    },
    {
        value: "Reunion",
        label: "Reunion"
    },
    {
        value: "Romania",
        label: "Romania"
    },
    {
        value: "Ruanda",
        label: "Ruanda"
    },
    {
        value: "Sahara Occidentale",
        label: "Sahara Occidentale"
    },
    {
        value: "Saint Kitts e Nevis",
        label: "Saint Kitts e Nevis"
    },
    {
        value: "Saint Pierre e Miquelon",
        label: "Saint Pierre e Miquelon"
    },
    {
        value: "Saint Vincent e Grenadine",
        label: "Saint Vincent e Grenadine"
    },
    {
        value: "Samoa",
        label: "Samoa"
    },
    {
        value: "Samoa Americane",
        label: "Samoa Americane"
    },
    {
        value: "San Marino",
        label: "San Marino"
    },
    {
        value: "Sant'Elena",
        label: "Sant'Elena"
    },
    {
        value: "Santa Lucia",
        label: "Santa Lucia"
    },
    {
        value: "Sao Tome e Principe",
        label: "Sao Tome e Principe"
    },
    {
        value: "Senegal",
        label: "Senegal"
    },
    {
        value: "Kosovo",
        label: "Kosovo"
    },
    {
        value: "Seychelles",
        label: "Seychelles"
    },
    {
        value: "Sierra Leone",
        label: "Sierra Leone"
    },
    {
        value: "Singapore",
        label: "Singapore"
    },
    {
        value: "Siria",
        label: "Siria"
    },
    {
        value: "Slovacchia",
        label: "Slovacchia"
    },
    {
        value: "Slovenia",
        label: "Slovenia"
    },
    {
        value: "Somalia",
        label: "Somalia"
    },
    {
        value: "Spagna",
        label: "Spagna"
    },
    {
        value: "Sri Lanka",
        label: "Sri Lanka"
    },
    {
        value: "Stati Federati della Micronesia",
        label: "Stati Federati della Micronesia"
    },
    {
        value: "Stati Uniti d'America",
        label: "Stati Uniti d'America"
    },
    {
        value: "Sud Africa",
        label: "Sud Africa"
    },
    {
        value: "Sud Georgia e Isole Sandwich",
        label: "Sud Georgia e Isole Sandwich"
    },
    {
        value: "Sudan",
        label: "Sudan"
    },
    {
        value: "Suriname",
        label: "Suriname"
    },
    {
        value: "Svalbard e Jan Mayen",
        label: "Svalbard e Jan Mayen"
    },
    {
        value: "Svezia",
        label: "Svezia"
    },
    {
        value: "Svizzera",
        label: "Svizzera"
    },
    {
        value: "Swaziland",
        label: "Swaziland"
    },
    {
        value: "Tagikistan",
        label: "Tagikistan"
    },
    {
        value: "Tailandia",
        label: "Tailandia"
    },
    {
        value: "Taiwan",
        label: "Taiwan"
    },
    {
        value: "Tanzania",
        label: "Tanzania"
    },
    {
        value: "Territori Britannici dell'Oceano Indiano",
        label: "Territori Britannici dell'Oceano Indiano"
    },
    {
        value: "Territori Francesi del Sud",
        label: "Territori Francesi del Sud"
    },
    {
        value: "Territori Palestinesi Occupati",
        label: "Territori Palestinesi Occupati"
    },
    {
        value: "Timor Est",
        label: "Timor Est"
    },
    {
        value: "Togo",
        label: "Togo"
    },
    {
        value: "Tokelau",
        label: "Tokelau"
    },
    {
        value: "Tonga",
        label: "Tonga"
    },
    {
        value: "Trinidad e Tobago",
        label: "Trinidad e Tobago"
    },
    {
        value: "Tunisia",
        label: "Tunisia"
    },
    {
        value: "Turchia",
        label: "Turchia"
    },
    {
        value: "Turkmenistan",
        label: "Turkmenistan"
    },
    {
        value: "Tuvalu",
        label: "Tuvalu"
    },
    {
        value: "Ucraina",
        label: "Ucraina"
    },
    {
        value: "Uganda",
        label: "Uganda"
    },
    {
        value: "Ungheria",
        label: "Ungheria"
    },
    {
        value: "Uruguay",
        label: "Uruguay"
    },
    {
        value: "Uzbekistan",
        label: "Uzbekistan"
    },
    {
        value: "Vanuatu",
        label: "Vanuatu"
    },
    {
        value: "Venezuela",
        label: "Venezuela"
    },
    {
        value: "Vietnam",
        label: "Vietnam"
    },
    {
        value: "Wallis e Futuna",
        label: "Wallis e Futuna"
    },
    {
        value: "Yemen",
        label: "Yemen"
    },
    {
        value: "Zambia",
        label: "Zambia"
    },
    {
        value: "Zimbabwe",
        label: "Zimbabwe"
    },
    {
        value: "Serbia",
        label: "Serbia"
    },
    {
        value: "Montenegro",
        label: "Montenegro"
    },
    {
        value: "Timor Est",
        label: "Timor Est"
    },
    {
        value: "Guernsey",
        label: "Guernsey"
    }
]
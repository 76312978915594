<script>
    import {slide} from "svelte/transition";
    export let title;
    let isOpen= false;

    function toggle() {
        isOpen = !isOpen;
    }
</script>

<div class="w-full">
    <button class="py-4 w-full text-left flex items-center focus:outline-none text-3xl text-black font-light border-black border-t-2 border-gray-200 " on:click={toggle}>
        <div class="px-3">
            <i class="fa {isOpen ? 'fa-minus' : 'fa-plus'} transition-all fa-sm"></i>
        </div>
        <div>
            {title}
        </div>
    </button>
    {#if isOpen}
        <div class="p-3 mb-3 " transition:slide={{ duration: 200 }}>
            <div class="bg-gray-50 p-3 border-2 text-lg font-light text-2xl text-black shadow-lg">
                <slot></slot>
            </div>
        </div>
    {/if}
</div>

<style>
    i {
        color: gray;
    }

</style>

<script>
    import CountrySelector from "./CountrySelector.svelte";
    import Select from "svelte-select";
    import { slide } from 'svelte/transition'
    import OrderSelector from "./OrderSelector.svelte";
    import AreaSelector from "./AreaSelector.svelte";
    import DateSearcher from "./DateSearcher.svelte";
    import DefaultSelector from "./DefaultSelector.svelte";
    import CitySearcher from "./CitySearcher.svelte";
    import AreaSearcher from "./AreaSearcher.svelte";
    import {onMount} from "svelte";

    const debounceTime = 500;
    export let maxReferenceYear;
    export let firstName, lastName, email, gender, birthDateFrom, birthDateTo, foreign, residenceForeign, residenceProvince, residenceRegion, residenceCity, active, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted, headerSort;
    export let referenceYear;
    export let isPrints = false;
    let timer;
    let timeFilter = "referenceYear";
    let spaceFilter = "country";
    //"country", "region", "province", "city"
    let spaceOrderBy = "country";
    //"isActive", "payment"
    let statusFilter;
    let sortColumn = undefined;
    let sortDirection = undefined;
    let referenceYears = [];
    let statusFilters = [{
        value: "payment",
        label: "Pagamento"
    }];
    let isExpanded = true;

    function debounce (v) {
        clearTimeout(timer);
        const value = v.target.value;

        switch (v.target.name) {
            case "firstName":
                timer = setTimeout(() => {
                    firstName = value;
                }, debounceTime);
                break;
            case "lastName":
                timer = setTimeout(() => {
                    lastName = value;
                }, debounceTime);
                break;
            case "email":
                console.log("okokok");
                timer = setTimeout(() => {
                    email = value;
                }, debounceTime);
                break;
            default:
                firstName = firstName;
                break;
        }
    }

    function resetStatusFilter() {
        paymentMethod = undefined;
        active = undefined;
    }

    function resetSpaceFilter() {
        residenceForeign = undefined;
        residenceCity = undefined;
        residenceRegion = undefined;
        residenceProvince = undefined;
    }

    function resetTimeFilter() {
        referenceYear = undefined;
        membershipCreatedFrom, membershipCreatedTo = undefined;
        paymentDateFrom, paymentDateTo = undefined;
    }

    function handleSort() {
        if (sortColumn === undefined) {
            sortDirection = undefined;

            return;
        }

        if(sortColumn!== undefined && sortDirection === undefined) {
            sortColumn = "asc";
            return;
        }

        if(sortColumn && sortDirection){
            headerSort = {name: sortColumn.value, sort: sortDirection.value};
            console.log(headerSort)
        }
    }

    function generateRefYears () {
        const minYear = 2017;

        for(let i =minYear; i<= maxReferenceYear; i++) {
            referenceYears.push({label: i.toString(), value: i.toString()})
        }

        referenceYear = maxReferenceYear.toString();
    }

    function expandFilters () {
        isExpanded = !isExpanded;
    }

    function generateStatusFilter() {
        statusFilters.push({
                value: isPrints ? "hasBeenPrinted" : "isActive",
                label: isPrints ? "Stampa" : "Attivazione"
            });

        statusFilter =  isPrints ? "hasBeenPrinted" : "isActive";
    }

    onMount(() => {
        generateStatusFilter();
        generateRefYears();
    })

    $: statusFilter && resetStatusFilter();
    $: spaceFilter && resetSpaceFilter();
    $: timeFilter && resetTimeFilter();
    $: sortColumn ,sortDirection,  handleSort();
</script>

<div class="w-full flex flex-col gap-1 justify-between">
    <div class="text-gray-500 flex flex-row justify-between">
        <div>
            Filtra per
        </div>

        <div class="text-lightBlue-500 cursor-pointer" on:click={() => expandFilters()}>
            {isExpanded ? "Riduci" : "Espandi"}
            <i class="fa pl-4  {isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}" />
        </div>
    </div>


    <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
        <!--            nominativo-->
        <div class="w-full">
            <input
                    class="w-full pr-4 h-11"
                    name="firstName"
                    id="firstName"
                    type="text"
                    maxlength="200"
                    placeholder="Nome"
                    on:input={(input) => debounce(input)}
            >
        </div>
        <div class="w-full">
            <input
                    class="w-full pr-4 h-11 transition-all"
                    name="lastName"
                    id="lastName"
                    maxlength="200"
                    type="text"
                    placeholder="Cognome"
                    on:input={(input) => debounce(input)}>
        </div>
    </div>

    <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
        <div class="w-full">
            <input
                    class="w-full pr-4 h-11"
                    name="email"
                    id="email"
                    type="text"
                    maxlength="200"
                    placeholder="Email"
                    on:input={(input) => debounce(input)}
            >
        </div>
    </div>

    {#if isExpanded}

        <div class="flex flex-col items-center gap-1 w-full mb-2 lg:mb-0" in:slide>
            <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
                <div class="w-full lg:w-6/12">
                    <Select
                            items={[
                        {
                            value: "referenceYear",
                            label: "Anno"
                        },
                        {
                            value: "membershipCreatedAt",
                            label: "Inserimento"
                        },
                        {
                            value: "paidAt",
                            label: "Pagamento"
                        },
                    ]}
                            floatingConfig={ {placement: 'top-start'}}
                            id="timeFilter"
                            type= "text"
                            clearable={false}
                            containerStyles={`border: 1px solid #D8D4D4; max-height: 2rem !important; min-height: 2.75rem; border-radius: 0px`}

                            bind:value={timeFilter}
                    />
                </div>

                <div class="w-full lg:w-6/12">
                    {#if timeFilter?.value === "referenceYear"}
                        <Select
                                items={[...referenceYears]}
                                id="referenceYear"
                                floatingConfig={ {placement: 'top-start'}}
                                type="text"
                                clearable={false}
                                placeholder="Seleziona un anno di riferimento"
                                containerStyles={`border: 1px solid #D8D4D4; max-height: 2rem !important; min-height: 2.75rem; border-radius: 0px;`}
                                bind:value={referenceYear}
                        />
                    {:else}
                        {#if timeFilter.value === "membershipCreatedAt"}
                            <div class="flex gap-3 h-11 border px-2">
                                <div class="w-full flex items-center">
                                    <DateSearcher
                                            placeholderValue="Dal"
                                            bind:date={membershipCreatedFrom}
                                    />
                                </div>
                                <div class="w-full flex items-center">
                                    <DateSearcher
                                            placeholderValue="Al"
                                            bind:date={membershipCreatedTo}
                                    />
                                </div>
                            </div>

                            {:else}
                                <div class="flex gap-3 h-11 border px-2">
                                    <div class="w-full flex items-center">
                                        <DateSearcher
                                                placeholderValue="Dal"
                                                bind:date={paymentDateFrom}
                                        />
                                    </div>
                                    <div class="w-full flex items-center">
                                        <DateSearcher
                                                placeholderValue="Al"
                                                bind:date={paymentDateTo}
                                        />
                                    </div>
                                </div>
                        {/if}
                    {/if}
                </div>
            </div>

            <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
                <div class="w-full lg:w-6/12">
                    <AreaSelector
                            bind:value={spaceFilter}
                    />
                </div>

                <div class="w-full lg:w-6/12">
                    {#if spaceFilter.value === "country"}
                        <CountrySelector
                                bind:foreign={residenceForeign}
                        />
                    {:else}
                        {#if spaceFilter.value === "region"}
                            <div class="flex gap-1 h-11">
                                <AreaSearcher
                                        bind:valueName={residenceRegion}
                                        subject="region"
                                        placeholder="Seleziona una regione"
                                        clearable="true"
                                />
                            </div>
                        {:else}
                            {#if spaceFilter.value === "city"}
                                <CitySearcher
                                        bind:cityName={residenceCity}
                                        isSearchBar="true"
                                />
                            {:else }
                                <AreaSearcher
                                        bind:valueName={residenceProvince}
                                        subject="province"
                                        placeholder="Seleziona una provincia"
                                        clearable="true"
                                />
                            {/if}
                        {/if}
                    {/if}
                </div>
            </div>

            <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
                <div class="w-full lg:w-6/12">
                    <Select
                            items={statusFilters}
                            floatingConfig={ {placement: 'top-start'}}
                            id="statusFilter"
                            clearable="{false}"
                            type= "text"
                            containerStyles={`border: 1px solid #D8D4D4; max-height: 2rem !important; min-height: 2.75rem; border-radius: 0px`}

                            bind:value={statusFilter}
                    />
                </div>

                <div class="w-full lg:w-6/12">
                    {#if statusFilter?.value === "payment"}
                        <DefaultSelector
                                firstOption="Bonifico"
                                secondOption="Carta di credito"
                                bind:inputValue={paymentMethod}
                        />
                    {:else}
                        {#if statusFilter?.value === "hasBeenPrinted"}
                            <DefaultSelector
                                    firstOption="Stampato"
                                    secondOption="Non stampato"
                                    bind:inputValue={wasPrinted}
                            />
                        {:else}
                            <DefaultSelector
                                    firstOption="Attivo"
                                    secondOption="Non attivo"
                                    bind:inputValue={active}
                            />
                        {/if}
                    {/if}
                </div>
            </div>
        </div>

    {/if}

    <div class="text-gray-500">Ordina per</div>


    <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
        <div class="w-full lg:w-6/12 z-10">
            <Select
                    items={[
                        {
                            value: "country",
                            label: "Stato residenza"
                        },
                        {
                            value: "region",
                            label: "Regione"
                        },
                        {
                            value: "province",
                            label: "Provincia"
                        },
                        {
                            value: "city",
                            label: "Città"
                        },
                        {
                            value: "createdAt",
                            label: "Data iscrizione"
                        },
                        {
                            value: "paidAt",
                            label: "Data pagamento"
                        },
                        {
                            value: "firstName",
                            label: "Nome"
                        },
                        {
                            value: "lastName",
                            label: "Cognome"
                        },
                        {
                            value: "email",
                            label: "Email"
                        },
                    ]}
                    floatingConfig={ {placement: 'top-start'}}
                    id="timeOrderBy"
                    type= "text"
                    containerStyles={`border: 1px solid #D8D4D4; max-height: 2rem !important; min-height: 2.75rem; border-radius: 0px`}
                    placeholder="Selziona un ordinamento"

                    bind:value={sortColumn}
            />
        </div>

        <div class="w-full lg:w-6/12">
            <OrderSelector
                bind:value={sortDirection}
                bind:isColumnSelected={sortColumn}
            />
        </div>
    </div>

</div>



<style>

    :root {
        --date-input-width: 100%;
        --date-picker-highlight-border : 0px solid;
        --date-picker-highlight-shadow: none;
        --date-picker-selected-color: white;
        --date-picker-selected-background: #ef3f3e;
        --date-picker-highlight-border: white;
    }

    input {
        border: 1px solid rgba(212, 212, 216, 1);
    }

    /*.border-color-date {*/
    /*    border-color: #71717a;*/
    /*}*/
</style>



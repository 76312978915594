<script>
    export let email = "";
    export let firstname= "";
    export let lastname= "";
    export let sort = [];
    let timer;

    function debounceInput (v) {
        clearTimeout(timer);

        switch (v.target.name) {
            case "email":
                timer = setTimeout(() => {
                    email = v.target.value;
                }, 200);
                break;
            case "firstname":
                timer = setTimeout(() => {
                    firstname = v.target.value;
                }, 200);
                break;
            case "lastname":
                timer = setTimeout(() => {
                    lastname = v.target.value;
                }, 200);
                break;
            default:
                email = email;
                break;
        }
    }

</script>

<div class="w-full flex flex-col gap-1 justify-between">
    <div class="w-full">
        <div class="text-gray-500">
            Filtra per
        </div>

        <input
                type="email"
                name="email"
                on:input={(value) => debounceInput(value)}
                placeholder="Email dell'utente"
                class="h-11 w-full border-gray-300"
        >
    </div>

    <div class="flex flex-col items-center gap-1 w-full mb-2 lg:flex-row lg:mb-0">
        <div class="w-full lg:w-6/12">

            <input
                    type="text"
                    name="firstname"
                    on:input={(value) => debounceInput(value)}
                    placeholder="Nome dell'utente"
                    class="h-11 w-full border-gray-300"
            >
        </div>
        <div class="w-full lg:w-6/12">

            <input
                    type="text"
                    name="lastname"
                    on:input={(value) => debounceInput(value)}
                    placeholder="Congnome dell'utente"
                    class="h-11 w-full border-gray-300"
            >
        </div>
    </div>
</div>
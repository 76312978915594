<script>

    import {onMount} from "svelte";
    import toast from "svelte-french-toast";
    import {navigate} from "svelte-routing";
    import {getSubByToken} from "../../../services/subscribersService";
    import SubscriberMultiForm from "./SubscriberMultiForm.svelte";

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");

    function defaultError() {
        toast.error("Token invalido, invia una richiesta di accesso utilizzando la tua mail", {duration: 8000});
        navigate("/login", {replace: true});
    }


    onMount(async () => {
        if(!token) {
            toast.error("Effettua l'accesso e iscrivi altre persone utilizzando il link che arriverà nella tua mail", {duration: 8000});
            navigate("/login", {replace: true});
        }

        getSubByToken(token).then((resp) => {
            if(resp?.status !== 200) {
                defaultError();
            }
        }).catch((err) => {
            defaultError();
            console.log(err);
        })

    })


</script>

<div class="w-full h-full relative flex flex-col justify-center items-center break-words pb-2">
    <div class="title-red font-bold w-full text-center">
        Raccolta adesioni
    </div>
    <div class="md:w-6/12">
        <SubscriberMultiForm token={token} />
    </div>
</div>

<script>
    import { createForm } from "svelte-forms-lib";
    import { slide, fade } from 'svelte/transition';
    import AddSubscriberButton from "./AddSubscriberButton.svelte";
    import GenderSelector from "../../SearchBars/GenderSelector.svelte";
    import DateSearcher from "../../SearchBars/DateSearcher.svelte";
    import PrefixSearcher from "../PrefixSearcher.svelte";
    import {prefixes} from "../../../utils/phonePrefix";
    import { imask } from 'svelte-imask';
    import CountrySearcher from "../../SearchBars/CountrySearcher.svelte";
    import CapSearcher from "../../SearchBars/CapSearcher.svelte";
    import CitySearcher from "../../SearchBars/CitySearcher.svelte";
    import GroupNameSearcher from "../../SearchBars/GroupNameSearcher.svelte";
    import SubscriberAccordion from "./SubscriberAccordion.svelte";
    import UgsSelector from "./UgsSelector.svelte";
    import {subscribeMultple} from "../../../services/subscribersService";
    import {navigate} from "svelte-routing";
    import toast from "svelte-french-toast";
    import sub from "date-fns/sub";
    import {onMount} from "svelte";
    import {getSettings} from "../../../services/settingService";

    export let token;
    let ugsMaxAgeInclusive;
    let registrationMinAge;
    let subErrors = [];
    let isPressed = false;
    const prefixList = prefixes.countries.map((prefix) => {return {label: prefix.name, value: prefix.code }});
    const phoneMask = { mask: '000 000 0000' }
    const subInfo = {
        subscriber: {
            firstName: "",
            lastName: "",
            birthPlace: "",
            birthDate: "",
            gender: "",
            email: "",
            nationality: "Italia",
            residenceAddress : {
                street: "",
                number: "",
                secondary: "",
                city: "",
                region: "",
                zip: "",
                province: "",
                country: "Italia",
            },
            phone: {
                code: "+39",
                number:  ""
            },
            isUGS: true
        },
        groupId: "",
        paidAmount: "",
        isUGSActive: false,
        isItaly: true,
        zips: [],
    }

    const {
        form,
        errors,
        state,
        handleChange,
        handleSubmit,
        handleReset
    } = createForm({
        initialValues: {
            subscribers: [
                {
                    ...subInfo
                }
            ]
        },
        onSubmit: (formSubscriber) => {
            isPressed = !isPressed;
            const subscribersM = [...handleSubmitData({subs :formSubscriber.subscribers})];

            subscribeMultple(token, subscribersM).then((resp) => {

                if(resp?.status === 200) {

                    if(resp.data.errors.length === 0) {
                        navigate("/", {replace: true});
                        toast.success("Gli iscritti sono stati inseriti correttamente");
                        isPressed = !isPressed;
                        return;
                    }
                    handleErrors(resp.data.errors);

                    isPressed = !isPressed;
                    return;
                }
                toast.error("Non è stato possibile inserire gli iscritti, riprova più tardi");
            }).catch((err) => {
                isPressed = !isPressed;
                toast.error("Non è stato possibile inserire gli iscritti, riprova più tardi");
                console.error(err);
            })
        }
    });

    function handleSubmitData(subscribers) {

        return subscribers.subs.map((sub) => {
            if(sub.subscriber.nationality?.value) {
                sub.subscriber.nationality = sub.subscriber.nationality?.value
            }

            if(sub.subscriber.phone.code?.value) {
                sub.subscriber.phone.code = sub.subscriber.phone.code.value
            }

            if(sub.subscriber.residenceAddress.country?.value) {
                sub.subscriber.residenceAddress.country = sub.subscriber.residenceAddress.country.value;
            }

            if(sub.subscriber.residenceAddress.zip?.value) {
                sub.subscriber.residenceAddress.zip = sub.subscriber.residenceAddress.zip.value;
            }

            if(sub.subscriber.residenceAddress.city?.value?.name) {
                sub.subscriber.residenceAddress.city = sub.subscriber.residenceAddress.city.value?.name;
            }

            if(sub.subscriber.residenceAddress.city?.value) {
                sub.subscriber.residenceAddress.city = sub.subscriber.residenceAddress.city.value;
            }

            if(sub.subscriber.residenceAddress.country !== "Italia") {
                delete sub.subscriber.residenceAddress.region;
                delete sub.subscriber.residenceAddress.province;
            }

            if(sub.groupId?.label) {
                sub.groupId = sub.groupId.value;
            }

            return sub;
        });
    }

    const addSubscriber = () => {
        $form.subscribers = $form.subscribers.concat({
            subscriber: {
                firstName: "",
                lastName: "",
                birthPlace: "",
                birthDate: "",
                gender: "",
                email: "",
                nationality: "Italia",
                residenceAddress : {
                    street: "",
                    number: "",
                    secondary: "",
                    city: "",
                    region: "",
                    zip: "",
                    province: "",
                    country: "Italia",
                },
                phone: {
                    code: "+39",
                    number:  ""
                },
                isUGS: true
            },
            groupId: "",
            paidAmount: "",
            isUGSActive: false,
            isItaly: true,
            zips: [],
        });

        console.log($form.subscribers);
    };

    const removeSubscriber = i => () => {
        $form.subscribers = $form.subscribers.filter((u, j) => j !== i);
    };

    function handleStateValue(value, j) {
        $form.subscribers[j].isItaly = value.detail.value === "Italia";
        $form.subscribers[j].subscriber.residenceAddress.city = "";
        $form.subscribers[j].zips = [];
    }

    function handleChangeCity(value, j) {
        $form.subscribers[j].subscriber.residenceAddress.zip = "";
        $form.subscribers[j].zips = value.detail.value.zip.map((e) => {return {value: e, label: e}});
        $form.subscribers[j].subscriber.residenceAddress.zip = $form.subscribers[j].zips[0];

        $form.subscribers[j].subscriber.residenceAddress.region = $form.subscribers[j].subscriber.residenceAddress.city.value.region;
        $form.subscribers[j].subscriber.residenceAddress.province = $form.subscribers[j].subscriber.residenceAddress.city.value.province;
    }

    function translateError(err) {

        switch (true) {
            case err === "Group not found":
                err = "Circolo non trovato";
                break;
            case err.includes("Subscriber is already present for the current subscription active year"):
                err = "L'iscritto associato a questa mail è già iscritto per questo anno";
                break;
            case err.includes("Subscriber is too young"):
                err = "L'iscritto è troppo giovane";
                break;
            case err === "Residence address is not valid.":
                err = "L'indirizzo utilizzato non è valido";
                break;
            default:
                err = "Dati inalidi";
                break;

        }

        return err
    }

    function handleErrors(errors) {
        let indexes;

        indexes = errors.map((err) => {
            subErrors[err.index] = translateError(err.message);

            return err.index;
        })

        let errorMessage = `Ci sono errori relativi ${indexes.length === 1 ? `all'iscritto ${indexes[0] +1}` : "agli iscritti: "}`;

        if(errors.length !== 1) {
            indexes.forEach((index) => {
                errorMessage += index +1 + " ";
            })
        }

        toast.error(errorMessage);
    }

    async function getAgeLimits() {
        getSettings().then((data) => {
            registrationMinAge= data.data.registrationMinAge;
        }).catch((err) => {
            console.error(err);

            toast("Errore server, riprova più tardi");
            navigate("/", {replace: true});
        })
    }

    onMount(async () => {
        await getAgeLimits();
    })
</script>

<div class="w-full flex flex-col gap-7 items-center justify-center">
    <form class="w-full flex-col flex items-center gap-3 lg:px-10" on:submit={handleSubmit}>

        {#each $form.subscribers as subscriber, j}
            <SubscriberAccordion>
                <div slot="header" class="flex justify-between align-middle items-center h-full w-full">
                    <div class="flex justify-center items-center font-450 text-xl h-full">
                        Iscritto #{j+1}
                    </div>
                    <div id="error{j}" class="font-450 text-red-400">
                        {subErrors[j] ?? ""}
                    </div>
                    <div>
                        {#if $form.subscribers.length !== 1}
                            <button class="w-52 h-11 text-center bg-red-500 font-450 py-3 text-white hover:shadow-lg transition-all outline-none focus:outline-none" transition:fade on:click|preventDefault={removeSubscriber(j)}>
                                Rimuovi iscritto <i class="ml-2 fa fa-minus-circle"></i>
                            </button>
                        {/if}
                    </div>
                </div>

                <div slot="form" class="flex flex-col justify-between w-full overflow-x-auto h-full px-0.5 md:px-8 mt-4" transition:slide>

                    <!-- Nominativo -->
                    <label class="block font-450">
                        Nominativo
                    </label>
                    <div class="w-full flex flex-col gap-6 my-2 md:flex-row">
                        <div class="w-full md:w-1/2">
                            <input
                                    class="w-full pr-4 h-11 input-home focus:outline-none"
                                    type="text"
                                    placeholder="Nome"
                                    bind:value={$form.subscribers[j].subscriber.firstName}
                                    on:change={handleChange}
                                    required
                            >
                        </div>
                        <div class="w-full md:w-1/2">
                            <input
                                    class="w-full h-11 input-home"
                                    placeholder="Cognome"
                                    maxlength="200"
                                    type="text"
                                    bind:value={$form.subscribers[j].subscriber.lastName}
                                    on:change={handleChange}
                                    required
                            >
                        </div>
                    </div>

                    <!-- Luogo di nascita -->
                    <label class="font-450">
                        Luogo di nascita
                    </label>
                    <div class="w-full my-2">
                        <input
                                class="w-full pr-4 h-11 input-home"
                                maxlength="200"
                                placeholder="Luogo di nascita"
                                type="text"
                                bind:value={$form.subscribers[j].subscriber.birthPlace}
                                on:change={handleChange}
                                required
                        >
                    </div>

                    <!-- Data di nascita -->
                    <label class="block font-450">
                        Data di nascita
                    </label>
                    <div class="h-11 w-full bg-white input-home my-2 flex items-center">
                        <DateSearcher
                            bind:isUGSActive={$form.subscribers[j].isUGSActive}
                            bind:date={$form.subscribers[j].subscriber.birthDate}
                            isNullable={false}
                            max={sub(new Date(), {years: registrationMinAge})}
                            placeholderValue="Data di nascita (DD/MM/YYYY)"
                        />
                    </div>

                    <!-- Genere -->
                    <label class="block font-450">
                        Genere
                    </label>
                    <div class="w-full my-2">
                        <GenderSelector
                            bind:value={$form.subscribers[j].subscriber.gender}
                        />
                    </div>

                    <!-- Nazionalità -->
                    <label class="block font-450">
                        Nazionalità
                    </label>
                    <div class="w-full my-2">
                        <div class="h-11">
                            <CountrySearcher
                                    handleChange={() => handleChange}
                                    required={true}
                                    placeholder="Nazionalità"
                                    name="nationality"
                                    bind:value={$form.subscribers[j].subscriber.nationality}
                            />
                        </div>
                    </div>

                    <!-- Residenza -->
                    <label class="block font-450">
                        Residenza
                    </label>
                    <div class="w-full my-2">
                        <div class="h-11">
                            <CountrySearcher
                                handleChange={(value) => {
                                    handleChange;
                                    handleStateValue(value, j);
                                }}
                                required={true}
                                placeholder="Stato"
                                name="country"
                                bind:value={$form.subscribers[j].subscriber.residenceAddress.country}
                            />
                        </div>
                    </div>

                    <!-- Città e cap -->
                    <div class="w-full flex flex-col gap-6 my-2 md:flex-row">
                        <div class="w-full md:w-8/12">
                            {#if $form.subscribers[j]?.isItaly === true}
                                <div class="w-full h-11">
                                    <CitySearcher
                                            bind:value={$form.subscribers[j].subscriber.residenceAddress.city}
                                            handleChange={(value) => {
                                                handleChangeCity(value, j);
                                                handleChange;

                                        }}
                                            required={true}
                                    />
                                </div>
                            {:else}
                                <input
                                        placeholder="Nome della città"
                                        name="city"
                                        type="text"
                                        class="w-full h-11 input-home"
                                        bind:value={$form.subscribers[j].subscriber.residenceAddress.city}
                                        required
                                />
                            {/if}
                        </div>
                        <div class="w-full md:w-4/12">
                            {#if $form.subscribers[j].isItaly}
                                <div class="h-11">
                                    <CapSearcher
                                            placeholder={$form.subscribers[j].isItaly ? "Scegli il tuo CAP" : "Scegli il tuo ZIP"}
                                            bind:value={$form.subscribers[j].subscriber.residenceAddress.zip}
                                            handleChange={handleChange}
                                            required={true}
                                            bind:zips={$form.subscribers[j].zips}
                                    />
                                </div>
                            {:else }
                                <input
                                        placeholder="Zip"
                                        type="text"
                                        class="w-full h-11 input-home"
                                        name="zip"
                                        maxlength="10"
                                        bind:value={$form.subscribers[j].subscriber.residenceAddress.zip}
                                        required
                                />
                            {/if}
                        </div>
                    </div>

                    <!-- Indirizzo e numero civico -->
                    <div class="flex flex-col gap-1 my-2">
                        <div class="w-full flex flex-col gap-6 md:flex-row">
                            <div class="w-full md:w-8/12">
                                <input
                                        placeholder="Indirizzo"
                                        name="street"
                                        type="text"
                                        class="w-full h-11 input-home"
                                        bind:value={$form.subscribers[j].subscriber.residenceAddress.street}
                                        required
                                />
                            </div>
                            <div class="w-full md:w-4/12">
                                <input
                                        placeholder="Numero civico"
                                        name="number"
                                        type="text"
                                        class="w-full h-11 input-home"
                                        bind:value={$form.subscribers[j].subscriber.residenceAddress.number}
                                        required
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Indirizzo secondario -->
                    <div class="w-full my-2">
                        <input
                            placeholder="Scala / Palazzo / Settore"
                            type="text"
                            class="w-full h-11 input-home"
                            name="secondary"
                            bind:value={$form.subscribers[j].subscriber.residenceAddress.secondary}
                        />
                    </div>

                    <!-- Email -->
                    <label class="font-450">
                        Indirizzo e-mail
                    </label>
                    <div class="w-full my-2">
                        <input
                            placeholder="E-mail"
                            name="email"
                            type="email"
                            class="w-full h-11 input-home"
                            bind:value={$form.subscribers[j].subscriber.email}
                            required
                        />
                    </div>

                    <!--  Numero di telefono  -->
                    <label class="font-450">
                        Numero di telefono
                    </label>
                    <div class="w-full flex gap-1 my-2">
                        <div class="w-4/12 h-11">
                            <PrefixSearcher
                                    bind:value={$form.subscribers[j].subscriber.phone.code}
                                    handleChange={() => handleChange}
                                    required={true}
                            />
                        </div>
                        <input
                                placeholder="Numero di telefono"
                                name="phone.number"
                                type="tel"
                                class="w-8/12 h-11 input-home"
                                use:imask={ phoneMask }
                                id="phone.number"
                                maxlength="15"
                                required
                                bind:value={$form.subscribers[j].subscriber.phone.number}
                        />
                    </div>

                    <!-- UGS -->
                    {#if $form.subscribers[j].isUGSActive}
                        <label class="font-450">
                            Adesione all'Unione dei Giovani di Sinistra (UGS)
                        </label>
                        <div class="w-full my-2">
                             <UgsSelector bind:value={$form.subscribers[j].subscriber.isUGS}/>
                        </div>
                    {/if}

                    <!-- Circolo -->
                    <label class="font-450">
                        Circolo
                    </label>
                    <div class="w-full my-2">
                        <div class="h-11 w-full">
                            <GroupNameSearcher
                                bind:value={$form.subscribers[j].groupId}
                                bind:province={$form.subscribers[j].subscriber.residenceAddress.province}
                                handleChange={() => handleChange}
                            />
                        </div>
                    </div>

                    <!-- Quota -->
                    <label class="font-450">
                        Quota
                    </label>
                    <div class="w-full relative my-2">
                        <input
                                placeholder="Somma pagata"
                                name="paidAmount"
                                type="number"
                                min="{0}"
                                class="w-full h-11 input-home"
                                bind:value={$form.subscribers[j].paidAmount}
                                required
                        />
                        <i class="absolute currency-icon font-450">€</i>
                    </div>
                </div>

            </SubscriberAccordion>
        {/each}

        <div class="flex flex-col w-1/2 gap-3">
            <AddSubscriberButton on:add={addSubscriber} on:reset={handleReset} />
            <button disabled="{isPressed}" type="submit" class="w-full bg-lightBlue-500 font-450 py-3 text-white hover:shadow-lg transition-all outline-none focus:outline-none">
                Conferma <i class="ml-2 fa fa-check"></i>
            </button>
        </div>
    </form>

</div>

<style>
    .currency-icon {
        top: 0.7rem;
        right: 3rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
<script>
    import {Moon} from "svelte-loading-spinners";
    import {createEventDispatcher} from "svelte";

    export let isPrints = false;
    export let isPressed = false;
    export let handleClick;
    const dispatch = createEventDispatcher();

    function dispatchEvent(event) {
        dispatch(event, {});
    }
</script>

<button class="border-0 bg-blueGray-600 p-2 px-4 font-semibold text-white hover:shadow-lg w-44 flex justify-center items-center" on:click|preventDefault={handleClick}>
    {#if isPressed}
        <Moon size="20" color="white" />
    {:else }
        {isPrints ? "Stampa" : "Esporta in XLSX"}
    {/if}
</button>
<script>
    import Select from "svelte-select";
    import {getGroupChildrenByName} from "../../services/groupService";

    let groups = [];
    export let province = "Italia";
    export let value;
    export let handleChange;

    function searchChildrenByName() {
        getGroupChildrenByName(province).then((data) => {
            groups = data?.data?.map((group) => {return {label: group.name, value: group.id}});
        })
    }

    $: province && searchChildrenByName(province);

</script>

<Select
        items = {groups}
        id="group"
        type= "text"
        placeholder="Circolo associato"
        on:change={handleChange}
        bind:value={value}
        required={false}
        disabled={!province}
        class="hover:shadow-md transition-all"
        containerStyles={`
            border: 1px solid #e0e0e0;
            box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);
            border-radius: 0;`
        }
/>
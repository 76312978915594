<script>
    import {navigate} from "svelte-routing";
    import {deleteSubscriber} from "../../../services/subscribersService";
    import {showToast} from "../../../services/toastService";
    import DeleteButton from "../../Cards/DeleteButton.svelte";

    export let isEdit;
    export let isBackOffice;
    export let isRenew;
    export let id;

    async function handleDeleteSubscriber() {
        deleteSubscriber(id).then((resp) => {
            if(resp.status === 200 && resp?.data?.id) {
                showToast("success", "Iscritto eliminato", "top-center");
                navigate("/admin/subscriber/table");
            }
        }).catch((err) => {
            handleError(err);
        })
    }

    function handleError(err) {
        if(err?.response?.status === 404) {
            showToast("error", "Iscritto non trovato", "top-center");
            console.error(err);
            return;
        }

        if(err?.response?.status === 403 || err?.response?.status === 401) {
            showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
            console.error(err);
            return;
        }

        defaultError();
    }

    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }
</script>

<div class="rounded-t mb-0 px-8 py-3 border-0">
    <div class="flex flex-wrap items-center justify-between w-full">
        <div class="relative w-full max-w-full flex-grow flex-1 {isBackOffice ? '' : 'text-center'}">
            <h3 class="font-semibold {isBackOffice ? 'text-2xl' : 'text-3xl'}">
                {#if isRenew}
                    Rinnovo iscrizione

                    {:else}
                        {#if !isBackOffice}
                            Iscriviti
                        {:else }
                            {isEdit ? "Modifica" : "Crea"} iscritto
                        {/if}
                {/if}
            </h3>
        </div>
        {#if isEdit}
            {#if localStorage.getItem(process.env.USER_ROLE) === "ADMIN" && isBackOffice}
                <DeleteButton element="l'iscritto" isEdit="{true}"  on:delete={() => handleDeleteSubscriber()}/>
            {/if}
        {/if}
    </div>
</div>
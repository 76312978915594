<script>
    import {onMount} from "svelte";
    import {navigate} from "svelte-routing";
    import {
        exportSubscribersToXLSX,
        getSubscribers
    } from "../../../services/subscribersService";
    import SubscriberSearchBar from "../../SearchBars/SubscriberSearchBar.svelte";
    import SubscriberCard from "./SubscriberCard.svelte";
    import TableFooter from "../TableFooter.svelte";
    import SubscriberHeader from "../../Headers/SubscriberHeader.svelte";
    import {resetPage} from "../../../utils/resetPage";
    import {showToast} from "../../../services/toastService";
    import StretchLoader from "../../Loaders/StretchLoader.svelte";
    import EmptyTable from "../EmptyTable.svelte";
    import {getSettings} from "../../../services/settingService";
    import toast from "svelte-french-toast";
    import Table from "../Table.svelte";
    import PrintButton from "../../Button/PrintButton.svelte";
    import {format} from "date-fns";
    let page = 1;
    let size = 10;
    let tableSort = [];
    let headerSort = undefined;
    let totalPages = 10;
    let subscribers = [];
    let totalCount;
    let referenceYear;
    let isLoading;
    let firstName, lastName, email, gender, birthDateFrom, birthDateTo, foreign, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, active, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo  = undefined;
    let isAdmin = localStorage.getItem(process.env.USER_ROLE) !== "USER";
    let colspan = 11;
    let maximumPayment;
    let minPaymentUGS;
    let minPaymentNotUGS;
    let maxReferenceYear;
    let isPressed = false;

    async function getSubscriberList () {
        isLoading = true;

        const sort = tableSort ? [...tableSort] : [];
        headerSort ? sort.push(headerSort) : null;

        getSubscribers (page, size, sort, firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, undefined, active).then((resp) => {

            if(resp.status === 200 && resp?.data) {
                subscribers = resp.data.data;
                totalPages = Math.ceil(resp.data?.totalCount / size);
                totalCount = resp.data.totalCount;
            }
            isLoading = false;
        }).catch((err) => {
            isLoading = false;
            console.log(err);

            if(err?.response?.data?.status === 403){
                showToast("error", "Non hai i permessi per compiere questa azione");
                navigate("/", {replace: true});
            }

            if(err?.response?.data?.status === 500){
                showToast("error", "Errore durante la ricerca, riprovare");
                return;
            }

            showToast("error", "Errore server");
            navigate("/", {replace: true});
        })
    }

    async function handleChange() {
        await getSubscriberList();
    }

    async function getPaymentLimit() {
        getSettings().then((data) => {
            maxReferenceYear = data.data.subscriptionActiveYear;
            maximumPayment = data.data.maximumPayment;
            minPaymentUGS = data.data.minimumPaymentUGS;
            minPaymentNotUGS = data.data.minimumPaymentNotUGS;
        }).catch((err) => {
            console.error(err);
            toast("Errore server, riprovare più tardi");
            navigate("/", {replace: true});
        })
    }

    async function handleExport() {
        isPressed = true;
        console.log(referenceYear)
        exportSubscribersToXLSX(firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo)
            .then((data) => {
                const fileName = `iscritti_${format(new Date(), "dd_MM_yyyy")}.xlsx`;
                const a = document.createElement("a");
                const objectUrl = window.URL.createObjectURL(new Blob([data.data]));

                a.href = objectUrl;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);

                getSubscriberList();
                isPressed = false;
                toast.success("Esportazione completata");
            }).catch((err) => {
            isPressed = false;
            toast.success("Esportazione completata");

            console.error(err);
        })
    }

    function handlePage() {
        page = resetPage();
    }

    $: tableSort, headerSort, lastName, firstName, referenceYear, gender, foreign, birthDateFrom, birthDateTo, residenceCity, nationality, residenceForeign, residenceCity, residenceRegion, residenceProvince, active, email, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, handlePage();

    $: page, size, lastName, firstName, referenceYear, gender, foreign, birthDateFrom, birthDateTo, residenceCity, residenceForeign, residenceRegion, residenceProvince, nationality, active, tableSort, headerSort, paymentMethod, email, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, getSubscriberList();

    onMount(async () => {
        await getPaymentLimit();
        await getSubscriberList();
    })

</script>

<Table>
    <div class="flex flex-wrap items-center justify-between w-full" slot="actions">
        <div class="relative w-full max-w-full flex-grow flex-1">
            <h3
                    class="font-semibold text-2xl light"
            >
                Iscritti
            </h3>
        </div>

        <div class="flex flex-col gap-1 lg:flex-row">
            <PrintButton
                    handleClick="{handleExport}"
                    bind:isPressed={isPressed}
            />
            <button class="border-0 bg-lightBlue-500 p-2 px-4 font-semibold text-white hover:shadow-lg w-44" on:click|preventDefault={() => navigate("create", {replace: true})}>
                Crea iscritto
            </button>
        </div>
    </div>

    <div slot="searchbar">
        {#if maxReferenceYear}
            <SubscriberSearchBar
                    bind:residenceForeign
                    bind:residenceRegion
                    bind:residenceProvince
                    bind:residenceCity
                    bind:nationality
                    bind:referenceYear
                    bind:firstName
                    bind:lastName
                    bind:birthDateFrom
                    bind:gender
                    bind:birthDateTo
                    bind:paymentMethod
                    bind:active
                    bind:headerSort
                    bind:email
                    bind:membershipCreatedFrom
                    bind:membershipCreatedTo
                    bind:paymentDateFrom
                    bind:paymentDateTo
                    maxReferenceYear={maxReferenceYear}
            />
        {/if}
    </div>

    <SubscriberHeader
            bind:sort={tableSort}
            isAdmin={isAdmin}
            slot="tableHeader"
    />

    <tbody class="h-full w-full overflow-y-auto overflow-x-scroll" slot="tableContent">
        {#if isLoading === true}
            <StretchLoader bind:colspan />
        {:else if isLoading === false && subscribers.length === 0}
            <EmptyTable bind:colspan subject="iscritti" />
        {:else if isLoading === false && subscribers.length > 0}
            {#each subscribers as subscriber}
                <SubscriberCard
                        {...subscriber}
                        on:update={handleChange}
                        isAdmin={isAdmin}
                        maximumPayment={maximumPayment}
                        minimumPaymentNotUGS="{minPaymentNotUGS}"
                        minimumPaymentUGS="{minPaymentUGS}"
                />
            {/each}
        {/if}
    </tbody>

    <TableFooter  bind:colspan bind:page bind:totalPages bind:size subject="Iscritti" bind:totalCount slot="footer"/>
</Table>
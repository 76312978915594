<script>
  // core components

  import {navigate} from "svelte-routing";
  import {login, saveTokens} from "../../services/authService";
  import { link } from "svelte-routing";

  const github = "../assets/img/github.svg";
  const google = "../assets/img/google.svg";
  export let location;
  let isUnauthorized = false;
  let isServerDown = false;

  /*Handle login*/
  async function handleLogin(data) {
    const formData = new FormData(data.target);
    resetErrors();

    const user = {};
    for (let field of formData) {
      const [key, value] = field;
      user[key] = value;
    }

    login(user).then((resp) => {
      if(resp.status === 200 && resp?.data?.accessToken) {
        saveTokens(resp.data?.accessToken, resp.data?.refreshToken);
        navigate("/admin/dashboard", { replace: true });
      }
    }).catch((err) => {
      if(err?.response?.data?.status === 401){
        isUnauthorized = true;
        return
      }
      isServerDown= true;
    })

    /*Set flags the 2 flags to false*/
    function resetErrors() {
      isServerDown = false;
      isUnauthorized = false;
    }

  }
</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
      >
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">
              Accedi
            </h6>
          </div>
          <hr class="mt-6 border-b-1 border-blueGray-300" />
        </div>
        <div class="flex-auto px-4 lg:px-10 py-2 pt-0">
          <form on:submit|preventDefault={(data) => handleLogin(data)}>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="email"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="email"
                placeholder="Email"
                required
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="password"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="password"
                placeholder="Password"
                required
              />
            </div>
<!--            <div>-->
<!--              <label class="inline-flex items-center cursor-pointer">-->
<!--                <input-->
<!--                  id="customCheckLogin"-->
<!--                  type="checkbox"-->
<!--                  class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"-->
<!--                />-->
<!--                <span class="ml-2 text-sm font-semibold text-blueGray-600">-->
<!--                  Remember me-->
<!--                </span>-->
<!--              </label>-->
<!--            </div>-->

            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
              >
                Accedi
              </button>
            </div>
            <div class="h-8">
              {#if (isServerDown)}
                <div class="w-full text-center text-red-600">
                  <small>Errore server, riprova più tardi</small>
                </div>
              {/if}
              {#if (isUnauthorized)}
                <div class="w-full text-center text-red-600">
                  <small>Credenziali errate</small>
                </div>
              {/if}
            </div>
          </form>
        </div>
      </div>
      <div class="flex flex-wrap mt-6 relative">
        <div class="w-full text-center">
          <a href="" on:click={(e) => e.preventDefault()} class="text-blueGray-200">
            <small>Password dimenticata?</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

export const regions = [
    {
        label: "Abruzzo",
        value: "Abruzzo"
    },
    {
        label: "Basilicata",
        value: "Basilicata"
    },
    {
        label: "Calabria",
        value: "Calabria"
    },
    {
        label: "Campania",
        value: "Campania"
    },
    {
        label: "Emilia-Romagna",
        value: "Emilia-Romagna"
    },
    {
        label: "Friuli-Venezia Giulia",
        value: "Friuli-Venezia Giulia"
    },
    {
        label: "Lazio",
        value: "Lazio"
    },
    {
        label: "Liguria",
        value: "Liguria"
    },
    {
        label: "Lombardia",
        value: "Lombardia"
    },
    {
        label: "Marche",
        value: "Marche"
    },
    {
        label: "Molise",
        value: "Molise"
    },
    {
        label: "Piemonte",
        value: "Piemonte"
    },
    {
        label: "Puglia",
        value: "Puglia"
    },
    {
        label: "Sardegna",
        value: "Sardegna"
    },
    {
        label: "Sicilia",
        value: "Sicilia"
    },
    {
        label: "Toscana",
        value: "Toscana"
    },
    {
        label: "Trentino-Alto Adige",
        value: "Trentino-Alto Adige"
    },
    {
        label: "Umbria",
        value: "Umbria"
    },
    {
        label: "Valle d'Aosta",
        value: "Valle d'Aosta"
    },
    {
        label: "Veneto",
        value: "Veneto"
    }
]
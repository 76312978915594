import {ACCESS_TOKEN, http, REFRESH_TOKEN} from "./http";

export function saveTokens(accessToken, refreshToken) {
    localStorage.setItem(process.env.ACCESS_TOKEN, accessToken)
    localStorage.setItem(process.env.REFRESH_TOKEN, refreshToken);
}

export function login (data)  {
    return http.post(`v1/auth/login`, {...data})
}

export function me (){
    return http.get("v1/auth/me");
}

export function removeKeys() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
}

export async function refreshAccessToken() {
    try {
        const response = await http.post("v1/auth/refresh", {
            refreshToken: "Bearer "+localStorage.getItem(REFRESH_TOKEN)
        });
        localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
        localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);
    } catch (err) {
        console.error(err);
        throw err;
    }
}

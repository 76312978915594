<script>
    import Select from "svelte-select";
    import {countries} from "../../utils/countries";
    export let value;
    export let handleChange;
    export const required = true;
    export let placeholder = "";
    export let isSearchBar= false;
    export let countryName = false;
    export let clearable = false;

    function setCityName(val) {
        countryName = val?.detail?.label;
    }

    let topConfig = {
        placement: 'top-start'
    };
</script>


<Select
        items={countries}
        id="city"
        type= "text"
        floatingConfig={topConfig}
        required={required}
        clearable={clearable}

        placeholder={placeholder}
        class={`${isSearchBar ? "" : "hover:shadow-md"} transition-all`}
        containerStyles={`
            ${isSearchBar ? "max-height: 2rem !important; min-height: 2.75rem; border-color: #D8D4D4;" : ""}
            border: 1px solid #e0e0e0;
            border-radius: 0px;
            box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);`
        }

        on:input={setCityName}
        bind:value={value}
        on:change={handleChange}

/>
<script>
    import {Route, Router} from "svelte-routing";
    import SubscriberTable from "../../components/Cards/Subscriber/SubscriberTable.svelte";
    import SubscriberForm from "../../components/Forms/Subscriber/SubscriberForm.svelte";
    export let location;
</script>



<div class="flex flex-wrap mt-4">
    <div class="w-full mb-8 px-4">
        <Router url="subscribers">
            <Route component="{SubscriberTable}" />
            <Route path="table" component="{SubscriberTable}" />
            <Route path="create" component="{SubscriberForm}" />
            <Route path="edit" component="{SubscriberForm}" />
        </Router>
    </div>
</div>

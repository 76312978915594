<script>
    import Select from "svelte-select";
    export let value = "asc";

    export let isColumnSelected = undefined;
</script>

<Select
        items={[
                    {
                        value: "asc",
                        label: "Crescente (A-Z)"
                    },
                    {
                        value: "desc",
                        label: "Decrescente (Z-A)"
                    },
                ]}
        floatingConfig={ {placement: 'top-start'}}
        type= "text"
        clearable="{false}"
        placeholder="Seleziona un ordinamento"
        disabled = {!isColumnSelected}
        containerStyles={`border: 1px solid #D8D4D4; max-height: 2rem !important; min-height: 2.75rem; border-radius: 0px`}
        bind:value={value}
/>
<script>
    import Select from "svelte-select";
    import {getCities} from "../../services/addressService";
    import {showToast} from "../../services/toastService";

    export let zips = [];
    export let value;
    export let handleChange;
    export const required = true;
    export let placeholder;
    export let cityEdit = "";
    export let provinceEdit = "";


    async function searchCityByName () {
        if(cityEdit) {
            await getCities(cityEdit).then((data) => {
                //console.log(data.data);
                getZips(data.data)
            }).catch((data) => {
                showToast("error", "Non è stato possibile caricare la lista delle città");
                console.error(data);
            });
        }
    }

    function getZips (data) {
        if (data.length === 1) {
            zips = data[0].zip.map((zip) => {return  {label: zip, value: zip}});
        } else if (data.length > 1) {
            const city = data.find((city) => city.province === provinceEdit);

            if(city) {
                zips = city.zip;
            }
        }
    }

    $: cityEdit && searchCityByName();

</script>


<Select
        items={zips}
        id="cap"
        type="text"
        placeholder={placeholder}
        on:change={handleChange}
        bind:value={value}
        required={required}
        disabled={zips?.length === 0}
        class="hover:shadow-md transition-all"
        containerStyles={`
            border: 1px solid #e0e0e0;
            border-radius: 0px;
            box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);
            max-height: 2.75rem`
        }
/>
<script>
    import { createEventDispatcher } from 'svelte';
    import { link } from "svelte-routing";
    import {deleteGroup} from "../../../services/groupService";
    import {showToast} from "../../../services/toastService";
    import DeleteButton from "../DeleteButton.svelte";

    const dispatch = createEventDispatcher()
    export let id;
    export let name;
    export let ancestorsCount;
    let element="il gruppo";

    async function handleDeleteGroup() {
        deleteGroup(id).then((resp) => {
            console.log(resp);
            if(resp.status === 200 && resp?.data?.id) {
                updateTable();
                showToast("success", "Gruppo eliminato", "top-center");
            }
        }).catch((err) => {
            if(err?.response?.status === 404) {
                showToast("error", "Gruppo non trovato", "top-center");
                return;
            }
            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                return;
            }

            showToast("error", "Errore server. Riprovi più tardi", "top-center");
        })
    }

    function getGroupType(value) {
        let type;

        switch (value) {
            case 0:
                type = "Mondo"
                break;
            case 1:
                type = "Nazionale";
                break;
            case 2:
                type = "Regionale";
                break;
            case 3:
                type = "Federazione";
                break;
            default:
                type = "Circolo";
                break;
        }

        return type;
    }

    function updateTable() {
        dispatch('delete', { state: "group deleted" });
    }
</script>


<tr class="h-12 border-b">
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {name}
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {getGroupType(ancestorsCount)}
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap text-right">
        <a use:link
           href="/admin/groups/edit?id={id}"
           class="mr-3"
        >
            <span
                    class="w-10 h-10 text-sm border inline-flex items-center justify-center rounded-full cursor-pointer"
            >
            <i class="fa fa-edit"></i>
          </span>
        </a>

        <DeleteButton bind:element={element} on:delete={() => handleDeleteGroup()} />
    </td>
</tr>


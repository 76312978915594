<script>
    import { link } from "svelte-routing";
    import { createForm } from "svelte-forms-lib";
    import {navigate} from "svelte-routing";
    import { onMount } from 'svelte';
    import {showToast} from "../../../services/toastService";
    import { imask } from 'svelte-imask';
    import {
        activateMembership,
        createSubscriberRegister, deactivateMembership, getSubByToken,
        getSubscriber,
        updateSubscriber
    } from "../../../services/subscribersService";
    import {prefixes} from "../../../utils/phonePrefix"
    import CountrySearcher from "../../SearchBars/CountrySearcher.svelte";
    import GenderSelector from "../../SearchBars/GenderSelector.svelte";
    import PrefixSearcher from "../PrefixSearcher.svelte";
    import CitySearcher from "../../SearchBars/CitySearcher.svelte";
    import CapSearcher from "../../SearchBars/CapSearcher.svelte";
    import DateSearcher from "../../SearchBars/DateSearcher.svelte";
    import UgsSelector from "./UgsSelector.svelte";
    import SubscriberFormTitle from "./SubscriberFormTitle.svelte";
    import GroupNameSearcher from "../../SearchBars/GroupNameSearcher.svelte";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../modal/Modal.svelte";
    import MembershipInfo from "./MembershipInfo.svelte";
    import {isEqual} from "date-fns";
    import {updateMembership} from "../../../services/membershipService";
    import toast from "svelte-french-toast";
    import sub from "date-fns/sub";
    import {getSettings} from "../../../services/settingService";

    const urlParams = new URLSearchParams(window.location.search);
    const pathName = window.location.pathname;
    const isBackOffice = pathName !== "/iscriviti" && pathName !== "/rinnovo";
    const isRenew = pathName === "/rinnovo";
    const subscriberId = urlParams.get('id');
    const prefixList = prefixes.countries.map((prefix) => {return {label: prefix.name, value: prefix.code }});
    const phoneMask = { mask: '000 000 0000' }
    let isRootManager;
    const isAdmin = localStorage.getItem(process.env.USER_ROLE) === "ADMIN";
    let isEdit = !!subscriberId;
    let subscriber;
    let isPressed = false;
    let zips;
    let isItaly = true;
    let groupId;
    let inputType = true;
    let subscriberBirthDate;
    let isUGSActive = false;
    let cityEdit;
    let provinceEdit;
    let membershipEdit;
    let registrationMinAge;

    const { form, handleChange, handleSubmit } = createForm({
        initialValues: {
            firstName: "",
            lastName: "",
            birthPlace: "",
            birthDate: "",
            nationality: "Italia",
            gender: "MALE",
            residenceAddress : {
                street: "",
                number: "",
                secondary: "",
                city: "",
                region: "",
                zip: "",
                province: "",
                country: "Italia",
            },
            phone: {
                code: "+39",
                number:  ""
            },
            isUGS: true,
            confirmEmail: ""
        },
        onSubmit: (formSubscriber) => {
            isPressed = true;

            if (isEdit) {
                openModal(Modal, {
                    title: `Modifica `,
                    message: `Vuoi modificare l'iscritto?`,
                    actionText: "Conferma",
                    isActionModal: true,
                    defaultText: "Annulla",
                    buttonColor: "lightBlue-400",
                    onAction: () => {
                        if(isRootManager || isAdmin) {
                            handleMembership();
                        }

                        editSubscriber(handleForm(formSubscriber))
                        closeModal()
                    }
                })
                return;
            }

            if(!!formSubscriber?.confirmEmail && formSubscriber?.confirmEmail !== formSubscriber.email) {
                toast.error("Le due e-mail non coincidono");
                isPressed = false;
                return;
            }

            addSubscriber(handleForm(formSubscriber));
        },
    });

    function handleForm (formSubscriber) {
        if(isUGSActive === false) {
            delete formSubscriber.isUGS;
        }

        if(groupId?.label) {
            groupId = groupId?.value;
        }

        if(formSubscriber.nationality?.value) {
            formSubscriber.nationality = formSubscriber.nationality?.value
        }

        if(formSubscriber.phone.code?.value) {
            formSubscriber.phone.code = formSubscriber.phone.code.value
        }

        if(formSubscriber.residenceAddress.country?.value) {
            formSubscriber.residenceAddress.country = formSubscriber.residenceAddress.country.value;
        }

        if(formSubscriber.residenceAddress.zip?.value) {
            formSubscriber.residenceAddress.zip = formSubscriber.residenceAddress.zip.value;
        }

        if(formSubscriber.residenceAddress.city?.value?.name) {
            formSubscriber.residenceAddress.city = formSubscriber.residenceAddress.city.value?.name;
        }

        if(formSubscriber.residenceAddress.city?.value) {
            formSubscriber.residenceAddress.city = formSubscriber.residenceAddress.city.value;
        }

        if(formSubscriber.residenceAddress.country !== "Italia") {
            delete formSubscriber.residenceAddress.region;
            delete formSubscriber.residenceAddress.province;
        }

        delete formSubscriber.memberships;

        return formSubscriber;
    }

    async function editSubscriber (subscriber) {
        updateSubscriber(subscriber, subscriberId).then((resp) => {
            isPressed = false;

            if(resp.status === 200 && resp?.data) {
                showToast("custom", "Iscritto modificato", "top-center", "⚒️");
                navigate("table", {replace: true})
            }
        }).catch((err) => {
            isPressed = false;
            handleError(err);
        })
    }
    async function addSubscriber(subscriber) {
        createSubscriberRegister(subscriber, groupId).then((resp) => {
            isPressed = false;
            if(resp.status === 200 && resp?.data) {
                //showToast("success", "Iscritto creato", "top-center");

                if (pathName === "/iscriviti" || isRenew) {
                    saveUserData(resp.data.id, resp.data.memberships[0].isUGS);
                    navigate(`/pagamento?id=${resp.data.memberships[0].id}`, {replace: true})
                } else {
                    showToast("success", "Iscritto creato", "top-center");
                    navigate("table", {replace: true})
                }
            }
        }).catch((err) => {
            isPressed = false;
            handleError(err);
        });
    }

    function handleError(err) {
        if(err?.response?.status === 403) {
            showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
            console.error(err);
            return;
        }
        if(err?.response?.status === 500) {
            showToast("error", "Questa mail è già presente nel sistema", "top-center");
            console.error(err);
            return;
        }
        if(err?.response?.status === 400) {
            showToast("error", "Data di nascita invalida, per iscriverti devi avere più di 14 anni", "top-center");
            console.error(err);
            return;
        }

        if(err?.response?.status === 404) {
            showToast("error", "Gruppo non trovato", "top-center");
            console.error(err);
            return;
        }

        if(err?.response?.status === 409) {
            showToast("error", "Esiste già un tesseramento con questa mail in questo anno", "top-center");
            console.error(err);
            return;
        }

        defaultError();
    }

    function setFormValue() {
        delete subscriber?.token;
        delete subscriber?.tokenExpiresAt;
        delete subscriber?.facilitatorId;
        delete subscriber?.activatorId;

        $form = subscriber;
        $form.isUGS = subscriber.memberships[0].isUGS;

        subscriberBirthDate = new Date($form.birthDate);

        if ($form.residenceAddress.country === "Italia") {
            cityEdit = $form.residenceAddress.city;
            provinceEdit = $form.residenceAddress.province;
            zips = [{value: $form.residenceAddress.zip, label: $form.residenceAddress.zip}]
        }
    }

    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }

    function handleChangeCity(value) {
        $form.residenceAddress.zip = "";
        zips = value.detail.value.zip.map((e) => {return {value: e, label: e}});
        $form.residenceAddress.zip = zips[0];

        $form.residenceAddress.region = $form.residenceAddress.city.value.region;
        $form.residenceAddress.province = $form.residenceAddress.city.value.province;
    }

    function handleStateValue(value) {
        isItaly = value.detail.value === "Italia";
        $form.residenceAddress.city = "";
        $form.residenceAddress.zip = "";
        zips = [];
    }

    function saveUserData(subscriberId, isUGS) {
        localStorage.setItem(process.env.SUBSCRIBER_ID, subscriberId);
        localStorage.setItem(process.env.IS_UGS, JSON.stringify({isUGS}));
    }

    function handleMembership() {
        console.log(subscriber.memberships[0], membershipEdit);

        if (subscriber.memberships[0].active === false && membershipEdit.active === true) {
            //attiva iscrizione
            activateMembership(subscriberId, membershipEdit.year, membershipEdit.paidAmount).then((data) => {
                if(data.status !== 200) {
                    showToast("error", "Impossibile attivare la tessera");
                }

            }).catch((err) => {
                handleError(err);
            });
        } else if(subscriber.memberships[0].active === true && membershipEdit.active === false) {
            //disattiva iscrizione
            deactivateMembership(subscriberId, membershipEdit.year).then((data) => {
                if(data.status !== 200) {
                    showToast("error", "Impossibile disattivare la tessera");
                }

            }).catch((err) => {
                console.error(err);
                showToast("error", "Errore nel disattivare la tessera", "top-center");
            });
        }

        if( !(isEqual(membershipEdit.paidAt, subscriber.memberships[0].paidAmount) && membershipEdit.paidAmount === subscriber.memberships[0].paidAmount )) {
            console.log(membershipEdit);

            updateMembership(membershipEdit.id, {...membershipEdit, paidAt : membershipEdit.paidAt.toISOString().split('T')[0]}).then((data) => {
                if(data.status !== 200) {
                    showToast("error", "Impossibile  modificare la tessera dell'iscritto");
                }
            }).catch((err) => {
                if (err?.response?.status === 403) {
                    showToast("error", "Tessera non trovata");
                } else {
                    handleError(err);
                }
            })
        }
    }

    function tokenInvalidError() {
        toast.error("Inserisci la tua mail per rinnovare l'iscrizione");
        navigate("/rinnovo/login");
    }

    function verifyToken(token) {
        getSubByToken(token).then((resp) => {
            if(resp?.status !== 200) {
                tokenInvalidError();
                return;
            }

            subscriber = resp.data;
            console.log(subscriber);

            setFormValue()
        }).catch((err) => {
            tokenInvalidError()
            console.log(err);
        })
    }

    async function getAgeLimits() {
        getSettings().then((data) => {
            registrationMinAge= data.data.registrationMinAge;
        }).catch((err) => {
            console.error(err);

            toast("Errore server, riprova più tardi");
            navigate("/", {replace: true});
        })
    }

    onMount(async () => {
        await getAgeLimits();

        if (isRenew) {
            const token = urlParams.get('token');
            if(!token){
                tokenInvalidError();
                return;
            }

            verifyToken(token);
            return;
        }

        if(isEdit) {
            isRootManager = localStorage.getItem(process.env.IS_ROOT_MANAGER) === "true";
            getSubscriber(subscriberId).then((data) => {
                if (data?.status === 200) {
                    membershipEdit = {...data.data.memberships[0]};
                    membershipEdit.paidAt = new Date(membershipEdit.paidAt);

                    delete membershipEdit.createdAt;
                    delete membershipEdit.updatedAt;
                    delete membershipEdit.activatorId;
                    delete membershipEdit.paymentMethod;
                    delete membershipEdit.prints;

                    subscriber = {...data.data};

                    groupId = {value: subscriber.memberships[0].group.id, label: subscriber.memberships[0].group.name}

                    setFormValue()
                }
            }).catch((err) => {
                if(err?.response?.data?.status === 404 || err?.response?.data?.status === 500) {
                    showToast("error", "Iscritto non trovato", "center")
                    navigate("table" , {replace: false})
                    return;
                }
                defaultError();
            });
        }
    });

</script>

<div class="h-full {isBackOffice ? 'bg-white' : ''} relative flex flex-col min-w-0 break-words w-full md:px-0 pb-2">
    {#if isBackOffice}
        <SubscriberFormTitle
            id={subscriberId}
            isEdit={isEdit}
            isBackOffice={isBackOffice}
            isRenew={isRenew}
        />
    {/if}
    <form on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-col justify-between w-full overflow-x-auto h-full px-0.5 md:px-8 mt-2">
            <div>

                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    {#if isBackOffice}
                        Nominativo
                    {:else }
                        Come ti chiami?
                    {/if}
                </label>
                <div class="w-full flex flex-col gap-6 md:flex-row my-2">
                    <!--            nominativo-->
                    <div class="w-full md:w-1/2">
                        <input
                            class="w-full pr-4 h-11 input-home focus:outline-none"
                            name="firstName"
                            id="firstName"
                            type="text"
                            placeholder="Nome"
                            bind:value={$form.firstName}
                            on:change={handleChange}
                            required
                            disabled="{((isEdit && !(isRootManager || isAdmin)) || isRenew)}"
                        >
                    </div>
                    <div class="w-full md:w-1/2">
                        <input
                                class="w-full pr-4 h-11 input-home"
                                name="lastName"
                                id="lastName"
                                placeholder="Cognome"
                                maxlength="200"
                                type="text"
                                bind:value={$form.lastName}
                                on:change={handleChange}
                                required
                                disabled="{((isEdit && !(isRootManager || isAdmin)) || isRenew)}"
                        >
                    </div>
                </div>

                <!--  Luogo di nascita  -->
                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    {#if isBackOffice}
                        Luogo di nascita
                    {:else }
                        Dove sei nat*?
                    {/if}
                </label>
                <div class="w-full my-2">
                    <input
                            class="w-full pr-4 h-11 input-home"
                            name="birthPlace"
                            id="birthPlace"
                            maxlength="200"
                            placeholder="Luogo di nascita"
                            type="text"
                            bind:value={$form.birthPlace}
                            on:change={handleChange}
                            required
                            disabled="{((isEdit && !(isRootManager || isAdmin)) || isRenew)}"
                    >
                </div>

                <!--Data di nascita -->
                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    {#if isBackOffice}
                        Data di nascita
                    {:else }
                        Quando sei nat*?
                    {/if}
                </label>
                <div class="w-full my-2">
                    <div class="h-11 w-full flex input-home">
                        <DateSearcher
                                bind:isUGSActive={isUGSActive}
                                bind:date={$form.birthDate}
                                max={sub(new Date(), {years: registrationMinAge})}
                                isNullable={false}
                                placeholderValue="Data di nascita (DD/MM/YYYY)"
                                bind:value={subscriberBirthDate}
                                disabled="{((isEdit && !(isRootManager || isAdmin)) || isRenew)}"
                        />
                    </div>
                </div>

                <!--  genere  -->
                {#if (isEdit && isRootManager) || !isEdit}
                    <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                        Genere
                    </label>
                    <div class="w-full my-2">
                        <div>
                            <GenderSelector
                                    bind:value={$form.gender}
                            />
                        </div>
                    </div>
                {/if}

                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    Nazionalità
                </label>
                <div class="w-full my-2">
                    <div class="h-11">
                        <CountrySearcher
                                handleChange={() => handleChange}
                                required={true}
                                placeholder="Nazionalità"
                                name="nationality"
                                bind:value={$form.nationality}
                        />
                    </div>
                </div>

                <!-- nazionalità e stato-->
                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    {#if isBackOffice}
                        Residenza
                    {:else }
                        Dove abiti?
                    {/if}
                </label>
                <div class="w-full my-2">
                    <div class="h-11">
                        <CountrySearcher
                                handleChange={(value) => {
                                    handleChange;
                                    handleStateValue(value);
                                }}
                                required={true}
                                placeholder="Stato"
                                name="country"
                                bind:value={$form.residenceAddress.country}
                        />
                    </div>
                </div>

                <!--Città e cap-->
                <div class="w-full flex flex-col gap-6 mb-2 md:flex-row">
                    <div class="w-full md:w-8/12">
                        {#if isItaly}
                            <div class="w-full h-11">
                                <CitySearcher
                                        bind:value={$form.residenceAddress.city}
                                        handleChange={(value) => {
                                            handleChange;
                                            handleChangeCity(value);
                                        }}
                                        required={true}
                                />
                            </div>
                        {:else}
                            <input
                                    placeholder="Nome della città"
                                    name="city"
                                    type="text"
                                    class="w-full h-11 input-home"
                                    bind:value={$form.residenceAddress.city}
                                    required
                            />
                        {/if}
                    </div>
                    <div class="w-full md:w-4/12">
                        {#if isItaly}
                            <CapSearcher
                                    placeholder={isItaly ? "Scegli il tuo CAP" : "Scegli il tuo ZIP"}
                                    bind:value={$form.residenceAddress.zip}
                                    handleChange={() => handleChange}
                                    required={true}
                                    bind:cityEdit={cityEdit}
                                    bind:provinceEdit={provinceEdit}
                                    {zips}
                            />
                        {:else }
                            <input
                                    placeholder="Zip"
                                    type="text"
                                    class="w-full h-11 input-home"
                                    name="zip"
                                    maxlength="10"
                                    bind:value={$form.residenceAddress.zip}
                                    required
                            />
                        {/if}
                    </div>
                </div>

                <!--Indirizzo -->
                <div class="flex flex-col gap-1">
                    <div class="w-full flex flex-col gap-6 mb-2 md:flex-row">
                        <div class="w-full md:w-8/12">
                            <input
                                    placeholder="Indirizzo"
                                    name="street"
                                    type="text"
                                    class="w-full h-11 input-home"
                                    bind:value={$form.residenceAddress.street}
                                    required
                            />
                        </div>
                        <div class="w-full md:w-4/12">
                            <input
                                    placeholder="Numero civico"
                                    name="number"
                                    type="text"
                                    class="w-full h-11 input-home"
                                    bind:value={$form.residenceAddress.number}
                                    required
                            />
                        </div>
                    </div>

                    <div class="flex gap-7 w-full mb-2">
                        <div class="w-full">
                            <input
                                    placeholder="Scala / Palazzo / Settore"
                                    type="text"
                                    class="w-full h-11 input-home"
                                    name="secondary"
                                    bind:value={$form.residenceAddress.secondary}
                            />
                        </div>
                    </div>
                </div>

                <!--  Email  -->
                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    Indirizzo e-mail
                </label>
                <div class="w-full my-2">
                    <input
                            placeholder="E-mail"
                            name="email"
                            type="email"
                            class="w-full h-11 input-home"
                            bind:value={$form.email}
                            required
                            disabled="{((isEdit && !(isRootManager || isAdmin)) || isRenew)}"
                    />
                </div>

                <!--  Ripeti email solo alla prima iscrizione  -->
                {#if !isBackOffice && !isRenew}
                    <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                        Ripeti indirizzo e-mail
                    </label>
                    <div class="w-full my-2">
                        <input
                                placeholder="Ripeti e-mail"
                                name="confirmEmail"
                                type="email"
                                class="w-full h-11 input-home"
                                bind:value={$form.confirmEmail}
                                required
                        />
                    </div>
                {/if}


                    <!--  Numero di telefono  -->
                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    Numero di telefono
                </label>
                <div class="w-full flex flex-col gap-1 mb-2 md:flex-row my-2">
                    <div class="w-full md:w-4/12 h-11">
                        <PrefixSearcher
                                bind:value={$form.phone.code}
                                handleChange={() => handleChange}
                                required={true}
                        />
                    </div>
                    <input
                            placeholder="Numero di telefono"
                            name="phone.number"
                            type="tel"
                            class=" w-full md:w-8/12 h-11 input-home transition-all"
                            use:imask={ phoneMask }
                            id="phone.number"
                            maxlength="15"
                            required
                            bind:value={$form.phone.number}
                    />
                </div>

                <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                    {#if isBackOffice}
                        Circolo
                    {:else }
                        Scegli, se vuoi, il circolo della tua provincia di cui far parte
                    {/if}
                </label>
                <div class="w-full h-11 my-2">
                    <GroupNameSearcher
                        bind:value={groupId}
                        bind:province={$form.residenceAddress.province}
                            handleChange={() => handleChange}
                    />
                </div>

                <!--Is ugs-->
                {#if isUGSActive}
                    <label class="block {isBackOffice ? 'font-450' : 'text-label pt-3'}">
                        {#if isBackOffice}
                            Partecipazione a UGS
                        {:else }
                            Vuoi far parte dell'Unione dei Giovani di Sinistra (UGS)?
                        {/if}
                    </label>
                    <div class="w-full flex flex-wrap">
                        <div class="h-full w-full my-2">
                            <UgsSelector bind:value={$form.isUGS}/>
                        </div>
                    </div>
                {/if}

                {#if isEdit && subscriber}
                    <MembershipInfo
                        isAdmin="{isAdmin}"
                        isRootManager="{isRootManager}"
                        subscriberId="{subscriberId}"
                        bind:membershipEdit="{membershipEdit}"
                    />
                {/if}
            </div>

            {#if !isBackOffice}
                <div class="w-full px-3 text-xl mt-4">
                    Procedendo con l'iscrizione a Sinistra Italiana accetti
                    “<a
                        use:link
                        href="/policy"
                        target="_blank"
                        class="red-si bg-none cursor-pointer"
                >
                    Termini e condizioni d’utilizzo
                </a>”.
                    Se non accetti non ti sarà possibile procedere con l'iscrizione.
                </div>
            {/if}

            <div class="flex mt-2 {isBackOffice ? ' justify-center' : ' justify-start'}">
                <button
                    disabled={isPressed}
                    type="submit"
                    class="bg-lightBlue-400 py-2 text-white transition font-bold mb-4 hover:shadow-lg {isBackOffice ? 'px-4' : 'w-52 px-8 text-xl'}"
                >
                    { isEdit ? "MODIFICA" : isBackOffice ? "SALVA": "Continua"}
                </button>
            </div>
        </div>
    </form>

</div>

<style>
    .text-label {
        font-size: 22px;
        line-height: 28px;
        color: #333333;
    }

    .pt-3 {
        padding-top: 0.75rem;
    }
</style>
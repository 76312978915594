<script>
  // core components
  import { Route, Router } from "svelte-routing";
  import SettingsForm from "../../components/Forms/Setting/SettingsForm.svelte";
  export let location;
</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-8 px-4">
    <Router url="settings">
      <Route component="{SettingsForm}" />
    </Router>
  </div>
</div>

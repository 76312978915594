<script>
    export let title;
    let isOpen= true;

    function toggle() {
        isOpen = !isOpen;
    }
</script>

<div class="w-full">
    <button class="py-4 w-full text-left flex items-center focus:outline-none text-black font-light border-t-2 border-gray-200 " on:click={toggle}>
        <i class="fa {isOpen ? 'fa-minus' : 'fa-plus'} transition-all fa-sm px-3"></i>
        <slot name="header"></slot>
    </button>
    {#if isOpen}
        <slot name="form"></slot>
    {/if}
</div>

<style>
    i {
        color: gray;
    }
</style>

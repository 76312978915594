<script>
    export let name = "";
    let timer;

    function debounceInput (v) {
        clearTimeout(timer);

        switch (v.target.name) {
            case "name":
                timer = setTimeout(() => {
                    name = v.target.value;
                }, 200);
                break;
            default:
                name = name;
                break;
        }
    }

</script>

<div class="w-full mb-2">
    <div class="text-gray-500">
        Filtra per
    </div>

    <input
            type="text"
            name="name"
            on:input={(value) => debounceInput(value)}
            placeholder="Nome del gruppo"
            class="h-11 w-full border-gray-300"
    >
</div>
<script>
    import {Stretch} from "svelte-loading-spinners";
    export let colspan = 2;
</script>

<div class="flex items-center justify-center h-full">
</div>

{#each Array(10) as tr }
    <tr class="border-b">
        <td colspan="{colspan}"></td>
    </tr>
{/each}

<style>

    tr {
        background-color: #f0f0f0;
        opacity: 0.20;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    @keyframes pulse {
        0%, 100% {
            opacity: 0.5;
        }
        50% {
            opacity: .3;
        }
    }

</style>
import {http} from "./http";
import {sortAnalyzer} from "./sortAnalyzer";

export function getUsers (page, size, sort, email, firstname, lastname) {
    const url = `v1/users?page=${page}&size=${size}${sortAnalyzer(sort)}${email ? `&email=${email}` : ""}${firstname ? `&firstname=${firstname}` : ""}${lastname ? `&lastname=${lastname}` : ""}`;
    return http.get(url);
}

export function deleteUser (id) {
    return http.delete(`v1/users/${id}`);
}

export function createUser (user) {
    return http.post(`v1/users`, {...user});
}

export function updateUser (user, id) {
    console.log(user);
    return http.patch(`v1/users/${id}`, {...user});
}

export function getUser (id) {
    return http.get(`v1/users/${id}`);
}

export function exportToXlsx() {
    return http.post(`/v1/users/export-users`, undefined, {
        responseType: "blob", 
    });
}
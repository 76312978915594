<script>
    import SubscriberNavbar from "../components/Navbars/SubscriberNavbar.svelte";
    import {link, Route, Router} from "svelte-routing";
    import RegisterLogin from "../views/formSubscriber/RegisterLogin.svelte";
    import SubscriberForm from "../components/Forms/Subscriber/SubscriberForm.svelte";
    import RegisterHome from "../views/formSubscriber/RegisterHome.svelte";
    import SubscriberPayment from "../views/formSubscriber/SubscriberPayment.svelte";
    import SubscriberPaymentThankYou from "../views/formSubscriber/SubscriberPaymentThankYou.svelte";
    import SubscriberPaymentFailure from "../views/formSubscriber/SubscriberPaymentFailure.svelte";
    import SubscriptionFAQ from "../views/formSubscriber/SubscriptionFAQ.svelte";
    import Policy from "../views/formSubscriber/Policy.svelte";
    import CollectSubscribers from "../components/Forms/Subscriber/CollectSubscribers.svelte";
    import MembershipHome from "../views/formSubscriber/MembershipHome.svelte";

    let isBackgroundVisible = false;
</script>

<div class="relative {isBackgroundVisible ? 'mainBG' : ''} h-full">
    <SubscriberNavbar />
    <main>
        <section class="w-full h-full pt-32 min-h-screen dropfilter-container">
            <Router url="subscribers">
                <Route path="" >
                    <RegisterHome  bind:isBackgroundVisible={isBackgroundVisible} />
                </Route>
                <Route path="raccolta-adesioni/login">
                    <RegisterLogin/>
                </Route>
                <Route path="iscriviti-home">
                    <MembershipHome bind:isBackgroundVisible={isBackgroundVisible} />
                </Route>
                <div class="flex justify-center w-full">
                    <div class="w-full px-2 md:px-0 md:w-10/12 lg:w-5/12">
                        <Route path="iscriviti" component="{SubscriberForm}" />
                        <Route path="rinnovo" component="{SubscriberForm}" />
                    </div>
                </div>
                <Route path="raccolta-adesioni" component={CollectSubscribers} />
                <Route path="pagamento" component={SubscriberPayment} />
                <Route path="grazie" component={SubscriberPaymentThankYou} />
                <Route path="pagamento-fallito" component={SubscriberPaymentFailure} />
                <Route path="aiuto" component={SubscriptionFAQ} />
                <Route path="policy" component={Policy} />
            </Router>
        </section>
    </main>
</div>

<style>
    @media (min-width: 1024px){
        .lg\:w-5\/12{
            width: 41%;
        }
    }
</style>
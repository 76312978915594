<script>
  // library for creating dropdown menu appear on click
  import { createPopper } from "@popperjs/core";
  import { onMount } from 'svelte';
  import {navigate} from "svelte-routing";
  import SvelteTooltip from "svelte-tooltip";
  import { link } from "svelte-routing";
  import {me, removeKeys} from "../../services/authService";
  // core components

  const image = "../assets/img/team-1-800x800.jpg";

  let dropdownPopoverShow = false;

  let btnDropdownRef;
  let popoverDropdownRef;

  let userEmail = "";

  const toggleDropdown = (event) => {
    event.preventDefault();
    if (dropdownPopoverShow) {
      dropdownPopoverShow = false;
    } else {
      dropdownPopoverShow = true;
      createPopper(btnDropdownRef, popoverDropdownRef, {
        placement: "bottom-start",
      });
    }
  };

  function handleLogout() {
      removeKeys();
      navigate("/", {replace: true})
  }

  onMount(async () => {
    me().then((resp) => {
      if(resp.status === 200 && resp?.data?.email) {
        userEmail = resp.data.email;
        return;
      }
      navigate("/", { replace: true });
    }).catch((err) => {
      if(err?.response?.data?.status === 401){
        navigate("/", { replace: true });
      }
      navigate("/", { replace: true });
    })
  });
</script>

<div>
    <div>
      <SvelteTooltip color="white" tip={userEmail} left >
        <a
                class="text-blueGray-500 block"
                href="#pablo"
                bind:this="{btnDropdownRef}"
                on:click="{toggleDropdown}"
        >
          <div class="items-center flex">
            <span
                    class="w-12 h-12 text-sm text-white border inline-flex items-center justify-center rounded-full"
            >
              <i class="fa fa-user"></i>
            </span>
          </div>
        </a>
      </SvelteTooltip>
          <div
                  bind:this="{popoverDropdownRef}"
                  class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block':'hidden'}"
          >
            <a
                    use:link
                    href="/admin/profile"
                    on:click={(e) => e.preventDefault()}
                    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Profilo
            </a>
          <hr class="my-2">
          <button
                   on:click|preventDefault={() => handleLogout()}
                  class="text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-red-600 outline-none focus:outline-none"
          >
            Logout
            </button>
        </div>
    </div>
</div>


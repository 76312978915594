import axios from 'axios';
import {refreshAccessToken} from "./authService";
const SERVER_URL = process.env.API_URL;
export const REFRESH_TOKEN = process.env.REFRESH_TOKEN;
export const ACCESS_TOKEN = process.env.ACCESS_TOKEN;

export const http = axios.create({
    baseURL: SERVER_URL,
    headers:{
        "Content-Type": "application/json"
    }
});

http.interceptors.request.use(function (config) {
    config.headers.Authorization =  localStorage.getItem(ACCESS_TOKEN) ? 'Bearer ' +  localStorage.getItem(ACCESS_TOKEN) : '';
    return config;
});
http.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return refreshAccessToken().then(() => {
                originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
                return http(originalRequest);
            });
        }

        throw error;
    }
);
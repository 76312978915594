<script>
    import {handleSortValue} from "../../utils/sortFunction";
    import EmptyHeader from "./EmptyHeader.svelte";
    let emailSortValue = undefined;
    let firstNameSortValue = undefined;
    let lastNameSortValue = undefined;
    let nationalitySortValue = undefined;
    let birthDateSortValue = undefined;
    let createdAtSortValue = undefined;
    export let isAdmin;
    export let sort = [];

    const sortNames = ["firstName", "lastName", "email", "nationality", "birthDate", "createdAt"];

    function searchSort(name) {
        const sortValue = (sort?.find((el) => el.name === name))?.sort;

        switch (name) {
            case "email" :
                emailSortValue = sortValue;
                break;
            case "lastName":
                lastNameSortValue = sortValue;
                break;
            case "firstName":
                firstNameSortValue = sortValue;
                break;
            case "nationality":
                nationalitySortValue = sortValue;
                break;
            case "birthDate":
                birthDateSortValue = sortValue;
                break;
            case "createdAt":
                createdAtSortValue = sortValue;
                break;
        }

        return sortValue;
    }

    function handleClick (name) {
        sort = handleSortValue(sort, name, searchSort(name) === undefined ? "rmv" : searchSort(name));

        if(sort) {
            sort.forEach((el) => searchSort(el.name))
        } else {
            sortNames.forEach((name) => searchSort(name))
        }
    }

</script>

<tr class="bg-white border-b border-color-input transition duration-300 ease-in-out flex">
    <EmptyHeader />
    <th on:click|preventDefault={() => handleClick("firstName")}  class="w-32 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap px-3 text-left">
        Nome {firstNameSortValue ? firstNameSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th on:click|preventDefault={() => handleClick("lastName")}  class="w-32 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Cognome {lastNameSortValue ? lastNameSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th on:click|preventDefault={() => handleClick("email")}  class="w-64 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Email {emailSortValue ? emailSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th class="w-52 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Cellulare
    </th>
    <th class="w-32 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Residenza
    </th>
    <th on:click|preventDefault={() => handleClick("createdAt")} class="w-44 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Iscrizione {createdAtSortValue ? createdAtSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th class="w-32 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Attivo
    </th>
    <th class="w-32 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Data Attiv.
    </th>
    <th class="w-16 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        UGS
    </th>
    <th class="w-16 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Quota
    </th>
</tr>
<script>
    import { fade } from 'svelte/transition'
    import {createEventDispatcher} from 'svelte';
    import {link, navigate} from "svelte-routing";
    import {showToast} from "../../../services/toastService";
    import {activateMembership, deactivateMembership} from "../../../services/subscribersService";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../modal/Modal.svelte";

    const dispatch = createEventDispatcher()
    export let id;
    export let firstName;
    export let lastName;
    export let birthDate;
    export let nationality;
    export let email;
    export let phone;
    export let residenceAddress;
    export let createdAt;
    export let active;
    export let memberships;
    export let isAdmin;
    export let maximumPayment;
    export let minimumPaymentUGS;
    export let minimumPaymentNotUGS;
    let element="l'iscritto";
    const maxCharEmail = 20;
    const maxCharName = 12;
    const maxCharResidence = 10;

    const isRootManager = localStorage.getItem(process.env.IS_ROOT_MANAGER) === "true";

    async function switchSubMembership (value) {
        if (value === true)  {
            deactivateMembership(id, memberships[0].year).then((data) => {
                updateTable("sub deactivate");
            }).catch((err) => {
                handleError(err);
            })
        } else {
            openModal(Modal, {
                isPayment: true,
                min: memberships[0].isUGS ? minimumPaymentUGS : minimumPaymentNotUGS,
                paidAmount: memberships[0]?.paidAmount,
                onAction: (paidAmount) => {
                    closeModal()
                    activateMembership(id, memberships[0].year, paidAmount).then((data) => {
                        updateTable("sub activate");
                    }).catch((err) => {
                        handleError(err);
                    })
                }
            })
        }
    }

    function handleError(err) {
        if(err?.response?.status === 404) {
            showToast("error", "Iscritto non trovato", "top-center");
            console.error(err);
            return;
        }

        if(err?.response?.status === 403 || err?.response?.status === 401) {
            showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
            console.error(err);
            return;
        }

        defaultError();
    }

    function updateTable(status) {
        dispatch("update", { state: status });
    }

    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }
</script>

<tr class="border-b flex flex-row py-1 items-center overflow-ellipsis">
    <td class="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left">
        <a use:link
           href="/admin/subscribers/edit?id={id}"
           class="mr-3"
        >
            <span
                    class="w-10 h-10 text-sm border inline-flex items-center justify-center rounded-full cursor-pointer"
            >
            <i class="fa fa-edit"></i>
          </span>
        </a>
    </td>

    <td class="w-32 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {firstName.length > maxCharName ? 'scroll-container' : ''}">
        <div class="{firstName.length > maxCharName ? 'scroll-text' : ''}">
            {firstName}
        </div>
    </td>
    <td class="w-32 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {lastName.length > maxCharName ? 'scroll-container' : ''}">
        <div class="{lastName.length > maxCharName ? 'scroll-text' : ''}">
            {lastName}
        </div>
    </td>
    <td class="w-64 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {email.length > maxCharEmail ? 'scroll-container' : ''}">
        <div class="{email.length > maxCharEmail ? 'scroll-text' : ''}">
            {email}
        </div>
    </td>
    <td class="w-52 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {phone.code} {phone.number}
    </td>
    <td class="w-32 h-full tooltipTrigger cursor-pointer font-450 truncate">
        {residenceAddress.city}
        <span class="tooltip w-52">
            <li>
                {residenceAddress.country}
            </li>
            <li>
                {residenceAddress.city}{residenceAddress.province ? `(${residenceAddress.province})` : ''}
            </li>
            <li>
                {residenceAddress.street} {residenceAddress.number}
            </li>
        </span>
    </td>
    <td class="w-44 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 ">
        {(new Date(createdAt)).toLocaleDateString('en-GB')}
    </td>
    <td class="w-32 px-0 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 select-none { (isAdmin || isRootManager)  ? 'cursor-pointer hover:shadow-md' : ''} p-1 text-left" on:click|preventDefault={(isRootManager || isAdmin) ?  switchSubMembership(memberships[0]?.active) : () => {}}>
        {memberships[0]?.active === true ? "ATTIVO" : "NON ATTIVO" }
    </td>
    <td class="w-32 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 ">
        {memberships[0]?.paidAt ? (new Date(memberships[0]?.paidAt)).toLocaleDateString('en-GB') : ""}
    </td>
    <td class="w-16 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 ">
        <div class="flex items-center px-2">
            {#if memberships[0].isUGS}
                <i class="fa fa-check fa-1x"></i>
            {:else }
                <img src="/assets/img/x-icon.svg" height="14rem" width="14rem"/>
            {/if}
        </div>
    </td>
    <td class="w-16 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {memberships[0]?.paidAmount ? `${memberships[0]?.paidAmount} €` : ""}
    </td>
</tr>
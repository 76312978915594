<script>
    import SvelteTooltip from "svelte-tooltip";
    import { createEventDispatcher } from 'svelte';
    import {link, navigate} from "svelte-routing";
    import {deleteUser} from "../../../services/userService";
    import {showToast} from "../../../services/toastService";
    import DeleteButton from "../DeleteButton.svelte";

    const maxCharEmail = 22;
    const maxCharGroup = 15;
    const maxCharName = 40;

    const dispatch = createEventDispatcher()
    export let id;
    export let firstname;
    export let lastname;
    export let email;
    export let role;
    export let group;
    let element="l'utente";

    async function handleDeleteUser() {
        deleteUser(id).then((resp) => {
            console.log(resp);
            if(resp.status === 200 && resp?.data?.id) {
                updateTable();
                showToast("success", "Utente eliminato", "top-center");
            }
        }).catch((err) => {
            if(err?.response?.status === 404) {
                showToast("error", "Utente non trovato", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                console.error(err);
                return;
            }

            defaultError();
        })
    }
    function updateTable() {
        dispatch('delete', { state: "user deleted" });
    }

    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }
</script>


<tr class="h-2 border-b">
    <td class="w-64 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {firstname?.length > maxCharName ? 'scroll-container' : ''}">
        <div class="{firstname?.length > maxCharName ? 'scroll-text' : ''}">
            {firstname ?? ""}
        </div>
    </td>
    <td class="w-64 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {lastname?.length > maxCharName ? 'scroll-container' : ''}">
        <div class="{lastname?.length > maxCharName ? 'scroll-text' : ''}">
            {lastname ?? ""}
        </div>
    </td>
    <td class="w-4/12 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {email.length > maxCharEmail ? 'scroll-container' : ''}">
        <div class="{email.length > maxCharEmail ? 'scroll-text' : ''}">
            {email}
        </div>
    </td>
    <td class="w-44 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left">
        {role === "GROUP_MANAGER" ? "AMM. DI GRUPPO" : role === "ADMIN" ? "ADMIN" : "UTENTE"}
    </td>
    <td class="w-4/12 border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap font-450 text-left {group?.name.length > maxCharGroup ? 'scroll-container' : ''}">
        <div class="{group?.name.length > maxCharGroup ? 'scroll-text' : ''}">
            {group?.name ?? ""}
        </div>
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap text-right">
        <a use:link
           href="/admin/users/edit?id={id}"
           class="mr-3"
        >
            <span
                    class="w-10 h-10 text-sm border inline-flex items-center justify-center rounded-full cursor-pointer"
            >
            <i class="fa fa-edit"></i>
          </span>
        </a>

        <DeleteButton bind:element={element} on:delete={() => handleDeleteUser()} />
    </td>
</tr>


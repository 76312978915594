<script>
    import { createForm } from "svelte-forms-lib";
    import {navigate} from "svelte-routing";
    import { onMount } from 'svelte';
    import {createUser, getUser, updateUser} from "../../../services/userService";
    import { fade } from 'svelte/transition';
    import GroupSearcher from "../../SearchBars/GroupSearcher.svelte";
    import {getGroup} from "../../../services/groupService";
    import {showToast} from "../../../services/toastService";
    import UserFormTitle from "./UserFormTitle.svelte";
    import sub from "date-fns/sub";
    import DateSearcher from "../../SearchBars/DateSearcher.svelte";

    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('id');
    let isEdit = !!userId;
    let user;
    let isPassword1 = true;
    let isPassword2 = true;
    let isPressed = false;
    let groupSelect;

    const { form, handleChange, handleSubmit } = createForm({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            secondPassword: "",
            role: "ADMIN",
            groupId: "",
        },
        onSubmit: (formUser) => {
            isPressed = true;

            if(formUser.role !== "GROUP_MANAGER") {
                delete formUser.groupId;
            } else {
                $form.groupId = $form.groupId.value
            }

            if(formUser.password !== formUser.secondPassword) {
                showToast("error", "Le due password non coincidono", "center")
                isPressed = false;
                return;
            }

            delete formUser.secondPassword;

            if (isEdit) {
                editUser(formUser)
                return;
            }
            addUser(formUser);
        },
    });

    async function editUser (user) {
        delete user.password;
        updateUser(user, userId).then((resp) => {
            isPressed = false;
            if(resp.status === 200 && resp?.data) {
                showToast("custom", "Utente modificato", "top-center", "⚒️");
                navigate("table", {replace: true})
            }
        }).catch((err) => {
            isPressed = false;
            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 404) {
                showToast("error", "Utente non trovato", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 500 && err?.name === "QueryFailedError") {
                showToast("error", "Questa mail è stata già modificata", "top-center");
                console.error(err);
                return;
            }
            defaultError();
        })
    }
    async function addUser(user) {
        createUser(user).then((resp) => {
            isPressed = false;
            if(resp.status === 200 && resp?.data) {
                showToast("success", "Utente creato", "top-center");
                navigate("table", {replace: true})
            }
        }).catch((err) => {
            isPressed = false;
            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 500) {
                showToast("error", "Questa mail è stata già utilizzata", "top-center");
                console.error(err);
                return;
            }
            defaultError();
        })
    }
    function toggleFirstPassword() {
        isPassword1 = !isPassword1;

        document.getElementById("p1").type = isPassword1 ? "password" : "text"
    }
    function toggleSecondPassword() {
        isPassword2 = !isPassword2;

        document.getElementById("p2").type = isPassword2 ? "password" : "text"
    }
    function setFormValue() {
        $form.role = user.role;
        $form.email = user.email;
    }

    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }

    async function setGroup(groupId) {
        getGroup(groupId).then((data) => {
            $form.groupId = {label: data.data.name, value: data.data.id};
        }).catch((err) => {
            showToast("error", "Utente non trovato", "center")
            console.error(err);
            navigate("table" , {replace: false})
        })
    }

    onMount(async () => {
        if(isEdit) {
            getUser(userId).then((data) => {
                if (data?.status === 200) {
                    user = data.data
                    setFormValue()
                    if( user.role === "GROUP_MANAGER" && user?.groupId ) {
                         setGroup(user.groupId);
                    }
                }
            }).catch((err) => {
                if(err?.response?.data?.status === 404 || err?.response?.data?.status === 500) {
                    showToast("error", "Utente non trovato", "center")
                    navigate("table" , {replace: false})
                    return;
                }
                defaultError();
            });
        }
    });
</script>

<div class="h-full relative flex flex-col min-w-0 break-words w-full mb-12 shadow-lg bg-white">
    <UserFormTitle
            id={userId}
            isEdit={isEdit}
    />
    <form on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-col justify-between w-full px-8 h-full mt-2">
            <div class="w-full flex flex-col gap-3 mb-2 md:flex-row">
                <div class="w-full md:w-1/2 ">
                    <label class="block font-450">
                        Nome
                    </label>
                    <input
                            class="w-full h-11 input-home"
                            name="firstname"
                            id="firstname"
                            maxlength="100"
                            placeholder="Nome dell'utente"
                            type="text"
                            bind:value={$form.firstname}
                            on:change={handleChange}
                    >
                </div>

                <div class="w-full md:w-1/2">
                    <label class="block font-450">
                        Cognome
                    </label>
                    <input
                            class="w-full h-11 input-home"
                            name="lastname"
                            id="lastname"
                            maxlength="100"
                            placeholder="Congnome dell'utente"
                            type="text"
                            bind:value={$form.lastname}
                            on:change={handleChange}
                    >
                </div>
            </div>

            <div class="w-full flex flex-wrap mb-2">
                <div class="w-full">
                    <label class="block font-450">
                        Email
                    </label>
                    <input
                            class="w-full pr-4 h-11 input-home"
                            name="email"
                            id="email"
                            maxlength="100"
                            placeholder="Email"
                            type="text"
                            bind:value={$form.email}
                            on:change={handleChange}
                            required
                    >
                </div>
            </div>

            <div class="w-full flex flex-col gap-3 mb-2 md:flex-row">
                {#if !isEdit}
                    <div class="w-full md:w-1/2 relative">
                        <label class="font-450">Password</label>
                        <input
                                class="w-full h-11 input-home"
                                name="firstPassword"
                                id="p1"
                                maxlength="200"
                                type= "password"
                                minlength="8"
                                bind:value={$form.password}
                                on:change={handleChange}
                                required
                        >
                        <i class="fa {isPassword1 ? 'fa-eye' : 'fa-eye-slash'} absolute icon-position cursor-pointer" on:click|preventDefault={() => toggleFirstPassword()}></i>
                    </div>
                    <div class="w-full md:w-1/2 relative">
                        <label class="font-450">Ripeti password</label>
                        <input
                                class="w-full h-11 input-home"
                                name="secondPassword"
                                id="p2"
                                maxlength="200"
                                type="password"
                                minlength="8"
                                bind:value={$form.secondPassword}
                                on:change={handleChange}
                                required
                        >
                        <i class="fa {isPassword2 ? 'fa-eye' : 'fa-eye-slash'} absolute icon-position cursor-pointer" on:click|preventDefault={() => toggleSecondPassword()}></i>
                    </div>
                {/if}
            </div>

            <div class="w-full flex flex-wrap mb-2">
                <div class="w-full">
                    <label class="block font-450">
                        Ruolo
                    </label>
                    <select
                            class="w-full pr-4 h-11 input-home"
                            name="role"
                            bind:value={$form.role}
                            on:blur={handleChange}
                            required
                    >
                        <option value="ADMIN" selected>Admin</option>
                        <option value="GROUP_MANAGER">Amministratore di gruppo</option>
                        <option value="SUBSCRIBER">Iscritto</option>
                    </select>
                </div>
            </div>

            <div class="w-full flex flex-wrap mb-2">
                {#if $form.role === "GROUP_MANAGER"}
                    <div class="w-full md:px-3">
                        <label class="block font-450">
                            Gruppo
                        </label>
                        <GroupSearcher
                                bind:value={$form.groupId}
                                handleChange={() => handleChange}
                                required={true}
                        />
                    </div>
                {/if}
            </div>

            <div class="flex justify-center">
                <button disabled={isPressed} type="submit" class="bg-lightBlue-400 py-2 px-4 text-white transition hover:shadow-lg my-2">
                    {isEdit ? "MODIFICA": "SALVA"}
                </button>
            </div>
        </div>
    </form>
</div>

<style>
    .icon-position {
        top: 38px;
        right: 20px;
    }
</style>
<script>
    // core components
    import {Route, Router} from "svelte-routing";
    import GroupTable from "../../components/Cards/Group/GroupTable.svelte";
    import GroupForm from "../../components/Forms/Group/GroupForm.svelte";
    export let location;
</script>



<div class="flex flex-wrap mt-4">
    <div class="w-full mb-8 px-4">
        <Router url="groups">
            <Route component="{GroupTable}" />
            <Route path="table" component="{GroupTable}" />
            <Route path="create" component="{GroupForm}" />
            <Route path="edit" component="{GroupForm}" />
        </Router>
    </div>
</div>

export function handleSortValue (sort, name, sortValue) {

    switch (sortValue) {
        case "rmv" :
            sortValue = "asc"
            break;
        case "asc" :
            sortValue = "desc"
            break;
        case "desc" :
            sortValue = "rmv"
            break;
    }

    return handleSort(sort, name, sortValue);
}

function handleSort(sort, name, sortValue) {
    sort = removeSort(sort,name);
    if (sortValue !== "rmv") {
        return addSort(sort, name, sortValue);
    }
}

function removeSort(sort, name) {
     return sort?.filter((el) => el.name !== name);
}

function addSort(sort ,name, sortValue) {
    if (sort)
        return [...sort, {name, sort: sortValue}];
    else
        return [{name, sort: sortValue}];
}
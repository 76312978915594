<script>
    export let foreign;

    function handleCountry(value) {
        foreign === value ? foreign = null : foreign = value;
    }
</script>

<div class="flex gap-1 h-11">
    <div class="flex justify-center items-center border border-gray-300 w-1/2">
        <input
                id="radio-country-1"
                type="radio"
                name="foreign"
                value="false"
                bind:group={foreign}
                on:click={(data) => handleCountry(data.target.value)}
                class="cursor-pointer w-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        >
        <label for="radio-country-1" class="ml-2 ">Italia</label>
    </div>
    <div class="flex items-center justify-center border border-gray-300 w-1/2">
        <input
                id="radio-country-2"
                type="radio"
                name="foreign"
                value="true"
                bind:group={foreign}
                on:click={(data) => handleCountry(data.target.value)}
                class="cursor-pointer w-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"

        >
        <label for="radio-country-1" class="ml-2">Estero</label>
    </div>
</div>
import {http} from "./http";
import {sortAnalyzer} from "./sortAnalyzer";

function insert(obj, name) {
    return { ...obj ? { [name]: obj } : {} };
}

export function getSubscribers(page, size, sort, firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted, active) {
    referenceYear = referenceYear || (new Date).getFullYear();

    if(paymentMethod !== undefined && paymentMethod !== null) {
        paymentMethod = paymentMethod === "true" ? "BANK" : "STRIPE";
    }

    if(referenceYear?.value) {
        referenceYear = referenceYear.value;
    }

    //http://localhost:5000/v1/subscribers?page=1&size=10&referenceYear=2020&firstName=AA&lastName=AA
    //http://localhost:5000/v1/subscribers?page=1&size=10&referenceYear=2020&firstName=asc&lastName=sdvs&birthPlace=sdvsdv&gender=MALE&residenceCity=asc&birthDateFrom=2000-05-05&foreign=true
    //const url = `v1/subscribers?page=${page}&size=${size}${firstName ? `&firstName=${firstName}` : ""}${lastName ? `&lastName=${lastName}` : ""}${referenceYear ? `&referenceYear=${referenceYear}` : ""}${gender ? `&gender=${gender}` : ""}${foreign ? `&foreign=${foreign}` : ""}${birthDateFrom ? `&birthDateFrom=${birthDateFrom}` : ""}${birthDateTo ? `&birthDateTo=${birthDateTo}` : ""}${residenceCity ? `&residenceCity=${residenceCity}` : ""}${nationality ? `&nationality=${nationality}` : ""}${sortAnalyzer(sort)}`;
    const urlParams = new URLSearchParams({
        page,
        size,
        ...insert(firstName, "firstName"),
        ...insert(lastName, "lastName"),
        ...insert(email, "email"),
        ...insert(referenceYear, "referenceYear"),
        ...insert(gender, "gender"),
        ...insert(foreign, "foreign"),
        ...insert(birthDateFrom, "birthDateFrom"),
        ...insert(birthDateTo, "birthDateTo"),
        ...insert(nationality, "nationality"),
        ...insert(residenceForeign, "residenceForeign"),
        ...insert(residenceRegion, "residenceRegion"),
        ...insert(residenceProvince, "residenceProvince"),
        ...insert(residenceCity, "residenceCity"),
        ...insert(paymentMethod, "paymentMethod"),
        ...insert(membershipCreatedFrom, "membershipCreatedFrom"),
        ...insert(membershipCreatedTo, "membershipCreatedTo"),
        ...insert(paymentDateFrom, "paymentDateFrom"),
        ...insert(paymentDateTo, "paymentDateTo"),
        ...insert(wasPrinted, "wasPrinted"),
        ...insert(active, "active")
    });

    return http.get(`v1/subscribers?${urlParams}${sortAnalyzer(sort)}`);
}

export function deleteSubscriber(id) {
    return http.delete(`v1/subscribers/${id}`);
}

export function createSubscriber(subscriber) {
    return http.post(`v1/subscribers`, {...subscriber});
}

export function createSubscriberRegister(subscriber, groupId) {
    return http.post(`v1/subscribers/register${groupId ? `?groupId=${groupId}` : ""}`, {...subscriber});
}

export function updateSubscriber(subscriber, id) {
    return http.patch(`v1/subscribers/${id}`, {...subscriber});
}

export function getSubscriber(id) {
    return http.get(`v1/subscribers/${id}`);
}

export function activateMembership(subId, year, paidAmount) {
    return http.post(`v1/subscribers/${subId}/memberships/${year}/activate${paidAmount ? `?paidAmount=${paidAmount}` : ""}`)
}
export function deactivateMembership(subId, year) {
    return http.post(`v1/subscribers/${subId}/memberships/${year}/deactivate`);
}

export function sendToken(email, template) {
    return http.post(`v1/subscribers/token?email=${email}&template=${template}`);
}

export function getSubByToken(token) {
    return http.get(`v1/subscribers/token/${token}`);
}

export function subscribeMultple(token, subscribers) {
    return http.post(`/v1/subscribers/register/multiple?token=${token}`, subscribers);
}

export function exportMembershipsToXLSX(membershipIds, notes, firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted) {
    referenceYear = referenceYear || (new Date).getFullYear();

    paymentMethod = handlePaymentValue(paymentMethod);

    if(referenceYear?.value) {
        referenceYear = referenceYear.value;
    }

    const urlParams = createURLParams (firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted);

    return http.post(`/v1/subscribers/export-membership?${urlParams}`, {membershipIds, notes }, {
        responseType: "blob",
    });
}


export function exportSubscribersToXLSX(firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo) {
    referenceYear = referenceYear || (new Date).getFullYear();

    paymentMethod = handlePaymentValue(paymentMethod);

    if(referenceYear?.value) {
        referenceYear = referenceYear.value;
    }

    const urlParams = createURLParams (firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo);

    return http.post(`/v1/subscribers/export-subscribers?${urlParams}`, {}, {
        responseType: "blob",
    });
}

function createURLParams (firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted) {
    return new URLSearchParams({
        ...insert(firstName, "firstName"),
        ...insert(lastName, "lastName"),
        ...insert(email, "email"),
        ...insert(referenceYear, "referenceYear"),
        ...insert(gender, "gender"),
        ...insert(foreign, "foreign"),
        ...insert(birthDateFrom, "birthDateFrom"),
        ...insert(birthDateTo, "birthDateTo"),
        ...insert(nationality, "nationality"),
        ...insert(residenceForeign, "residenceForeign"),
        ...insert(residenceRegion, "residenceRegion"),
        ...insert(residenceProvince, "residenceProvince"),
        ...insert(residenceCity, "residenceCity"),
        ...insert(paymentMethod, "paymentMethod"),
        ...insert(membershipCreatedFrom, "membershipCreatedFrom"),
        ...insert(membershipCreatedTo, "membershipCreatedTo"),
        ...insert(paymentDateFrom, "paymentDateFrom"),
        ...insert(paymentDateTo, "paymentDateTo"),
        ...insert(wasPrinted, "wasPrinted"),
    });
}

function handlePaymentValue (pv) {
    if(pv !== undefined && pv !== null) {
       return pv === "true" ? "BANK" : "STRIPE";
    } else {
        return undefined;
    }
}


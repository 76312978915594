<script>
    import {onMount} from "svelte";
    import {getSettings} from "../../services/settingService";
    import toast from "svelte-french-toast";
    import {navigate} from "svelte-routing";
    import {Moon} from "svelte-loading-spinners";
    import {createPaymentIntent} from "../../services/paymentService";
    import {Elements, PaymentElement} from "svelte-stripe";
    import {loadStripe} from "@stripe/stripe-js";
    import { link } from "svelte-routing";

    const urlParams = new URLSearchParams(window.location.search);
    let membershipId = urlParams.get("id");

    const stripePublicKey = process.env.STRIPE_PUBLIC_KEY;

    let maximumPayment;
    let minPayment;
    let amount;
    let elements;
    let payment = undefined;
    let ugsMaxAgeInclusive;
    let isUGS = undefined;

    let gotoPayment = false;
    let processingPayment = false;
    let isTransfer = false;
    let paymentError;

    async function getPaymentLimit() {
        getSettings().then((data) => {
            ugsMaxAgeInclusive = data.data.ugsMaxAgeInclusive;

            maximumPayment = data.data.maximumPayment;
            minPayment = isUGS ?  data.data.minimumPaymentUGS : data.data.minimumPaymentNotUGS;
            payment = minPayment;

            amount = minPayment;
        }).catch((err) => {
            console.error(err);
            toast("Errore server, riprovare più tardi");
            navigate("/", {replace: true});
        })
    }

    async function createPayment() {
        const { clientSecret } = (await createPaymentIntent(amount, membershipId)).data;
        return clientSecret;
    }

    function handleInput(value) {
        if  (!value || value < minPayment) {
            amount = minPayment
            return;
        }

        if  (value > maximumPayment) {
            amount = maximumPayment
            return;
        }

        if (value < minPayment) {
            amount = minPayment;
            return;
        }

        amount = value;
    }

    onMount(async () => {
        isUGS = JSON.parse(localStorage.getItem(process.env.IS_UGS)).isUGS;

        if (isUGS !== undefined || isUGS != null) {
            await getPaymentLimit();
        } else {
            toast("Completa l'iscrizione prima di accedere a questa pagina");
            navigate("/", {replace: true});
        }
    });

    async function submitPayment(stripe) {
        if (processingPayment) {
            return;
        }

        processingPayment = true;
        const result = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
        });

        if (!result.error) {
            navigate("/grazie", { replace: true });
        } else {
            paymentError = result.error;
            console.error(paymentError);
            navigate(`/pagamento-fallito?id=${membershipId}`, {replace: true})
            toast.error("Pagamento rifiutato, ti invitiamo a riprovare.");
        }

        processingPayment = false;
    }
</script>

{#if membershipId}
    <div class="container mx-auto w-full md:bg-none md:p-4">
        <div class="flex flex-col content-center items-center select-none h-full w-full">
            <div class="title-red font-bold w-full text-center">
                Pagamento quota di adesione
            </div>

            {#if !gotoPayment && !isTransfer}
                <div class="w-8/12 flex flex-col gap-7 mt-2">
                    <div class="text-3xl font-450 text-center">
                        Quanto vuoi versare per la tua quota di adesione?
                    </div>
                    <div class="text-center text-2xl">
                        Puoi aumentare la quota minima di iscrizione di {minPayment}€, contribuirai così a finanziare le iniziative politiche che decideremo insieme di mettere in campo.
                    </div>
                </div>

                <div class="flex flex-col mt-20">
                    <div class="h-24 w-80 text-2xl number text-center text-blueGray-800 flex justify-center items-center">
                        {#if payment === undefined}
                            <Moon size="40" color="#ef3f3e" unit="px" duration="0.5s" />
                        {:else}
                            <input
                                    id="payment"
                                    type="number"
                                    on:input|preventDefault={(value) => handleInput(value?.target?.value)}
                                    value={amount}
                                    step="1"
                                    style="font-size: 6rem"
                                    class="border-none text-center inputPayment w-full bg-none select-none"
                            >
                            <div class="text-4xl">€</div>
                        {/if}
                    </div>
                    <div class="w-80 h-24 flex justify-between items-center gap-7">
                        <div class="w-24 text-center text-xl">
                            {#if amount === undefined}
                                <Moon size="10" color="#ef3f3e" unit="px" duration="0.5s" />
                            {:else}
                                <div class="text-2xl">
                                    {minPayment}
                                </div>
                            {/if}
                        </div>
                        <div class="w-80">
                            <input
                                    id="default-range"
                                    type="range"
                                    bind:value={amount}
                                    min={minPayment}
                                    max={maximumPayment}
                                    step="1"
                                    class="w-full h-2 range-lg bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            >
                        </div>
                        <div class="w-24 text-center text-xl">
                            {#if !amount}
                                <Moon size="10" color="#ef3f3e" unit="px" duration="0.5s" />
                            {:else}
                                <div class="text-2xl">
                                    {maximumPayment}
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>

                <div class="w-6/12 text-xl mt-4">
                    Procedendo con l'iscrizione a Sinistra Italiana accetti
                    “<a
                        use:link
                        href="/policy"
                        target="_blank"
                        class="red-si bg-none cursor-pointer"
                    >
                        Termini e condizioni d’utilizzo
                    </a>”.
                    Se non accetti non ti sarà possibile procedere con l'iscrizione.
                </div>

                <div class="flex flex-col gap-7 mb-2 md:flex-row">
                    <button on:click={() => gotoPayment = true} class="mt-10 w-64 h-16 text-lg font-450 bg-lightBlue-500 flex items-center justify-center text-white rounded shadow-md cursor-pointer font-bold transition-all hover:shadow-lg">
                        Pagamento online
                    </button>

                    <button on:click={() => isTransfer = true} class="mt-10 w-64 h-16 text-lg font-450 bg-lightBlue-500 flex items-center justify-center text-white rounded shadow-md cursor-pointer font-bold transition-all hover:shadow-lg">
                        Pagamento con bonifico
                    </button>
                </div>
            {/if}

            {#if gotoPayment}
                <div class="w-8/12 flex flex-col gap-7 mt-2">
                    <div class="text-3xl font-450 text-center">
                        Seleziona un metodo di pagamento e inserisci i tuoi dati per completare l'iscrizione
                    </div>
                </div>

                <div class="flex flex-col items-center justify-center mt-10 mb-12">
                    {#await loadStripe(stripePublicKey) then stripe}
                        {#await createPayment() then clientSecret}
                            <form on:submit|preventDefault={() => submitPayment(stripe)}>
                                <Elements {stripe} {clientSecret} bind:elements>
                                    <!-- This area is the only place where Stripe components can go -->
                                    <PaymentElement/>
                                </Elements>

                                <button disabled={processingPayment} class="mt-10 w-52 h-16 text-lg font-450 bg-lightBlue-500 flex items-center justify-center text-white rounded shadow-md cursor-pointer">
                                    {processingPayment ? "Pagamento in corso..." : "Paga"}
                                </button>
                            </form>
                        {/await}
                    {/await}
                </div>
            {/if}

            {#if isTransfer}
                <div class="w-8/12 flex flex-col gap-7 mt-4">
                    <div class="text-3xl font-450 text-center">
                        Per completare il pagamento con bonifico utilizza queste informaizoni
                    </div>
                    <div class="p-4 h-full w-full border border-gray-200 shadow-md break-words">
                        <div class="text-2xl">
                            Indica nella causale di pagamento nome e cognome seguiti dal tuo codice utente
                        </div>
                        <div class="text-2xl">
                            <span class="font-bold">- BENEFICIARIO:</span> Sinistra Italiana - BANCO BPM - Fil. di Piazza di Montecitorio, Roma
                        </div>
                        <div class="text-2xl">
                            <span class="font-bold">- IBAN:</span> IT70F0503403264000000002286
                        </div>
                        <div class="text-2xl">
                            <span class="font-bold">- SWIFT:</span> BAPPIT21P23
                        </div>
                    </div>
                </div>
            {/if}
        </div>
    </div>
{:else}
    <div class="container mx-auto w-full md:bg-none">
        <div class="flex flex-col content-center items-center select-none h-full w-full">
            <div class="text-3xl font-bold w-full text-center">
                ID del sottoscrittore mancante, non posso procedere al pagamento.
            </div>
        </div>
    </div>
{/if}

<style>
    input[type="number"]
    {
        background: transparent;
        border: none;
        outline: none;
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
</style>
<script>
    import {Moon} from "svelte-loading-spinners";
    import {createForm} from "svelte-forms-lib";
    import {sendToken} from "../../../services/subscribersService";
    import toast from "svelte-french-toast";
    import {navigate} from "svelte-routing";
    import {showToast} from "../../../services/toastService";

    let isPressed = false;

    const { form, handleChange, handleSubmit } = createForm({
        initialValues: {
            email: ""
        },
        onSubmit: (formSubscriber) => {
            isPressed = true;
            sendTokenRequest(formSubscriber.email);
        },
    });

    function sendTokenRequest(email) {
        sendToken(email , "access_registration_multi").then((resp) => {
            if(resp?.status === 200) {
                toast.success(
                    "La mail con il link per registrare altri iscritti è stata mandata alla sua casella postale.", {duration: 8000} );
                navigate("/", {replace: true});
                return;
            }
            showToast("error", "Non è stato possibile mandare la mail, riprovi più tardi");
            isPressed = false;
        }).catch((err) => {
            showToast("error", "Non è stato possibile mandare la mail, riprovi più tardi");
            console.error(err);
            isPressed = false;
        })
    }
</script>


<div class="text-xl w-full mt-6">
    <form on:submit|preventDefault={handleSubmit}>
        <label>
            Inserisci la tua email
        </label>

        <input
                type="email"
                class="w-full rounded input-home px-2 mt-2"
                bind:value={$form.email}
                placeholder="Inserisci la tua mail"
                required
        >

        <div class="my-2 w-full flex items-start mt-12">
            <button type="submit" class="w-64 h-10 bg-lb cursor-pointer py-1 px-8 first-button text-center transform-gpu section-title rounded flex justify-center items-center">
                {#if isPressed}
                    <Moon size="20" color="white" />
                {:else }
                    Inserisci la mail
                {/if}
            </button>
        </div>
    </form>
</div>

<script>
    import Select from "svelte-select";
    import {regions} from "../../utils/regions";
    import {provinces} from "../../utils/provinces";

    let value;
    export let subject;
    export const required = true;
    export let placeholder = "";
    export let clearable = false;
    export let valueName;

    let topConfig = {
        placement: 'top-start'
    };

    function setName(val) {
        valueName = val?.detail?.value;
        console.log(valueName);
    }

</script>


<Select
        items={subject === "region" ? regions : provinces}
        id="area"
        type= "text"
        floatingConfig={topConfig}
        required={required}
        clearable={clearable}

        placeholder={placeholder}
        containerStyles={`border: 1px solid rgba(212, 212, 216, 1); border-radius: 0;`}
        inputStyles={`z-index: 100`}
        bind:value={value}
        on:input={setName}
/>
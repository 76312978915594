<script>

    import Table from "../Table.svelte";
    import {onMount} from "svelte";
    import TableFooter from "../TableFooter.svelte";
    import EmptyTable from "../EmptyTable.svelte";
    import StretchLoader from "../../Loaders/StretchLoader.svelte";
    import { exportMembershipsToXLSX, getSubscribers} from "../../../services/subscribersService";
    import {showToast} from "../../../services/toastService";
    import {navigate} from "svelte-routing";
    import PrintHeader from "../../Headers/PrintHeader.svelte";
    import PrintCard from "./PrintCard.svelte";
    import SubscriberSearchBar from "../../SearchBars/SubscriberSearchBar.svelte";
    import {getSettings} from "../../../services/settingService";
    import toast from "svelte-french-toast";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../modal/Modal.svelte";
    import PrintButton from "../../Button/PrintButton.svelte";
    import {format} from "date-fns";

    let page = 1;
    let size = 10
    let firstName, lastName, email, gender, birthDateFrom, birthDateTo, foreign, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo = undefined;;
    let wasPrinted = "false";
    let headerSort = undefined;
    let sort=[];
    let totalPages = 10;
    let subscribers = [];
    let totalCount;
    let referenceYear;
    let isLoading;
    let colspan = 10;
    let maxReferenceYear;
    let membershipPrintIds =[];
    let selectAll;
    let isPressed = false;

    async function getSubscriberList () {
        isLoading = true;

        headerSort ? sort.push(headerSort) : null;

        getSubscribers (page, size, sort, firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted, true).then((resp) => {

            if(resp.status === 200 && resp?.data) {
                subscribers = resp.data.data;
                totalPages = Math.ceil(resp.data?.totalCount / size);
                totalCount = resp.data.totalCount;

                membershipPrintIds = [];
            }
            isLoading = false;
        }).catch((err) => {
            isLoading = false;
            console.log(err);

            if(err?.response?.data?.status === 403){
                showToast("error", "Non hai i permessi per compiere questa azione");
                navigate("/", {replace: true});
            }

            if(err?.response?.data?.status === 500){
                showToast("error", "Errore durante la ricerca, riprovare");
                return;
            }

            showToast("error", "Errore server");
            navigate("/", {replace: true});
        })
    }

    async function getPaymentLimit() {
        getSettings().then((data) => {
            maxReferenceYear = data.data.subscriptionActiveYear;
        }).catch((err) => {
            console.error(err);
            toast("Errore server, riprovare più tardi");
            navigate("/", {replace: true});
        })
    }

    function handleSelectAll(value) {
        const membershipIds = subscribers.map((sub) => sub.memberships[0].id);

        if(value) {
            membershipPrintIds = [...new Set([...membershipPrintIds, ...membershipIds])];
        } else {
            membershipPrintIds = [...membershipPrintIds.filter((m) =>  !membershipIds.includes(m))];
        }
    }

    function handlePrints() {
        if(membershipPrintIds.length === 0) {
            toast.error("Seleziona una tessera prima di procedere alla stampa.");
            return;
        }

        openModal(Modal, {
            title: `Stampa`,
            message: `Vuoi aggiungere una nota alla stampa (facoltativa)?`,
            actionText: "Stampa",
            isActionModal: true,
            defaultText: "Annulla",
            buttonColor: "lightBlue-400",
            isCustom: true,
            onAction: (notes) => {
                handleExport(notes);
                closeModal()
            }
        })

    }

    function handleSelectAllValue() {
        const membershipIds = subscribers.map((sub) => sub.memberships[0].id);

        if(membershipIds.length === 0) {
            selectAll = false;
            return;
        }

        selectAll = membershipIds.every((m) => membershipPrintIds.includes(m));
    }

    async function handleExport(notes) {
        isPressed = true;

        exportMembershipsToXLSX(selectAll ? [] : membershipPrintIds, notes, firstName, lastName, email, referenceYear, gender, foreign, birthDateFrom, birthDateTo, nationality, residenceForeign, residenceRegion, residenceProvince, residenceCity, paymentMethod, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted)
            .then((data) => {
                const fileName = `iscrizioni_${format(new Date(), "dd_MM_yyyy")}.xlsx`;
                const a = document.createElement("a");
                const objectUrl = window.URL.createObjectURL(new Blob([data.data]));

                a.href = objectUrl;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);

                getSubscriberList();
                isPressed = false;
                toast.success("Esportazione completata");
            }).catch((err) => {
                isPressed = false;
                toast.success("Esportazione completata");
                
                console.error(err);
            })
    }

    $: page, size, lastName, firstName, referenceYear, gender, foreign, birthDateFrom, birthDateTo, residenceCity, residenceForeign, residenceRegion, residenceProvince, nationality, headerSort, paymentMethod, email, membershipCreatedFrom, membershipCreatedTo, paymentDateFrom, paymentDateTo, wasPrinted, getSubscriberList();

    $: membershipPrintIds, handleSelectAllValue();

    onMount(async () => {
        await getSubscriberList();
        await getPaymentLimit();
    })
</script>


<Table>
    <div class="flex flex-wrap items-center justify-between w-full" slot="actions">
        <div class="relative w-full max-w-full flex-grow flex-1">
            <h3
                    class="font-semibold text-2xl light"
            >
                Stampa iscrizioni
            </h3>
        </div>

        <PrintButton
            handleClick="{handlePrints}"
            bind:isPressed={isPressed}
            isPrints="{true}"
        />
    </div>

    <div slot="searchbar">
        {#if maxReferenceYear}
            <SubscriberSearchBar
                    bind:residenceForeign
                    bind:residenceRegion
                    bind:residenceProvince
                    bind:residenceCity
                    bind:nationality
                    bind:referenceYear
                    bind:firstName
                    bind:lastName
                    bind:birthDateFrom
                    bind:gender
                    bind:birthDateTo
                    bind:paymentMethod
                    bind:headerSort
                    bind:email
                    bind:membershipCreatedFrom
                    bind:membershipCreatedTo
                    bind:paymentDateFrom
                    bind:paymentDateTo
                    bind:wasPrinted
                    isPrints="{true}"
                    maxReferenceYear="{maxReferenceYear}"
            />
        {/if}
    </div>

    <div slot="extra" class="font-450 my-2 h-10 flex items-center justify-between">
        <div class="flex items-center gap-3">
            Seleziona tutti
            <input
                type="checkbox"
                class="cursor-pointer"
                bind:checked={selectAll}
                on:input={(v) => {handleSelectAll(v.target.checked)}}
            >
        </div>

        <div class="class flex gap-3">
            <div>Tessere selezionate: </div>
            <div class="w-5 text-right">
                {membershipPrintIds.length}
            </div>
        </div>
    </div>

    <PrintHeader slot="tableHeader"/>

    <tbody class="h-full overflow-y-auto pt-6 no-scroll mx-4" slot="tableContent">
        {#if isLoading === true}
            <StretchLoader bind:colspan />
        {:else if isLoading === false && subscribers.length === 0}
            <EmptyTable bind:colspan subject="iscritti" />
        {:else if isLoading === false}
            {#each subscribers as subscriber}
                <PrintCard
                    {...subscriber}
                    bind:membershipPrintIds="{membershipPrintIds}"
                />
            {/each}
        {/if}

    </tbody>

    <TableFooter bind:colspan bind:page bind:totalPages bind:size bind:totalCount subject="Iscrizioni" slot="footer"/>
</Table>
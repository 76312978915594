<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  import Admin from "./layouts/Admin.svelte";
  import Auth from "./layouts/Auth.svelte";
  import Profile from "./views/Profile.svelte";
  import {Toaster} from "svelte-french-toast";
  import SubscriberHome from "./layouts/SubscriberHome.svelte";
  export let url = "";
</script>

<Router url="{url}">
  <Toaster
          containerStyle="left: 42%;"
  />
  <Route path="auth/*auth" component="{Auth}" />
  <Route path="admin/*admin" component="{Admin}" />
  <Route path="profile" component="{Profile}" />
  <Route path="subscribers/*subscribers" component="{SubscriberHome}"  />
  <Route component="{SubscriberHome}"  />
</Router>

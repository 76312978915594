<script>
    export let value;
</script>

<div class="flex flex-col gap-3 md:flex-row md:gap-7">
    <div class="hover:shadow-md input-home transition-all flex justify-center items-center bg-white py-4 h-11 w-full md:w-4/12 ">
        <input
                bind:group={value}
                id="radio-gender-1"
                type="radio"
                value="MALE"
                class="cursor-pointer w-4 h-4 border-gray-300 checked:bg-lightblue focus:ring-lightblue focus:ring-1"
                required
        >
        <label for="radio-gender-1" class="ml-2 w-24 select-none">Uomo</label>
    </div>
    <div class="hover:shadow-md input-home transition-all flex items-center justify-center bg-white py-4 h-11 w-full md:w-4/12">
        <input
                bind:group={value}
                id="radio-gender-2"
                type="radio"
                value="FEMALE"
                class="cursor-pointer w-4 h-4 text-blue-600 border-gray-300 checked:bg-lightblue focus:ring-lightblue focus:ring-1"
                required
        >
        <label for="radio-gender-2" class="ml-2 w-24 select-none">Donna</label>
    </div>
    <div class="hover:shadow-md input-home transition-all flex items-center justify-center bg-white py-4 h-11 w-full md:w-4/12">
        <input
                bind:group={value}
                id="radio-gender-3"
                type="radio"
                value="OTHER"
                class="cursor-pointer w-4 h-4 border-gray-300 checked:bg-lightblue focus:ring-lightblue focus:ring-1"
                required
        >
        <label for="radio-gender-3" class="ml-2 w-24 select-none">Altro</label>
    </div>
</div>

<style>
    .focus\:ring-lightblue:focus{
        --tw-ring-opacity: 1;
        --tw-ring-color: #00c5ff;
    }

    .checked\:bg-lightblue:checked {
        --tw-bg-opacity: 1;
        background-color: #00c5ff !important;
    }
</style>

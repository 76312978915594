<script>
    export let size = 10;
    export let totalPages;
    export let page;
    export let subject;
    export let totalCount;

    export let colspan = 2;
    function setSize(value) {
        size = value;
        page=1;
    }

    function setActualPage(value) {
        page = value;
    }

</script>



<div class="flex flex-wrap justify-between h-20 items-center text-lightBlue-600 font-450">
    <div class="flex  gap-3">
        <div>
            {subject} visibili per pagina:
        </div>
        <button class="{size === 10 ? 'underline font-semibold' : ''}" on:click={() => setSize(10)}>
            10
        </button>
        <button class="{size === 50 ? 'underline font-semibold' : ''}" on:click={() => setSize(50)}>
            50
        </button>
        <button class="{size === 100 ? 'underline font-semibold' : ''}" on:click={() => setSize(100)}>
            100
        </button>
    </div>
    <div class="w-32 select-none">
        {totalCount ? ` Totale: ${totalCount} ${subject.toLowerCase()}`: " "}
    </div>
    <div class="flex gap-1">
        <div class="w-5">
            {#if page !== 1}
                <button class="px-2" on:click={() => setActualPage(--page)}>
                    <i class="fa fa-chevron-left"></i>
                </button>
            {/if}
        </div>
        <div class="w-24">
            <button on:click={() => setActualPage(page)}>
                Pagina: {totalPages=== 0 ? totalPages : page}
            </button>
        </div>
        <div class="w-10">
            <button on:click={() => setActualPage(totalPages)}>
                di: {totalPages}
            </button>
        </div>
        <div class="w-5">
            {#if page !== totalPages && totalPages !== 0}
                <button class="px-2" on:click={() => setActualPage(++page)}>
                    <i class="fa fa-chevron-right"></i>
                </button>
            {/if}
        </div>
    </div>

</div>

<script>
    import SettingsFormTitle from "./SettingsFormTitle.svelte";
    import {createForm} from "svelte-forms-lib";
    import {onMount} from "svelte";
    import {getSettings, setSettings} from "../../../services/settingService";
    import toast from "svelte-french-toast";
    import {navigate} from "svelte-routing";
    import {showToast} from "../../../services/toastService";
    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../../modal/Modal.svelte";

    let isPressed = false;

    const { form, handleChange, handleSubmit } = createForm({
        initialValues: {
            subscriptionActiveYear: "",
            registrationMinAge: "",
            ugsMaxAgeInclusive: "",
            minimumPaymentUGS: "",
            minimumPaymentNotUGS: "",
            maximumPayment: ""
        },
        onSubmit: async (form) => {

            openModal(Modal, {
                title: `Modifica impostazioni globali`,
                message: "Sei sicuro di voler modificare le impostazioni globali?",
                actionText: "Modifica",
                isActionModal: true,
                isCustom: false,
                defaultText: "Annulla",
                buttonColor: "lightBlue-400",
                onAction: () => {
                    editSettings(form);
                    closeModal();
                }
            });
        }
    });

    function getSettingsValues() {
        getSettings().then((data) => {
            if(data?.status === 200) {
                $form.subscriptionActiveYear = data.data.subscriptionActiveYear;
                $form.registrationMinAge = data.data.registrationMinAge;
                $form.ugsMaxAgeInclusive = data.data.ugsMaxAgeInclusive;
                $form.minimumPaymentUGS = data.data.minimumPaymentUGS;
                $form.minimumPaymentNotUGS = data.data.minimumPaymentNotUGS
                $form.maximumPayment = data.data.maximumPayment;

                return;
            }
            toast.error("Impossibile accedere alle impostazioni");
            navigate("subscribers");
        }).catch((err) => {
            console.error(err);
            toast.error("Impossibile accedere alle impostazioni");
            navigate("subscribers");
        })
    }

    function editSettings(newSettings) {
        isPressed = true;

        setSettings(newSettings).then((data) => {
            isPressed = false;

            if(data?.status === 200) {
                showToast("edit", "Impostazioni modificate");

                getSettingsValues();
                return;
            }
            toast.error("Non è stato possibile modificare le impostazioni");
        }).catch((err) => {
            isPressed = false;

            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                console.error(err);
                return;
            }

            showToast("error", "Errore server, riprova più tardi", "top-center");
            console.error(err);
        });
    }

    onMount(() => {
        getSettingsValues();
    });

</script>

<div class="h-full relative flex flex-col min-w-0 break-words w-full mb-12 shadow-lg rounded bg-white">
    <SettingsFormTitle />
    <form on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-col justify-between block w-full overflow-x-auto h-full mt-2 px-8 gap-6 min-h-400-px">
            <div class="flex flex-col gap-6">
                <div class="w-full">
                    <label class="font-450">Anno d'iscrizione</label>
                    <input
                        class="w-full input-home"
                        name="subscriptionActiveYear"
                        placeholder="Anno attuale d'iscrizione"
                        min="2000"
                        max="2100"
                        type="number"
                        bind:value={$form.subscriptionActiveYear}
                        required
                    >
                </div>
                <div class="w-full flex flex-col gap-3 mb-2 md:flex-row">
                    <div class="w-full">
                        <label class="font-450">Limite età per l'iscrizione</label>
                        <input
                            class="w-full input-home"
                            name="subscriptionActiveYear"
                            placeholder="Limite età per l'iscrizione"
                            min="7"
                            max="99"
                            type="number"
                            bind:value={$form.registrationMinAge}
                            required
                        >
                    </div>
                    <div class="w-full">
                        <label class="font-450">Limite età per UGS</label>
                        <input
                            class="w-full input-home"
                            name="subscriptionActiveYear"
                            placeholder="Età massima per la partecipazione all'UGS"
                            min="7"
                            max="99"
                            type="number"
                            bind:value={$form.ugsMaxAgeInclusive}
                            required
                        >
                    </div>
                </div>

                <div class="w-full flex flex-col gap-3 mb-2 md:flex-row">
                    <div class="w-full md:w-1/2 relative">
                        <label class="font-450">Pagamento minimo UGS</label>
                        <input
                            class="w-full input-home"
                            name="subscriptionActiveYear"
                            placeholder="Pagamento minimo UGS"
                            min="1"
                            max="100"
                            type="number"
                            bind:value={$form.minimumPaymentUGS}
                            required
                        >
                        <span class="absolute right-0 m-2">€</span>
                    </div>
                    <div class="w-full md:w-1/2 relative">
                        <label class="font-450">Pagamento minimo non UGS</label>
                        <input
                            class="w-full input-home"
                            name="subscriptionActiveYear"
                            placeholder="Pagamento minimo non UGS"
                            min="1"
                            max="100"
                            type="number"
                            bind:value={$form.minimumPaymentNotUGS}
                            required
                        >
                        <span class="absolute right-0 m-2">€</span>
                    </div>
                </div>

                <div class="w-full relative">
                    <label class="font-450">Pagamento massimo</label>
                    <input
                        class="w-full input-home"
                        name="subscriptionActiveYear"
                        placeholder="Pagamento massimo"
                        min="500"
                        max="10000"
                        type="number"
                        bind:value={$form.maximumPayment}
                        required
                    >
                    <span class="absolute right-0 m-2">€</span>
                </div>
            </div>
            <div class="flex justify-center">
                <button
                    disabled={isPressed}
                    type="submit"
                    class="bg-lightBlue-400 py-2 px-4 text-white transition hover:shadow-lg mb-4"
                >
                    MODIFICA
                </button>
            </div>
        </div>
    </form>
</div>

<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
<script>
    import { fade } from 'svelte/transition';
    import DateSearcher from "../../SearchBars/DateSearcher.svelte";
    import {onMount} from "svelte";

    export let subscriberId;
    export let membershipEdit;

    export let isAdmin;
    export let isRootManager;
    let isDateDisabled = false;
    let isEditable;

    function handleActiveMembership(active) {
        if(active && !membershipEdit.paidAmount) {
            membershipEdit.paidAt = new Date();
        }
    }

    $: membershipEdit.active ,handleActiveMembership(membershipEdit.active)

    onMount(() => {
        isEditable = isAdmin || isRootManager;
        console.log(isEditable, isAdmin, isRootManager)
    })
</script>

<div class="w-full flex flex-col gap-6 mb-2 md:flex-row">
    <div class="w-full md:w-1/2">
        <div>
            <label class="font-450">Anno di riferimento</label>
        </div>
        <input
                value="{membershipEdit.year}"
                type="text"
                class="w-full h-11 input-home"
                disabled
        />
    </div>
    <div class="w-full md:w-1/2">
        <div>
            <label class="font-450">Numero tessera</label>
        </div>
        <input
                value={membershipEdit.code ?? "Tessera non presente"}
                type="text"
                class="w-full h-11 input-home"
                disabled
        />
    </div>
</div>

<div class="w-full">
    <div>
        <label class="font-450">Stato tessera</label>
    </div>
    <div class="w-full flex flex-col gap-6 mb-2 md:flex-row">
        <div class="input-home transition-all flex justify-center items-center bg-white py-4 h-11 w-full md:w-6/12 ">
            <input
                    bind:group={membershipEdit.active}
                    id="radio-active-1"
                    type="radio"
                    name="active"
                    value="{true}"
                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    required
                    disabled={!isEditable}
            >
            <label for="radio-active-1" class="ml-2 w-24 font-450 select-none">Attivo</label>
        </div>
        <div class="hover:shadow-md input-home transition-all flex items-center justify-center bg-white py-4 h-11 w-full md:w-6/12">
            <input
                    bind:group={membershipEdit.active}
                    id="radio-active-2"
                    type="radio"
                    name="active"
                    value="{false}"
                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    disabled={!isEditable}
                    required
            >
            <label for="radio-active-2" class="ml-2 w-24 font-450 select-none">Non Attivo</label>
        </div>
    </div>

</div>

{#if membershipEdit.active !== undefined}

    <div class="w-full flex flex-wrap mb-2">
        <div class="w-full">
            <div>
                <label class="font-450">Metodo di pagamento</label>
            </div>
            <div class="h-11 flex items-center w-full">
                <input
                        value={membershipEdit?.paymentMethod === "STRIPE" ?  "Pagamento online": "Pagamento con bonifico"}
                        type="text"
                        class="w-full h-11 input-home transition-all"
                        disabled="{true}"
                />
            </div>
        </div>
    </div>

    <div class="w-full flex flex-col gap-6 mb-2 md:flex-row">
        <div class="w-full md:w-1/2">
            <div>
                <label class="font-450">Data pagamento</label>
            </div>
            <div class="h-11 flex items-center w-full input-home">
                <DateSearcher
                    bind:value={membershipEdit.paidAt}
                    placeholderValue="Data del pagamento"
                    isNullable="{false}"
                    disabled="{!isEditable || isDateDisabled}"
                    on:input={(v) => console.log(v)}
                />
            </div>
        </div>


        <div class="w-full md:w-1/2">
            <div>
                <label class="font-450">Quota</label>
            </div>
            <div class="h-full relative">
                <input
                        placeholder="{membershipEdit.active === false ? 'Attiva la tessera per inserire la somma' : 'Inserisci la somma pagata'}"
                        bind:value="{membershipEdit.paidAmount}"
                        disabled="{!isEditable}"
                        type="number"
                        min="0"
                        max="1000"
                        class="w-full h-11 input-home"
                        required
                >
                {#if membershipEdit.paidAmount}
                    <i transition:fade={{duration: 100}} max="{1000}" class="absolute currency-icon font-450">€</i>
                {/if}
            </div>
        </div>

    </div>

{/if}

<style>
    .currency-icon {
        top: 0.7rem;
        right: 1rem;
    }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
         -webkit-appearance: none;
         margin: 0;
     }

    input[type=number] {
        -moz-appearance: textfield;
    }
</style>


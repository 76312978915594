<script>
    import {link, navigate} from "svelte-routing";
    import {onDestroy, onMount} from "svelte";
    import {getSettings} from "../../services/settingService";
    import toast from "svelte-french-toast";

    export let isBackgroundVisible;
    let subscriptionActiveYear;
    let minimumPaymentNotUGS;


    onMount(() => {
        isBackgroundVisible = false;
        getSettingsValues();
    })

    function getSettingsValues() {
        getSettings().then((data) => {
            if(data?.status === 200) {
                subscriptionActiveYear = data.data.subscriptionActiveYear;
                minimumPaymentNotUGS = data.data.minimumPaymentNotUGS

                return;
            }
            setDefaultValue();
        }).catch((err) => {
            console.error(err);
            setDefaultValue();
        })
    }

    function setDefaultValue() {
        subscriptionActiveYear = (new Date()).getFullYear();
        minimumPaymentNotUGS = 15;
    }

    onDestroy(() => {
        isBackgroundVisible = false;
    } )
</script>

<div class="container-home min-h-600-px flex justify-center">
    <div class="flex flex-col items-center select-none mb-4 h-full w-full md:w-8/12 xl:w-4/12">
        <div class="title si-font font-bold w-full text-left red-si">
            Tesseramento

            {#if subscriptionActiveYear}
                {subscriptionActiveYear}
            {/if}
        </div>

        <div class="my-2 w-full">
            <img class="img-home" alt="Tessera SI 2023" src="https://aderisci.sinistraitaliana.si/materiali/tessera2023.png">
        </div>

        <div class="red-si si-font sub-title-home text-left w-full mb-6">
            La tua iscrizione - online
        </div>

        <div class="section-title si-font grey-content w-full">
            NUOVA ISCRIZIONE
        </div>

        <div class="w-full my-2">
            Clicca sul bottone blu "Iscriviti online" e verrà aperta la pagina che ti consentirà di iscriverti per la prima volta, clicca sul bottone "Nuova iscrizione" per inserire i tuoi dati
        </div>

        <div class="section-title si-font grey-content w-full">
            RINNOVO DI ISCRIZIONI DI ANNI PRECEDENTI
        </div>

        <div class="w-full my-2">
            Nel caso di rinnovo dell’iscrizione, non è necessario inserire di nuovo i tuoi dati personali ma sarà possibile richiamarli automaticamente inserendo il tuo indirizzo e-mail (quello usato per il tesseramento dell’anno precedente). Riceverai una mail con il link per rinnovare la tua iscrizione.
        </div>

        <div class="my-2 w-full flex items-start">
            <a
                    use:link
                    href="/iscriviti-home"
            >
                <div class="w-64 bg-lb cursor-pointer py-1 px-8 first-button text-center transform-gpu section-title rounded">
                    Iscriviti online
                </div>
            </a>
        </div>

        <div class="w-full my-2" style="color: #666666">
            L’iscrizione richiede il pagamento di una quota minima di almeno
            {#if minimumPaymentNotUGS}
                {minimumPaymentNotUGS}
            {/if}
            €.
        </div>

        <div class="w-full mt-12 underline">
            ATT.NE: L’inserimento di un’e-mail non valida o errata renderà indisponibili alcuni dei diritti fondamentali degli iscritti, tra i quali: la ricezione di comunicazioni importanti, la partecipazione a consultazioni e l’accesso ad eventuali servizi digitali che si renderanno disponibili per gli iscritti.
        </div>

        <hr class="w-full h-1 my-6">

        <div class="my-2 w-full flex items-start">
            <a
                    use:link
                    href="/raccolta-adesioni/login"
            >
                <div class="w-64 second-button cursor-pointer py-1 px-8 text-center transform-gpu section-title rounded">
                    Iscrivi altri online
                </div>
            </a>

        </div>

        <div class="w-full my-2">
            Clicca su questo bottone solo se sei già un/una iscritto/a e devi inserire l’iscrizione di altri che per varie ragioni sono impossibilitati a farlo. Scarica qui il
            <a href="http://www.sinistraitaliana.si/public/Format_adesione.pdf" class="red-si cursor-pointer" target="_blank">MODULO</a>
            , che dovrai obbligatoriamente far firmare al/alla nuovo/a iscritto/a per l’accettazione delle norme sulla sulla privacy, conservalo in archivio e poi inserisci i dati del/della nuovo/a iscritto/a.
        </div>

        <div class="my-4 text-lg font-425">
            Per qualsiasi problema riscontrato durante l'iscrizione
            <a
                    target="_blank"
                    use:link
                    href="/aiuto"
                    class="red-si"
            >
                Clicca qui
            </a>

            per consultare le nostre FAQ
        </div>

    </div>
</div>

<style>
    .si-font {
        font-family: "Work Sans", sans-serif !important;
    }

</style>
<script>
    import EmailForm from "../../components/Forms/Subscriber/EmailForm.svelte";

    export let onClose;
</script>

<div class="container-home min-h-600-px flex justify-center">
    <div class="flex flex-col content-color items-center select-none mb-4 h-full w-full md:w-8/12 xl:w-4/12">
        <div class="text-2xl mt-4">
            Dopo l'attivazione della tua iscrizione, inserisci la tua mail e registra i nuovi aderenti cliccando sul link che arriverà sulla tua casella di posta
        </div>

        <EmailForm />
    </div>
</div>
<script>
    import Select from "svelte-select";

    export let value;
</script>

<Select
        items={[
                    {
                        value: "country",
                        label: "Area"
                    },
                    {
                        value: "region",
                        label: "Regione"
                    },
                    {
                        value: "province",
                        label: "Provincia"
                    },
                    {
                        value: "city",
                        label: "Comune"
                    },
                ]}
        floatingConfig={ {placement: 'top-start'}}
        type= "text"
        containerStyles={`border: 1px solid #D8D4D4; max-height: 2rem !important; min-height: 2.75rem; border-radius: 0px`}
        clearable={false}
        bind:value={value}
/>

<script>
    import {handleSortValue} from "../../utils/sortFunction";
    import EmptyHeader from "./EmptyHeader.svelte";
    let emailSortValue = undefined;
    let roleSortValue = undefined;

    export let sort = [];

    const sortNames = ["email", "role"];


    function searchSort(name) {
        const sortValue = (sort?.find((el) => el.name === name))?.sort;

        switch (name) {
            case "email" :
                emailSortValue = sortValue;
                break;
            case "role":
                roleSortValue = sortValue;
                break;
        }

        return sortValue;
    }

    function handleClick (name) {
        sort = handleSortValue(sort, name, searchSort(name) === undefined ? "rmv" : searchSort(name));

        if(sort) {
            sort.forEach((el) => searchSort(el.name))
        } else {
            sortNames.forEach((name) => searchSort(name))
        }

    }

</script>

<tr class="bg-white border-b transition duration-300 ease-in-out">
    <th on:click|preventDefault={() => handleClick("email")}  class="w-64 cursor-pointer select-none  align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Nome {emailSortValue ? emailSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th on:click|preventDefault={() => handleClick("email")}  class="w-64 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Cognome {emailSortValue ? emailSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th on:click|preventDefault={() => handleClick("email")}  class="w-4/12 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Email {emailSortValue ? emailSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th on:click|preventDefault={() => handleClick("role")}  class="w-44 cursor-pointer select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Ruolo {roleSortValue ? roleSortValue === "asc" ? "↑" : "↓" : "" }
    </th>
    <th class="w-4/12 select-none align-middle border border-solid border-blueGray-100 py-3 font-bold uppercase border-l-0 border-r-0 whitespace-nowrap text-left">
        Gruppo
    </th>
    <EmptyHeader />
</tr>
<script>
    import { createEventDispatcher } from 'svelte'
    const dispatch = createEventDispatcher();

    function dispatchEvent(eventName) {
        dispatch(eventName);
    }
</script>


<div class="flex gap-3">
    <button class="w-1/2 bg-blueGray-600 font-450 py-3 text-white hover:shadow-lg transition-all outline-none focus:outline-none" on:click|preventDefault={() => dispatchEvent("add")}>
        Aggiungi <i class="ml-2 fa fa-plus"></i>
    </button>
    <button class="w-1/2 bg-red-500 font-450 py-3 text-white hover:shadow-lg transition-all outline-none focus:outline-none" on:click|preventDefault={() => dispatchEvent("reset")}>
        Cancella tutti i campi <i class="ml-2 fa fa-minus"></i>
    </button>
</div>
<script>
    // core components
    import {Route, Router} from "svelte-routing";
    import UserForm from "../../components/Forms/User/UserForm.svelte";
    import UserTable from "../../components/Cards/User/UserTable.svelte";
    export let location;
</script>



<div class="flex flex-wrap mt-4">
    <div class="w-full mb-8 px-4">
        <Router url="users">
            <Route path="table" component="{UserTable}" />
            <Route path="create" component="{UserForm}" />
            <Route path="edit" component="{UserForm}" />
        </Router>
    </div>
</div>

<script>
  import {onMount} from "svelte";
  import {navigate} from "svelte-routing";
  import {getGroups} from "../../../services/groupService";
  import GroupCard from "./GroupCard.svelte";
  import GroupSearchBar from "../../SearchBars/GroupSearchBar.svelte";
  import TableFooter from "../TableFooter.svelte";
  import GroupHeader from "../../Headers/GroupHeader.svelte";
  import {resetPage} from "../../../utils/resetPage";
  import {showToast} from "../../../services/toastService";
  import EmptyTable from "../EmptyTable.svelte";
  import StretchLoader from "../../Loaders/StretchLoader.svelte";
  import Table from "../Table.svelte";
  // can be one of light or dark
  export let color = "light";
  
  let page = 1;
  let size = 10;
  let sort = [];
  let totalPages = 10;
  let groups = [];
  let name = "";
  let totalCount;
  let isLoading;
  let colspan = 2;

  async function getGroupList () {
    isLoading = true;

    getGroups(page, size, sort, name).then((resp) => {
      isLoading = false;

      if(resp.status === 200 && resp?.data) {
        groups = resp.data.data;
        totalPages = Math.ceil(resp.data?.totalCount / size)
        totalCount = resp.data.totalCount;
      }

    }).catch((err) => {
      isLoading = false;
      console.log(err);

      if(err?.response?.data?.status === 403){
        showToast("error", "Non hai i permessi per compiere questa azione");
        navigate("/", {replace: true});
      }

      showToast("error", "Errore server");
      navigate("/", {replace: true});
    })
  }

  async function handleChange() {
    await getGroupList();
  }

  $: name, page = resetPage();

  $: sort, page, size, name, getGroupList();

  onMount(() => {
    getGroupList();
  })

</script>

<Table>
  <div class="flex flex-wrap items-center justify-between w-full" slot="actions">
    <div class="relative w-full max-w-full flex-grow flex-1">
      <h3
              class="font-semibold text-2xl light"
      >
        Gruppi
      </h3>
    </div>

    <button class="border-0 bg-lightBlue-500 p-2 px-4 font-semibold text-white hover:shadow-lg w-44" on:click|preventDefault={() => navigate("create", {replace: true})}>
      Crea gruppo
    </button>
  </div>

  <GroupSearchBar bind:name slot="searchbar"/>

  <GroupHeader bind:sort slot="tableHeader"/>

  <tbody class="h-full overflow-y-auto pt-6 no-scroll mx-4" slot="tableContent">
    {#if isLoading === true}
      <StretchLoader bind:colspan />
    {:else if isLoading === false && groups.length === 0}
      <EmptyTable bind:colspan subject="gruppi" />
    {:else if isLoading === false}
      {#each groups as group}
        <GroupCard {...group} on:delete={handleChange}/>
      {/each}
    {/if}
  </tbody>

  <TableFooter  bind:colspan bind:page bind:totalPages bind:size subject="Gruppi" bind:totalCount slot="footer"/>
</Table>

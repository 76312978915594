export const provinces = [
    {
        label: "Agrigento (AG)",
        value: "AG"
    },
    {
        label: "Alessandria (AL)",
        value: "AL"
    },
    {
        label: "Ancona (AN)",
        value: "AN"
    },
    {
        label: "Aosta (AO)",
        value: "AO"
    },
    {
        label: "Arezzo (AR)",
        value: "AR"
    },
    {
        label: "Ascoli Piceno (AP)",
        value: "AP"
    },
    {
        label: "Asti (AT)",
        value: "AT"
    },
    {
        label: "Avellino (AV)",
        value: "AV"
    },
    {
        label: "Bari (BA)",
        value: "BA"
    },
    {
        label: "Barletta-Andria-Trani (BT)",
        value: "BT"
    },
    {
        label: "Belluno (BL)",
        value: "BL"
    },
    {
        label: "Benevento (BN)",
        value: "BN"
    },
    {
        label: "Bergamo (BG)",
        value: "BG"
    },
    {
        label: "Biella (BI)",
        value: "BI"
    },
    {
        label: "Bologna (BO)",
        value: "BO"
    },
    {
        label: "Bolzano (BZ)",
        value: "BZ"
    },
    {
        label: "Brescia (BS)",
        value: "BS"
    },
    {
        label: "Brindisi (BR)",
        value: "BR"
    },
    {
        label: "Cagliari (CA)",
        value: "CA"
    },
    {
        label: "Caltanissetta (CL)",
        value: "CL"
    },
    {
        label: "Campobasso (CB)",
        value: "CB"
    },
    {
        label: "Caserta (CE)",
        value: "CE"
    },
    {
        label: "Catania (CT)",
        value: "CT"
    },
    {
        label: "Catanzaro (CZ)",
        value: "CZ"
    },
    {
        label: "Chieti (CH)",
        value: "CH"
    },
    {
        label: "Como (CO)",
        value: "CO"
    },
    {
        label: "Cosenza (CS)",
        value: "CS"
    },
    {
        label: "Cremona (CR)",
        value: "CR"
    },
    {
        label: "Crotone (KR)",
        value: "KR"
    },
    {
        label: "Cuneo (CN)",
        value: "CN"
    },
    {
        label: "Enna (EN)",
        value: "EN"
    },
    {
        label: "Fermo (FM)",
        value: "FM"
    },
    {
        label: "Ferrara (FE)",
        value: "FE"
    },
    {
        label: "Firenze (FI)",
        value: "FI"
    },
    {
        label: "Foggia (FG)",
        value: "FG"
    },
    {
        label: "Forlì-Cesena (FC)",
        value: "FC"
    },
    {
        label: "Frosinone (FR)",
        value: "FR"
    },
    {
        label: "Genova (GE)",
        value: "GE"
    },
    {
        label: "Gorizia (GO)",
        value: "GO"
    },
    {
        label: "Grosseto (GR)",
        value: "GR"
    },
    {
        label: "Imperia (IM)",
        value: "IM"
    },
    {
        label: "Isernia (IS)",
        value: "IS"
    },
    {
        label: "L’aquila (AQ)",
        value: "AQ"
    },
    {
        label: "La spezia (SP)",
        value: "SP"
    },
    {
        label: "Latina (LT)",
        value: "LT"
    },
    {
        label: "Lecce (LE)",
        value: "LE"
    },
    {
        label: "Lecco (LC)",
        value: "LC"
    },
    {
        label: "Livorno (LI)",
        value: "LI"
    },
    {
        label: "Lodi (LO)",
        value: "LO"
    },
    {
        label: "Lucca (LU)",
        value: "LU"
    },
    {
        label: "Macerata (MC)",
        value: "MC"
    },
    {
        label: "Mantova (MN)",
        value: "MN"
    },
    {
        label: "Massa-Carrara (MS)",
        value: "MS"
    },
    {
        label: "Matera (MT)",
        value: "MT"
    },
    {
        label: "Messina (ME)",
        value: "ME"
    },
    {
        label: "Milano (MI)",
        value: "MI"
    },
    {
        label: "Modena (MO)",
        value: "MO"
    },
    {
        label: "Monza e Brianza (MB)",
        value: "MB"
    },
    {
        label: "Napoli (NA)",
        value: "NA"
    },
    {
        label: "Novara (NO)",
        value: "NO"
    },
    {
        label: "Nuoro (NU)",
        value: "NU"
    },
    {
        label: "Oristano (OR)",
        value: "OR"
    },
    {
        label: "Padova (PD)",
        value: "PD"
    },
    {
        label: "Palermo (PA)",
        value: "PA"
    },
    {
        label: "Parma (PR)",
        value: "PR"
    },
    {
        label: "Pavia (PV)",
        value: "PV"
    },
    {
        label: "Perugia (PG)",
        value: "PG"
    },
    {
        label: "Pesaro e Urbino (PU)",
        value: "PU"
    },
    {
        label: "Pescara (PE)",
        value: "PE"
    },
    {
        label: "Piacenza (PC)",
        value: "PC"
    },
    {
        label: "Pisa (PI)",
        value: "PI"
    },
    {
        label: "Pistoia (PT)",
        value: "PT"
    },
    {
        label: "Pordenone (PN)",
        value: "PN"
    },
    {
        label: "Potenza (PZ)",
        value: "PZ"
    },
    {
        label: "Prato (PO)",
        value: "PO"
    },
    {
        label: "Ragusa (RG)",
        value: "RG"
    },
    {
        label: "Ravenna (RA)",
        value: "RA"
    },
    {
        label: "Reggio Calabria (RC)",
        value: "RC"
    },
    {
        label: "Reggio Emilia (RE)",
        value: "RE"
    },
    {
        label: "Rieti (RI)",
        value: "RI"
    },
    {
        label: "Rimini (RN)",
        value: "RN"
    },
    {
        label: "Roma (RM)",
        value: "RM"
    },
    {
        label: "Rovigo (RO)",
        value: "RO"
    },
    {
        label: "Salerno (SA)",
        value: "SA"
    },
    {
        label: "Sassari (SS)",
        value: "SS"
    },
    {
        label: "Savona (SV)",
        value: "SV"
    },
    {
        label: "Siena (SI)",
        value: "SI"
    },
    {
        label: "Siracusa (SR)",
        value: "SR"
    },
    {
        label: "Sondrio (SO)",
        value: "SO"
    },
    {
        label: "Sud Sardegna (SU)",
        value: "SU"
    },
    {
        label: "Taranto (TA)",
        value: "TA"
    },
    {
        label: "Teramo (TE)",
        value: "TE"
    },
    {
        label: "Terni (TR)",
        value: "TR"
    },
    {
        label: "Torino (TO)",
        value: "TO"
    },
    {
        label: "Trapani (TP)",
        value: "TP"
    },
    {
        label: "Trento (TN)",
        value: "TN"
    },
    {
        label: "Treviso (TV)",
        value: "TV"
    },
    {
        label: "Trieste (TS)",
        value: "TS"
    },
    {
        label: "Udine (UD)",
        value: "UD"
    },
    {
        label: "Varese (VA)",
        value: "VA"
    },
    {
        label: "Venezia (VE)",
        value: "VE"
    },
    {
        label: "Verbano-Cusio-Ossola (VB)",
        value: "VB"
    },
    {
        label: "Vercelli (VC)",
        value: "VC"
    },
    {
        label: "Verona (VR)",
        value: "VR"
    },
    {
        label: "Vibo valentia (VV)",
        value: "VV"
    },
    {
        label: "Vicenza (VI)",
        value: "VI"
    },
    {
        label: "Viterbo (VT)",
        value: "VT"
    }
]
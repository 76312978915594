<script>
    import { closeModal } from 'svelte-modals'
    import { fade } from 'svelte/transition'
    // provided by Modals
    export let isOpen
    export let title
    export let message
    export let isActionModal
    export let actionText
    export let buttonColor
    export let onAction;
    export let defaultText
    export let isPayment = false;
    export let min = 0;
    export let max;
    export let paidAmount = min;
    export let isCustom = false
    export let value;
</script>

{#if isOpen}
    {#if isPayment}
        <div role="dialog" class="modal" out:fade>
            <div class="contents border-blueGray-200 border">
                <h2 class="font-semibold">Inserisci la somma pagata</h2>
                <div class="flex w-8/12 items-center font-425 gap-3 mt-2 w-full">
                    <input type="number" class="h-10 border-gray-300 w-full text-center" bind:value={paidAmount} min={min} max="1000"> €
                </div>

                <div class="actions mt-4">
                    <button class="font-semibold p-2 w-24 border hover:shadow-md transition-all outline-none focus:outline-none" on:click="{closeModal}">Annulla</button>
                    <button class="font-semibold p-2 w-24  bg-lightBlue-500 text-white hover:shadow-md transition-all outline-none focus:outline-none" on:click="{onAction(paidAmount)}">Attiva</button>
                </div>
            </div>
        </div>
    {:else }
        {#if !isCustom}
            <div role="dialog" class="modal" out:fade>
                <div class="contents border-blueGray-200 border">
                    <h2 class="font-semibold">{title}</h2>
                    <p class="text-lg">{message}</p>
                    <div class="actions">
                        <button class="font-semibold p-2  border hover:shadow-md transition-all outline-none focus:outline-none" on:click="{closeModal}">{defaultText}</button>
                        {#if (isActionModal)}
                            <button class="font-semibold p-2 bg-{buttonColor} text-white hover:shadow-md transition-all outline-none focus:outline-none" on:click="{onAction}">{actionText}</button>
                        {/if}
                    </div>
                </div>
            </div>
            {:else}
                <div role="dialog" class="modal" out:fade>
                    <div class="contents border-blueGray-200 border">
                        <h2 class="font-semibold">{title}</h2>
                        <p class="text-lg">{message}</p>
                        <div class="flex w-8/12 items-center font-425 gap-3 mt-2 w-full">
                            <textarea class="h-24 border-gray-300 w-full" maxlength="300" bind:value={value} ></textarea>
                        </div>

                        <div class="actions mt-4">
                            <button class="font-semibold p-2 w-24 border hover:shadow-md transition-all outline-none focus:outline-none" on:click="{closeModal}">Annulla</button>
                            <button class="font-semibold p-2 w-24  bg-lightBlue-500 text-white hover:shadow-md transition-all outline-none focus:outline-none" on:click="{onAction(value)}">{actionText}</button>
                        </div>
                    </div>
                </div>
        {/if}
    {/if}
{/if}

<style>
    .modal {
        position: fixed;
        top: 10px;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99 !important;
        /* allow click-through to backdrop */
        pointer-events: none;
    }

    .contents {
        min-width: 240px;
        /*padding: 16px 25px;*/
        padding: 15px 40px 15px 40px;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        pointer-events: auto;
        z-index: 99 !important;
    }

    h2 {
        text-align: center;
        font-size: 24px;
    }

    p {
        text-align: center;
        margin-top: 16px;
    }

    .actions {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
</style>
<script>
    // core components
    import {navigate, Route, Router} from "svelte-routing";
    import PrintsTable from "../../components/Cards/Print/PrintsTable.svelte";
    import {onMount} from "svelte";
    export let location;

    onMount(() => {
        const isRootManager = localStorage.getItem(process.env.IS_ROOT_MANAGER) === "true";
        const isAdmin = localStorage.getItem(process.env.USER_ROLE) === "ADMIN";

        if (!isRootManager && !isAdmin) {
            navigate("/admin/", {replace: true});
        }
    })
</script>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-8 px-4">
        <Router url="prints">
            <Route component="{PrintsTable}" />
        </Router>
    </div>
</div>

<script>
    import Select from "svelte-select";
    import {getGroups} from "../../services/groupService";
    async function searchGroupByName (filterText) {
        await getGroups(1, filterText.length === "" ? 5 : 50, "", filterText).then((data) => {
            groups = data?.data?.data.map((group) => {return {label: group.name, value: group.id}});
        });
        return groups;
    }
    export let groups = [];
    export let value = "";
    export let handleChange;
    export const required = true;
</script>


<Select
        {groups}
        id="groupId"
        maxlength="200"
        type= "text"
        placeholder="Nome del gruppo"
        debounceWait={300}
        bind:value={value}
        on:change={handleChange}
        loadOptions={searchGroupByName}
        class="rounded"
        required={required}
        containerStyles={`
            border: 1px solid #e0e0e0;
            border-radius: 0px;
            box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%);
            height: 2.75rem
        `}
/>
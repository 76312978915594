<script>

    import {closeModal, openModal} from "svelte-modals";
    import Modal from "../../modal/Modal.svelte";
    import {createEventDispatcher} from "svelte";
    const dispatch = createEventDispatcher();
    export let element;
    export let isEdit = false;
    function handleDeleteButton () {
        openModal(Modal, {
            title: `Eliminazione `,
            message: `Vuoi eliminare ${element}?`,
            actionText: "Elimina",
            isActionModal: true,
            defaultText: "Annulla",
            buttonColor: "red-500",
            onAction: () => {
                closeModal()
                dispatch('delete', {});
            }
        })
    }
</script>

{#if isEdit === false}
    <span
        on:click|preventDefault={() => handleDeleteButton()}
        class="w-10 h-10 text-sm text-white inline-flex items-center justify-center rounded-full bg-red-600 cursor-pointer"
    >
          <i class="fa fa-trash"></i>
    </span>
{:else}
    <button class="border-0 bg-red-500 p-2 px-4 font-semibold text-white transition hover:shadow-lg w-44 outline-none focus:outline-none"  on:click|preventDefault = {() => handleDeleteButton()}>
        Elimina {element}
    </button>
{/if}
import toast from "svelte-french-toast";

export function showToast (type, text, position, emoji) {
    switch (type) {
        case "success":
            toastSuccess(text);
            break;
        case "error":
            toastError(text, position);
            break;
        case "custom":
            toastCustom(text, position, emoji)
            break;
        default:
            toastDefault(text, position);
            break;
    }
}
function toastSuccess (text, position) {
    toast.success(text, {
        position: position,
        className:"w-52"
    });
}

function toastError (text, position) {
    toast.error(text, {
        position: position,
        className:"w-52"
    });
}

function toastDefault (text, position) {
    toast(text, {
        icon: "🛠️ ",
        position: position,
        className:"w-52"
    });
}

function toastCustom (text, position, emoji) {
    toast(text, {
        icon: emoji,
        position: position,
        className:"w-52"
    });
}




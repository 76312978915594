<script>
    import {onMount} from "svelte";
    import {navigate} from "svelte-routing";
    import toast from "svelte-french-toast";
    import { link } from "svelte-routing";

    const urlParams = new URLSearchParams(window.location.search);
    let subscriberId = urlParams.get("id");


    onMount(() => {
        if(!subscriberId) {
            toast.error("Iscriviti prima di accedere al pagamento");
            navigate("/",  {replace: true});
        }
    })
</script>

<div class="container mx-auto w-full bg-white md:bg-none flex flex-col justify-between" style="height: 50vh">
    <div class="flex flex-col content-center items-center select-none h-full w-full">
        <div class="title-red font-bold w-full text-center">
            Il tuo pagamento non è andato a buon fine
        </div>

        <div class="w-8/12 flex flex-col mt-8 gap-7">
            <div class="text-3xl font-450 text-center">
                La tua iscrizione è stata salvata, ritenta il pagamento cliccando il pulsante qui sotto
            </div>
        </div>

    </div>
    <div class="flex flex-col justify-center gap-7">
        <div class="flex justify-center">
            <a
                use:link
                href="/pagamento?id={subscriberId}"
            >
                <button class="mt-10 w-52 h-16 text-lg font-450 bg-lightBlue-500 flex items-center justify-center text-white rounded shadow-md cursor-pointer transition hover:shadow-lg">
                    Riprova il pagamento
                </button>
            </a>
        </div>

        <div class="text text-center justify-center text-xl">
            Altrimenti utilizza questo <a class="text-lightBlue-500 bg-none cursor-pointer" href="/pagamento?id={subscriberId}">link</a>
        </div>
    </div>

</div>
<script>
    import Select from "svelte-select";
    import {prefixes} from "../../utils/phonePrefix";
    export let value;
    export let handleChange;
    export const required = true;

    const prefixList = prefixes.countries.map((prefix) => {return {label: prefix.code + ` (${prefix.name})`, value: prefix.code }});

</script>


<Select
        items={prefixList}
        id="phone.prefix"
        type= "text"
        placeholder="+XX"
        bind:value={value}
        on:change={handleChange}
        required={required}
        class="hover:shadow-md transition-all"
        containerStyles="border: 1px solid #e0e0e0; box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 15%); border-radius: 0px; height: 2.75rem"
/>
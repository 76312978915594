<script>
    import {createEventDispatcher} from "svelte";

    export let inputValue;
    export let firstOption;
    export let secondOption;
    export let isNullable = true;
    export let isForm = false;
    const dispatch = createEventDispatcher()

    function handleValue(value) {
        inputValue === value ? inputValue = null : inputValue = value;
    }

    function dispatchEvent() {
        dispatch('input', { state: "swithced value", value: inputValue});
    }
</script>

<div class="flex gap-1 {isForm ? 'gap-7': 'gap-1'} h-11">
    <div class="flex justify-center items-center {isForm ? 'border-input': 'border border-gray-300'} w-1/2">
        <input
                type="radio"
                value="true"
                bind:group={inputValue}
                on:click={!isNullable ? () => {} : (data) => handleValue(data.target.value)}
                class="cursor-pointer w-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                on:input={dispatchEvent}
        >
        <label class="w-28 text-center ml-2">{firstOption}</label>
    </div>
    <div class="flex items-center justify-center {isForm ? 'border-input': 'border border-gray-300'} w-1/2">
        <input
                type="radio"
                value="false"
                bind:group={inputValue}
                on:click={!isNullable ? () => {} : (data) => handleValue(data.target.value)}
                class="cursor-pointer w-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                on:input={dispatchEvent}
        >
        <label class="w-28 text-center ml-2">{secondOption}</label>
    </div>
</div>
<script>
    import { createForm } from "svelte-forms-lib";
    import {navigate} from "svelte-routing";
    import { onMount } from 'svelte';
    import {createGroup, getGroup, updateGroup} from "../../../services/groupService";
    import GroupSearcher from "../../SearchBars/GroupSearcher.svelte";
    import {showToast} from "../../../services/toastService";
    import UserFormTitle from "../User/UserFormTitle.svelte";
    import GroupFormTitle from "./GroupFormTitle.svelte";

    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get('id');
    let isEdit = !!groupId;
    let group;
    let isPressed = false;

    const { form, handleChange, handleSubmit } = createForm({
        initialValues: {
            name: "",
            parentId: "",
        },
        onSubmit: (formGroup) => {
            isPressed = true;
            console.log($form?.parentId?.value);

            if($form?.parentId?.value) {
                $form.parentId = $form.parentId.value
            }
            else {
                delete $form.parentId;
            }

            if (isEdit) {
                editGroup(formGroup)
                return;
            }
            addGroup(formGroup);
        },
    });

    async function editGroup (group) {
        delete group.password;
        updateGroup(group, groupId).then((resp) => {
            isPressed = false;
            if(resp.status === 200 && resp?.data) {
                showToast("custom", "Gruppo modificato", "top-center", "⚒️");
                navigate("table", {replace: true})
            }
        }).catch((err) => {
            isPressed = false;
            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 404) {
                showToast("error", "Gruppo non trovato", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 500 && err?.name === "QueryFailedError") {
                showToast("error", "Impossibile modificare il gruppo", "top-center");
                console.error(err);
                return;
            }
            defaultError();
        })
    }
    async function addGroup(group) {
        createGroup(group).then((resp) => {
            isPressed = false;
            if(resp.status === 200 && resp?.data) {
                showToast("success", "Gruppo creato", "top-center");
                navigate("table", {replace: true})
            }
        }).catch((err) => {
            isPressed = false;
            if(err?.response?.status === 403) {
                showToast("error", "Non sei abilitato a compiere questa azione", "top-center");
                console.error(err);
                return;
            }
            if(err?.response?.status === 500) {
                showToast("error", "Impossibile creare il gruppo", "top-center");
                console.error(err);
                return;
            }
            defaultError();
        })
    }

    function setFormValue() {
        $form.name = group.name;
    }


    function defaultError() {
        showToast("error", "Errore server. Riprovi più tardi", "top-center");
        navigate("/admin" , {replace: true});
    }

    onMount(async () => {

        if(isEdit) {
            getGroup(groupId).then((data) => {
                if (data?.status === 200) {
                    group = data.data
                    setFormValue()
                }
            }).catch((err) => {
                if(err?.response?.data?.status === 404 || err?.response?.data?.status === 500) {
                    showToast("error", "Gruppo non trovato", "center")
                    navigate("table" , {replace: false})
                    return;
                }
                defaultError();
            });
        }
    });
</script>

<div class="h-full relative flex flex-col min-w-0 break-words w-full mb-12 shadow-lg rounded bg-white">
    <GroupFormTitle
            id={groupId}
            isEdit={isEdit}
    />
    <form on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-col justify-between block w-full overflow-x-auto h-full mt-2 px-8 gap-6 min-h-400-px">
            <div class="flex flex-col gap-6">
                <div class="w-full">
                    <label class="font-450">Nome del gruppo</label>
                    <input
                            class="w-full input-home"
                            name="name"
                            placeholder="Nome del gruppo"
                            maxlength="200"
                            type="text"
                            bind:value={$form.name}
                            on:change={handleChange}
                            required
                    >
                </div>
                {#if isEdit === false}
                    <div class="w-full">
                        <label class="font-450">Gruppo Padre</label>
                        <GroupSearcher
                            bind:value={$form.parentId}
                            handleChange={() => handleChange}
                            required={false}
                        />
                    </div>
                {/if}
            </div>
            <div class="flex justify-center">
                <button disabled={isPressed} type="submit" class="bg-lightBlue-400 py-2 px-4 text-white transition hover:shadow-lg mb-4">
                    {isEdit ? "MODIFICA": "SALVA"}
                </button>
            </div>
        </div>
    </form>
</div>
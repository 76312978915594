<script>
    export let value;
</script>

<div class="flex flex-col gap-3 md:flex-row md:gap-7">
    <div class="gap-1 input-home hover:shadow-md transition-all flex items-center justify-center py-4 h-11 w-full md:w-6/12">
        <input
                bind:group={value}
                id="radio-ugs-1"
                type="radio"
                name="isUGS"
                value="{true}"
                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                required
        >
        <div>
            <label for="radio-ugs-1" class="ml-2 w-24 font-450 select-none">SI</label>
        </div>
    </div>
    <div class="gap-1 input-home hover:shadow-md transition-all flex items-center justify-center py-4 h-11 w-full md:w-6/12">
        <input
                bind:group={value}
                id="radio-ugs-2"
                type="radio"
                name="isUGS"
                value="{false}"
                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                required
        >
        <div>
            <label for="radio-ugs-2" class="ml- w-24 font-450 select-none">NO</label>
        </div>
    </div>
</div>
